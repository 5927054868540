import { formatDate } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { retry } from 'rxjs';
import { CurrentPlan } from 'src/app/_data/current-plan';
import { WhiteLabelList } from 'src/app/_data/white-label-list';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { SignupService } from 'src/app/_services/signup.service';
import { SubscriptionPlanService } from 'src/app/_services/subscription-plan.service';
 
declare const Stripe;

interface PlanDetails {
  currentPlan: string;
  upgradeToOptions: string[];
  selectedUpgrade: string;
  discount: number;
  salesTaxRate: number;
  couponCode: string;
  subtotal: number;
  amountDue: number;
  billingFrequency: 'monthly' | 'yearly';
  monthlyPrice: number;
  yearlyPrice: number;
}

@Component({
    selector: 'app-payment-page',
    templateUrl: './payment-page.component.html',
    styleUrls: ['./payment-page.component.scss'],
})
export class PaymentPageComponent implements OnInit {
 
  _global = GlobalService
  currentPlan = CurrentPlan

  applyCouponBtnDisable = true
  inValidCode:boolean = false;
  isLoading:boolean = false;
  selectedPlan:any = null
  saleTax:any = 0
  taxAmount:number = 8.25
  subTotal:number = 0;
  discountedSubTotal:number = 0;
  discount:number = 0;
  applyDiscount:string = null
  discountValue:string = null;
  select:string = null
  selectedPlanPrice:any;
  plans:any[] = []
  
  planData: any = null;
  totalAmount: number = 0;
 
    constructor(
        public _signup: SignupService,
        public _abd:AppBaseDataService,
        private _subscriptionPlanService: SubscriptionPlanService,
        private _spinner: NgxSpinnerService
    ) {}



    ngOnInit(): void {
        this._abd.showSpinner()
        try {
                this._signup.getSubscriptionPlans().subscribe((res)=>{
            this._abd.hideSpinner()
                  if(res.success){
                    this.plans = res.data.filter((v)=>v.isTrial !== true)
                    let plan = JSON.parse(localStorage.getItem('selectedPlanDetails'))
                    if(plan !== undefined && plan !== null){
                        this._signup.selectedPlanDetails = plan
                    }
                    this.select = this._signup.selectedPlanDetails?.planId
                    this.onPlanChange(this._signup.selectedPlanDetails?.planId)
                   this.getCurrentPlanDetails() 
          }  
     
        })
        } catch (error) {
          this._abd.hideSpinner()
        }
     
    }
    getCurrentPlanDetails(){
        try {
            this._abd.showSpinner()
                   this._subscriptionPlanService.getBusinessInvoice().subscribe(
                        (res: any) => {
                           this._abd.hideSpinner()
                            if (res.responseStatusCode == 903) {
                                this.planData = res.data.currentSubscription;
                            }
                        },
                        (error) => {
                            this._abd.hideSpinner()
                        }
                    );
        } catch (error) {
            
        }
    }
     onPlanChange(value:any){
     this.cancel()
      this._signup.selectedPlanDetails = this.plans.find((p)=>p.planId.toString() === value)
     this.selectedPlanPrice = this._signup.selectedPlanDetails?.prices.find((v)=>v.periodString === this._signup.selectedPlanInterval)
     this.selectedPlanPrice = (this.selectedPlanPrice?.value * 12).toFixed(2)
     this.onPlanIntervalChange(this._signup.selectedPlanInterval)

    }

    onPlanIntervalChange(value:any){
        this.cancel()
        this.selectedPlanPrice = this._signup.selectedPlanDetails?.prices.find((v)=>v?.periodString === this._signup.selectedPlanInterval)
      if(this._signup.selectedPlanInterval === 'Yearly'){
          this.selectedPlanPrice = (this.selectedPlanPrice?.value * 12).toFixed(2)
      }else{
        this.selectedPlanPrice = this.selectedPlanPrice?.value.toFixed(2)
      }
      this.calculate()
    }
    calculate() {
     this.subTotal = this.selectedPlanPrice
     this.discountedSubTotal = this.subTotal - this.discount

     this.saleTax = this.discountedSubTotal * 0.0825
      
     this.totalAmount = this.discountedSubTotal + this.saleTax
    }

    goBack(){
        window.history.back()
    }
    cancel(){
      this.applyDiscount = null
      this.inValidCode = false
      this.discount = 0
      this.calculate()
    }
    apply(){
      this._abd.showSpinner()

      setTimeout(() => {
        this.isLoading = false
               if(this.discountValue === "flat10%"){
            this.applyDiscount = this.discountValue
            this.discount = this.subTotal * 10/100
            let discountValue = this.discount
             let  disVal = discountValue.toString().split('.')
             let first = disVal[0];
             let second = disVal[1].substring(0, 2);
             let discountOnly = Number(first+'.'+second);
             this.discount =  discountOnly
         
             this.inValidCode = false
            this.discountValue = ""
        } else{
            this.inValidCode = true
            this.applyDiscount = null
                 } 
        this._abd.hideSpinner()
        this.calculate()  
      }, 4000);

    }
   paymentProceed(){
    this._signup.setInitialSubscriptionDetail(this._global.userInfo);
    this.sendDataToCentralHub({},true)
   }
   inputVlaue(){
    this.inValidCode = false
   }
   goToPricing(){
    window.open("https://lincsell.com/pricing/","_blank");
  }
   sendDataToCentralHub(responseDataFromAuthorize: any , authorizeResponseStatus:boolean) {
   this._abd.showSpinner()
    try {
        let websiteDomain : string = "";
        websiteDomain = window.location.origin + '/'; // this._globals.whiteLabel.domain
        var n = websiteDomain.lastIndexOf('/');
        var result = websiteDomain.substring(n + 1);

        if (result == "") {
            websiteDomain = websiteDomain.substring(0, websiteDomain.length - 1);

        }
        let hardwarePackagesId = [];
        let hardwarePkg = [];
        let SubscribedFeatures = [];

        let taxRate = this.saleTax;
     
        let wooCommercePlanPkg = []

        let domainPlanPkg  = [];

        let taxAmount = (this.selectedPlanPrice * taxRate)
        let TransactionDetails = {
            "accountType": "",
            "accountNumber": "",
            "transId": "",
            "responseCode": "1",
            "paymentMethod":"Stripe",
            "authorization": "",
            "merchantName": "Connect-360",
            "billTo": {
                "phoneNumber": this._signup.businessInfo?.phone,
                "firstName": "",
                "lastName": "",
                "address": this._signup.businessInfo?.address,
                "city": this._signup.businessInfo?.city,
                "state": this._signup.businessInfo?.state,
                "zip": this._signup.businessInfo?.zip,
                "country": "US"
            },
            "totalAmount": this._signup.checkoutPageCalcVars.gTotal,
            "dateTime": new Date()
        }
        // if (!authorizeResponseStatus){
        //     TransactionDetails = responseDataFromAuthorize
        // }
        let CCCustomerProfile = {
            "TransactionId": "",
            "StartDate": "2022-10-18",
            "IntervalLength": (this._signup.selectedPlanInterval ===  "Monthly" ? 1 : 12 ),
            "Amount": this.totalAmount.toFixed(2),
            "TrialAmount": 0,
            "SubTotal":this.subTotal,
            "Tax": this.saleTax.toFixed(2)
        };          
        let couponObj:any ;
        if(this._signup.couponCodeData?.couponCodes !== undefined){
             couponObj = {
          discountValueType:this._signup.couponCodeData?.discountValueType,
          id:this._signup.couponCodeData?.id,
          couponCodes:this._signup.couponCodeData?.couponCodes ,
          discountName:this._signup.couponCodeData?.discountName ,
          discountValue:this._signup.couponCodeDiscount
        }
      } else{
          couponObj = {}
      }
        let data = {
            "businessId": this._global.userInfo.businessSetup[0].businessId,
            "NoOfLocations": 1,
            "PerNoOfRegister": 1,
            "NoOfRegisters": 1,
            "userID": this._global.userInfo.businessSetup[0].userID,
            "SaveInfo": authorizeResponseStatus,
            "subscription": {
                "TransactionDetails": TransactionDetails,
                "hardwarePkgId": hardwarePackagesId,
                "totalHardwarePkgCost": "0",
               "totalSupportPkgCost":"0",
                "planCost": this.selectedPlanPrice.toString(),
                "planId": this._signup.selectedPlanDetails?.id,
                "totalCost": this.totalAmount.toFixed(2).toString(),
                "subscriptionPlanPkg": [
                    {
                        "packageId": this._signup.selectedPlanDetails?.id,
                        "qty": 1,
                        "pricePerPackage": this.selectedPlanPrice.toString(),
                        "subTotalCost": this.subTotal,
                        "packageType": 0,
                        "discountValue": this.discount,
                        "taxValue":  this.saleTax.toFixed(2).toString(),
                        "discountAmount": 0,
                        "taxAmount": this.saleTax.toFixed(2).toString(),
                        "isDiscountPercent": false,
                        "isTaxPercent":  true,
                        "totalPrice": this.totalAmount.toFixed(2).toString()
                    }
                ],

                "WooCommercePlanPkg": wooCommercePlanPkg,
                "DomainPlanPkg": domainPlanPkg ,
                "hardwarePkg": hardwarePkg,
                "TotalTaxAmount": this.saleTax.toFixed(2),
                "subTotalCost": this.subTotal,
                "TotalDiscountAmount": this.discount,
                "TotalTaxValue": taxRate,
                "TotalDiscountValue": 0,
                "IsTotalTaxPercente": true,
                "IsTotalDiscountPercent": false,
                "SubscribedFeatures": SubscribedFeatures
            },
            CCCustomerProfile: CCCustomerProfile,
            DiscountDet:couponObj,
            CouponCode:this.applyDiscount || null,
            SuccessUrl:`${websiteDomain}/#/setup/subscription-payment-success-merchant`,
            FailureUrl:`${websiteDomain}/#/app/check-out`,
        }
        // console.log(data)
        this._signup.saveUserSignupInfo(data).pipe(retry(3)).subscribe(res => {
            
            if (res.success && res.responseStatusCode == 903){
        //   if(res.success){
            
                this._signup.saveUserSignupInfoForStripe(data).pipe(retry(3)).subscribe(data => {
            //    if(data.success){
                let session = {
                    publicKey:data.publicKey,
                    sessionId:data.sessionId
                }
                   this.redirectToCheckout(session)
            //    }
                })
            //   }
                // if (!authorizeResponseStatus){
                    // this._signup.loadInit()
                    // sessionStorage.clear();
                    // sessionStorage.setItem('payment-successfully', '1');
                //     // this.route.navigate(['/setup/subscription-payment-success'])
                //     this.route.navigate(['/setup/subscription-payment-success-merchant'])
                // }

            }
            // else{
            //     if (authorizeResponseStatus){
            //         this.sendDataToCentralHub({}, true);
            //     }
            //     else{
            //         this._connMessageService.showToastMessage(res.message, 'error')
            //     }

            // }
        },error=>{
            this._abd.hideSpinner()
            if (authorizeResponseStatus) {
                // this.sendDataToCentralHub({}, true);
            }
            else {
                // this._connMessageService.showToastMessage(error.message, 'error')
            }
        })


    } catch (error) {
        this._abd.hideSpinner()
        // this.sendDataToCentralHub({}, true);
    }
}

redirectToCheckout(session: any) {
    let url = window.location.origin + '/#/app/check-out';
    localStorage.setItem('goto',url)
      const stripe = Stripe(session.publicKey);
      stripe?.redirectToCheckout({
        sessionId: session.sessionId,
      });
    }


    getNextDate(option:any): Date {
        const currentDate = new Date();
    
        if (option === 'Monthly') {
          currentDate.setMonth(currentDate.getMonth() + 1);
        } else if (option === 'Yearly') {
          currentDate.setFullYear(currentDate.getFullYear() + 1);
        } else {
          throw new Error("Invalid option. Use 'Monthly' or 'Yearly'.");
        }
    
        return currentDate;
      }
}
