import { GlobalService } from 'src/app/_services/global.service';
import { Component, AfterViewInit, OnDestroy, Renderer2, OnInit, Inject, HostListener, DoCheck } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppComponent } from './app.component';
import { ConfigService } from './service/app.config.service';
import { AppConfig } from './api/appconfig';
import { Subscription } from 'rxjs';
import { WhiteLabelList } from './_data/white-label-list';
import { UserService } from './_services/user.service';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { AppBaseDataService } from './_services/app-base-data.service';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { SubscriptionPlanService } from './_services/subscription-plan.service';

import { BusinessSetupModel, SubscriptionModel } from 'src/app/_models/subscription-management/subscription-model';
import { PlanInfo } from 'src/app/_models/subscription-management/user-subscription-info';
import { Plan } from 'src/app/_models/subscription-plan/plan';

@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html',
    animations: [
        trigger('submenu', [
            state('hidden', style({
                height: '0px'
            })),
            state('visible', style({
                height: '*'
            })),
            transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})

export class AppMainComponent implements AfterViewInit, OnDestroy, OnInit, DoCheck {

    public menuInactiveDesktop: boolean;

    public menuActiveMobile: boolean;

    public overlayMenuActive: boolean;

    public staticMenuInactive: boolean = false;

    public profileActive: boolean;

    public topMenuActive: boolean;

    public topMenuLeaving: boolean;

    public theme: string;

    documentClickListener: () => void;

    menuClick: boolean;

    topMenuButtonClick: boolean;

    configActive: boolean;

    configClick: boolean;

    config: AppConfig;

    subscription: Subscription;
    //IsOnSubscriptionPage=false
    items: MenuItem[];

    _globals = GlobalService
     isOnlineStore:boolean = false;
    dayLeftInRenewal: number = 0;
    IsUserOnTrial=false
    planInfo = <SubscriptionModel>{};
    businessSetup = <BusinessSetupModel>{}

    constructor(public renderer: Renderer2,
        public app: AppComponent, public configService: ConfigService,
        private _userService: UserService,
        @Inject(DOCUMENT) private _document: HTMLDocument,
        private title: Title,
        public _abd: AppBaseDataService,
        private route: ActivatedRoute,
        public _subscriptionManagementService: SubscriptionPlanService,
        private _router: Router) { }

    @HostListener('window:resize', ['$event'])
    showUpgradeBtn: boolean = true;
    onWindowResize() {
        this._abd.getScreenWidth = window.innerWidth;
        this._abd.getScreenHeight = window.innerHeight;
    }

    ngOnInit() {
        this.config = this.configService.config;
        this.subscription = this.configService.configUpdate$.subscribe(config => this.config = config);
          this.checkTour()
        this._router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator
            }

            if (event instanceof NavigationEnd) {
                if (this._router.url == '/app/billing-and-plans') {
                // if (this._router.url == '/app/subscription-management') {
                    this.showUpgradeBtn = false;
                }
                else {
                    this.showUpgradeBtn = true;
                }
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
                console.log(event.error);
            }
        });


        this.businessSetup = this._globals.userInfo.businessSetup[0];
        this.planInfo = this._globals.userInfo.businessSetup[0]?.subscription;
        this.IsUserOnTrial=this.businessSetup.isFreeTrial
        if (this.IsUserOnTrial) {
            // this.planInfo.freeTrialStartDate.toString()
            let startDate: Date = new Date();
            let endDate: Date = new Date(this.businessSetup.freeTrialEndDate.toString());

            let diff = Math.abs(endDate.getTime() - startDate.getTime());
            this.dayLeftInRenewal = Math.ceil(diff / (1000 * 3600 * 24));

        }
        else {
            let startDate: Date = new Date(this.businessSetup.createdDate.toString());
            let endDate: Date = new Date(this.planInfo.nextChargeDate.toString());

            let diff = Math.abs(endDate.getTime() - startDate.getTime());
            this.dayLeftInRenewal = Math.ceil(diff / (1000 * 3600 * 24));
        }

        //++++++++++++++++++++++

        // const sourceUrl = (window.location.href).toLowerCase()


        // if (sourceUrl.includes('localhost:')) wll = WhiteLabelList[0] // to be deleted later
        // else {
        //     for (let i = 0; i < WhiteLabelList.length; i++) {
        //         if (sourceUrl.includes(WhiteLabelList[i].wlName.toLowerCase() + '.net')) {
        //             wll = WhiteLabelList[i]
        //             break
        //         }
        //     }
        // }

        // if (!wll) wll = WhiteLabelList[0] // to be deleted later
        // if (wll) {
        //     this._globals.whiteLabel = wll
        //     if (this._userService.isUserAutoLogin()) {
        //
        //         this._userService.setCurrentUserFromLocalStorage()
        //         this._userService.getUserByUserName(this._userService.currentUserObj.userName)
        //         this._userService.getUserRoles()
        //         this._document.getElementById('appFavicon')?.setAttribute('href', 'assets/images/' + wll.favIcon + '.ico')
        //         this.title.setTitle(wll.wlName)
        //         this._abd.loadInit()
        //         //this._router.navigate(['']);
        //     }
        //     else
        //         this._router.navigate(['pages/signin']);
        // } else {
        //     this._router.navigate(['/error']);
        // }
    }
    checkTour() {
        let done = localStorage.getItem('isTour')
       if(this._globals.userInfo.businessSetup[0]?.isTour === false && (done === null || done !== 'true')){
           this._globals.currentTour = 1
        }else{
            this._globals.currentTour = this._globals.totalTour +1
        }
    }

    ngAfterViewInit() {
        // hides the overlay menu and top menu if outside is clicked
        this.documentClickListener = this.renderer.listen('body', 'click', (event) => {
            if (!this.isDesktop()) {
                if (!this.menuClick) {
                    this.menuActiveMobile = false;
                }

                if (!this.topMenuButtonClick) {
                    this.hideTopMenu();
                }
            }
            else {
                if (!this.menuClick && this.isOverlay()) {
                    this.menuInactiveDesktop = true;
                }
                if (!this.menuClick) {
                    this.overlayMenuActive = false;
                }
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            this.configClick = false;
            this.menuClick = false;
            this.topMenuButtonClick = false;
        });
    }
    ngDoCheck(): void {
       if(this._router.url.includes('app/online-store')){
         this.isOnlineStore  = true;
       }else{
        this.isOnlineStore  = false;
       }
    }
    toggleMenu(event: Event) {
        this.menuClick = true;
        this._globals.moveMainMenuToggleSwitch = !this._globals.moveMainMenuToggleSwitch

        if (this.isDesktop()) {
            if (this.app.menuMode === 'overlay') {
                if (this.menuActiveMobile === true) {
                    this.overlayMenuActive = true;
                }

                this.overlayMenuActive = !this.overlayMenuActive;
                this.menuActiveMobile = false;
            }
            else if (this.app.menuMode === 'static') {
                this.staticMenuInactive = !this.staticMenuInactive;
            }
        }
        else {
            this.menuActiveMobile = !this.menuActiveMobile;
            this.topMenuActive = false;
        }

        event.preventDefault();
    }

    toggleMenuDummy() {
        this.menuClick = true;
        this._globals.moveMainMenuToggleSwitch = true //!this._globals.moveMainMenuToggleSwitch
        this.staticMenuInactive = true
    }

    toggleProfile(event: Event) {
        this.profileActive = !this.profileActive;
        event.preventDefault();
    }

    toggleTopMenu(event: Event) {
        this.topMenuButtonClick = true;
        this.menuActiveMobile = false;

        if (this.topMenuActive) {
            this.hideTopMenu();
        } else {
            this.topMenuActive = true;
        }

        event.preventDefault();
    }

    hideTopMenu() {
        this.topMenuLeaving = true;
        setTimeout(() => {
            this.topMenuActive = false;
            this.topMenuLeaving = false;
        }, 1);
    }

    onMenuClick() {
        this.menuClick = true;
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    isStatic() {
        return this.app.menuMode === 'static';
    }

    isOverlay() {
        return this.app.menuMode === 'overlay';
    }

    isDesktop() {
        return window.innerWidth > 992;
    }

    isMobile() {
        return window.innerWidth < 1024;
    }

    onSearchClick() {
        this.topMenuButtonClick = true;
    }

    ngOnDestroy() {
        if (this.documentClickListener) {
            this.documentClickListener();
        }


        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    navigateToSubscription(){
        this._router.navigateByUrl('app/billing-and-plans')
        // this._router.navigateByUrl('app/subscription-management')
    }
}
