export let CurrentPlan:any[] = [
        {
            "planId": "1",
            "wlId": "94DE1528-DE42-498A-A07E-4A458E97240E",
            "orderNo": 1,
            "title": "Free Trial",
            "note": "",
            "rawData": "",
            "price": 0,
            "isActive": true,
            "isRenewal": true,
            "isTrial": true,
            "isUpgrade": true,
            "isDowngrade": true,
            "description": "Great for start-ups​",
            "recommended": false,
            "allowedLocations": 1,
            "perAllowedRegisters": 2,
            "modified": "2021-08-09T13:48:50.3353378Z",
            "durationType": "​30 Day Free Trial",
            "isCustom": false,
            "featureHeading": "",
            "features": [
                {
                    "data": "Locations: 1",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Registers: 1",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Users: 1",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Inventory: Limited",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Legal Business Entity: 1",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Revenues /month/business: $15,000",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Additional Register: N/A",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Additional Location + 1Reg: N/A",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "CC Fee with Card: Only Cash",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Static Website: N/A",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Basic eCommerce Store: N/A",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Loyalty Program: N/A",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Reporting and Business Analytics: Standard",
                    "isActive": true,
                    "features": []
                }
            ],
            "featureData": [
                {
                    "orderNo": 1,
                    "type": 0,
                    "typeString": "Locations",
                    "title": "Locations",
                    "value": "1",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 2,
                    "type": 1,
                    "typeString": "Registers",
                    "title": "Registers",
                    "value": "1",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 3,
                    "type": 2,
                    "typeString": "Users",
                    "title": "Users",
                    "value": "1",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 4,
                    "type": 3,
                    "typeString": "Inventory",
                    "title": "Inventory",
                    "value": "Limited",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 5,
                    "type": 4,
                    "typeString": "LegalBusinessEntity",
                    "title": "Legal Business Entity",
                    "value": "1",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 6,
                    "type": 13,
                    "typeString": "RevenuesPerMonthPerbusiness",
                    "title": "Revenues /month/business",
                    "value": "$15,000",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 7,
                    "type": 6,
                    "typeString": "AdditionalRegister",
                    "title": "Additional Register",
                    "value": "N/A",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": false,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 8,
                    "type": 7,
                    "typeString": "AdditionalLocationPlus1Reg",
                    "title": "Additional Location + 1Reg",
                    "value": "N/A",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": false,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 9,
                    "type": 8,
                    "typeString": "CCFeeWithCard",
                    "title": "CC Fee with Card",
                    "value": "Only Cash",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 10,
                    "type": 9,
                    "typeString": "StaticWebsite",
                    "title": "Static Website",
                    "value": "N/A",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": false,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 11,
                    "type": 10,
                    "typeString": "BasiceCommerceStore",
                    "title": "Basic eCommerce Store",
                    "value": "N/A",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": false,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 12,
                    "type": 14,
                    "typeString": "LoyaltyProgram",
                    "title": "Basic Loyalty Program",
                    "value": "N/A",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 13,
                    "type": 12,
                    "typeString": "ReportingAndBusinessAnalytics",
                    "title": "Reporting & Business Analytics",
                    "value": "Standard",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 14,
                    "type": 17,
                    "typeString": "AdvancedLoyaltyProgram",
                    "title": "Advanced Loyalty Program",
                    "value": "N/A",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": false,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                }
            ],
            "limits": [
                {
                    "period": 4,
                    "periodString": "Upfront",
                    "limitType": 1,
                    "limitTypeString": "MaxLocationsPerBusiness",
                    "value": 1
                },
                {
                    "period": 4,
                    "periodString": "Upfront",
                    "limitType": 2,
                    "limitTypeString": "MaxRegistersPerBusiness",
                    "value": 2
                },
                {
                    "period": 3,
                    "periodString": "Yearly",
                    "limitType": 4,
                    "limitTypeString": "MaxRevenuePerBusiness",
                    "value": 250000
                }
            ],
            "prices": [
                      {
                    "period": 2,
                    "periodString": "Monthly",
                    "priceLimitType": 1,
                    "priceLimitTypeString": "billed monthly (USD)",
                    "value": 0
                },
                {
                    "period": 2,
                    "periodString": "Yearly",
                    "priceLimitType": 2,
                    "priceLimitTypeString": "billed annually (USD)",
                    "value": 0
                }
         
            ],
            "wlApplicationEnvironment": 2,
            "id": "162851695001",
            "createdDate": "2024-01-11T05:03:01.7210767Z",
            "partitionKey": "0"
        }, 
        {
            "planId": "2",
            "wlId": "94DE1528-DE42-498A-A07E-4A458E97240E",
            "orderNo": 0,
            "title": "Webstore",
            "note": "",
            "rawData": "",
            "price": 79.99,
            "isActive": true,
            "isRenewal": true,
            "isTrial": false,
            "isUpgrade": true,
            "isDowngrade": true,
            "description": "Start-up​",
            "recommended": false,
            "allowedLocations": 1,
            "perAllowedRegisters": 1,
            "modified": "2021-08-09T13:48:50.3353378Z",
            "durationType": "/month billed annually or $19.99 monthly",
            "isCustom": false,
            "featureHeading": "",
            "features": [
                {
                    "data": "Locations: 1",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Registers: 1",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Users: 2",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Inventory: 200",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Legal Business Entity: 1",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Revenues /month/business: $15,000",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Additional Register: N/A",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Additional Location + 1Reg: N/A",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "CC Fee with Card: 2.59% + 15C",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Static Website: $9.99+",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Basic eCommerce Store: $49.99",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Loyalty Program: $39.99/month",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Reporting and Business Analytics: Standard",
                    "isActive": true,
                    "features": []
                }
            ],
            "featureData": [
                {
                    "orderNo": 1,
                    "type": 0,
                    "typeString": "Locations",
                    "title": "Locations",
                    "value": "1",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 2,
                    "type": 1,
                    "typeString": "Registers",
                    "title": "Registers",
                    "value": "1",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 3,
                    "type": 2,
                    "typeString": "Users",
                    "title": "Users",
                    "value": "1",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 4,
                    "type": 3,
                    "typeString": "Inventory",
                    "title": "Inventory",
                    "value": "Unlimited",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": true,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 5,
                    "type": 4,
                    "typeString": "LegalBusinessEntity",
                    "title": "Legal Business Entity",
                    "value": "1",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 6,
                    "type": 13,
                    "typeString": "RevenuesPerMonthPerbusiness",
                    "title": "Revenues /month/business",
                    "value": "$15,000",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 7,
                    "type": 6,
                    "typeString": "AdditionalRegister",
                    "title": "Additional Register",
                    "value": "N/A",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": false,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 8,
                    "type": 7,
                    "typeString": "AdditionalLocationPlus1Reg",
                    "title": "Additional Location + 1Reg",
                    "value": "N/A",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": false,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 9,
                    "type": 8,
                    "typeString": "CCFeeWithCard",
                    "title": "CC Fee with Card",
                    "value": "2.59% + 15C",
                    "price": 15,
                    "percentValue": 2.59,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 10,
                    "type": 9,
                    "typeString": "StaticWebsite",
                    "title": "Static Website",
                    "value": "$9.99+",
                    "price": 9.99,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": true
                },
                {
                    "orderNo": 11,
                    "type": 10,
                    "typeString": "BasiceCommerceStore",
                    "title": "Basic eCommerce Store",
                    "value": "$49.99",
                    "price": 49.99,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": true
                },
                {
                    "orderNo": 12,
                    "type": 14,
                    "typeString": "LoyaltyProgram",
                    "title": "Customer Loyalty Program",
                    "value": "Basic",
                    "price": 39.99,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": true
                },
                {
                    "orderNo": 13,
                    "type": 12,
                    "typeString": "ReportingAndBusinessAnalytics",
                    "title": "Reporting & Business Analytics",
                    "value": "Standard",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 14,
                    "type": 17,
                    "typeString": "AdvancedLoyaltyProgram",
                    "title": "Advanced Loyalty Program",
                    "value": "$99.99/month",
                    "price": 99.99,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": true
                }
            ],
            "notes": [
                {
                    "data": "Additional location is a physical outlet with a differrent address",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Business entity is an entiy that will be defined for credit card processing",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Businesses not using credit cards will have 2% service charge of their gross receipts",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Allow cancellation any time",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Yearly subscription cancellation fee is 10% of the total contract",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Monthly subscription cancellation requires 30 day notice",
                    "isActive": true,
                    "features": []
                }
            ],
            "limits": [
                {
                    "period": 4,
                    "periodString": "Upfront",
                    "limitType": 1,
                    "limitTypeString": "MaxLocationsPerBusiness",
                    "value": 1
                },
                {
                    "period": 4,
                    "periodString": "Upfront",
                    "limitType": 2,
                    "limitTypeString": "MaxRegistersPerBusiness",
                    "value": 2
                },
                {
                    "period": 3,
                    "periodString": "Yearly",
                    "limitType": 4,
                    "limitTypeString": "MaxRevenuePerBusiness",
                    "value": 250000
                }
            ],
            "prices": [
             
                {
                    "period": 2,
                    "periodString": "Monthly",
                    "priceLimitType": 1,
                    "priceLimitTypeString": "billed monthly (USD)",
                    "value": 99.99
                },
                {
                    "period": 2,
                    "periodString": "Yearly",
                    "priceLimitType": 2,
                    "priceLimitTypeString": "billed annually (USD)",
                    "value": 79.99
                },
                
            ],
            "wlApplicationEnvironment": 2,
            "id": "1628516995000",
            "createdDate": "2024-01-11T05:03:01.7210769Z",
            "partitionKey": "0"
        },
        {
            "planId": "3",
            "wlId": "94DE1528-DE42-498A-A07E-4A458E97240E",
            "orderNo": 0,
            "title": "Mobile App",
            "note": "",
            "rawData": "",
            "price": 19.99,
            "isActive": true,
            "isRenewal": true,
            "isTrial": false,
            "isUpgrade": true,
            "isDowngrade": true,
            "description": "Start-up​",
            "recommended": false,
            "allowedLocations": 1,
            "perAllowedRegisters": 1,
            "modified": "2021-08-09T13:48:50.3353378Z",
            "durationType": "/month billed annually or $19.99 monthly",
            "isCustom": false,
            "featureHeading": "",
            "features": [
                {
                    "data": "Locations: 1",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Registers: 1",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Users: 2",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Inventory: 200",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Legal Business Entity: 1",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Revenues /month/business: $15,000",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Additional Register: N/A",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Additional Location + 1Reg: N/A",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "CC Fee with Card: 2.59% + 15C",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Static Website: $9.99+",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Basic eCommerce Store: $49.99",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Loyalty Program: $39.99/month",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Reporting and Business Analytics: Standard",
                    "isActive": true,
                    "features": []
                }
            ],
            "featureData": [
                {
                    "orderNo": 1,
                    "type": 0,
                    "typeString": "Locations",
                    "title": "Locations",
                    "value": "1",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 2,
                    "type": 1,
                    "typeString": "Registers",
                    "title": "Registers",
                    "value": "1",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 3,
                    "type": 2,
                    "typeString": "Users",
                    "title": "Users",
                    "value": "1",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 4,
                    "type": 3,
                    "typeString": "Inventory",
                    "title": "Inventory",
                    "value": "Unlimited",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": true,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 5,
                    "type": 4,
                    "typeString": "LegalBusinessEntity",
                    "title": "Legal Business Entity",
                    "value": "1",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 6,
                    "type": 13,
                    "typeString": "RevenuesPerMonthPerbusiness",
                    "title": "Revenues /month/business",
                    "value": "$15,000",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 7,
                    "type": 6,
                    "typeString": "AdditionalRegister",
                    "title": "Additional Register",
                    "value": "N/A",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": false,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 8,
                    "type": 7,
                    "typeString": "AdditionalLocationPlus1Reg",
                    "title": "Additional Location + 1Reg",
                    "value": "N/A",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": false,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 9,
                    "type": 8,
                    "typeString": "CCFeeWithCard",
                    "title": "CC Fee with Card",
                    "value": "2.59% + 15C",
                    "price": 15,
                    "percentValue": 2.59,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 10,
                    "type": 9,
                    "typeString": "StaticWebsite",
                    "title": "Static Website",
                    "value": "$9.99+",
                    "price": 9.99,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": true
                },
                {
                    "orderNo": 11,
                    "type": 10,
                    "typeString": "BasiceCommerceStore",
                    "title": "Basic eCommerce Store",
                    "value": "$49.99",
                    "price": 49.99,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": true
                },
                {
                    "orderNo": 12,
                    "type": 14,
                    "typeString": "LoyaltyProgram",
                    "title": "Customer Loyalty Program",
                    "value": "Basic",
                    "price": 39.99,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": true
                },
                {
                    "orderNo": 13,
                    "type": 12,
                    "typeString": "ReportingAndBusinessAnalytics",
                    "title": "Reporting & Business Analytics",
                    "value": "Standard",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 14,
                    "type": 17,
                    "typeString": "AdvancedLoyaltyProgram",
                    "title": "Advanced Loyalty Program",
                    "value": "$99.99/month",
                    "price": 99.99,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": true
                }
            ],
            "notes": [
                {
                    "data": "Additional location is a physical outlet with a differrent address",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Business entity is an entiy that will be defined for credit card processing",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Businesses not using credit cards will have 2% service charge of their gross receipts",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Allow cancellation any time",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Yearly subscription cancellation fee is 10% of the total contract",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Monthly subscription cancellation requires 30 day notice",
                    "isActive": true,
                    "features": []
                }
            ],
            "limits": [
                {
                    "period": 4,
                    "periodString": "Upfront",
                    "limitType": 1,
                    "limitTypeString": "MaxLocationsPerBusiness",
                    "value": 1
                },
                {
                    "period": 4,
                    "periodString": "Upfront",
                    "limitType": 2,
                    "limitTypeString": "MaxRegistersPerBusiness",
                    "value": 2
                },
                {
                    "period": 3,
                    "periodString": "Yearly",
                    "limitType": 4,
                    "limitTypeString": "MaxRevenuePerBusiness",
                    "value": 250000
                }
            ],
            "prices": [
            
                {
                    "period": 2,
                    "periodString": "Monthly",
                    "priceLimitType": 1,
                    "priceLimitTypeString": "billed monthly (USD)",
                    "value": 459.99
                },
                {
                    "period": 2,
                    "periodString": "Yearly",
                    "priceLimitType": 2,
                    "priceLimitTypeString": "billed annually (USD)",
                    "value": 367.99
                },
                
            ],
            "wlApplicationEnvironment": 2,
            "id": "1628516995000",
            "createdDate": "2024-01-11T05:03:01.7210769Z",
            "partitionKey": "0"
        }, 
        {
            "planId": "4",
            "wlId": "94DE1528-DE42-498A-A07E-4A458E97240E",
            "orderNo": 0,
            "title": "Webstore & Mobile App",
            "note": "",
            "rawData": "",
            "price": 19.99,
            "isActive": true,
            "isRenewal": true,
            "isTrial": false,
            "isUpgrade": true,
            "isDowngrade": true,
            "description": "Start-up​",
            "recommended": false,
            "allowedLocations": 1,
            "perAllowedRegisters": 1,
            "modified": "2021-08-09T13:48:50.3353378Z",
            "durationType": "/month billed annually or $19.99 monthly",
            "isCustom": false,
            "featureHeading": "",
            "features": [
                {
                    "data": "Locations: 1",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Registers: 1",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Users: 2",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Inventory: 200",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Legal Business Entity: 1",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Revenues /month/business: $15,000",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Additional Register: N/A",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Additional Location + 1Reg: N/A",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "CC Fee with Card: 2.59% + 15C",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Static Website: $9.99+",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Basic eCommerce Store: $49.99",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Loyalty Program: $39.99/month",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Reporting and Business Analytics: Standard",
                    "isActive": true,
                    "features": []
                }
            ],
            "featureData": [
                {
                    "orderNo": 1,
                    "type": 0,
                    "typeString": "Locations",
                    "title": "Locations",
                    "value": "1",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 2,
                    "type": 1,
                    "typeString": "Registers",
                    "title": "Registers",
                    "value": "1",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 3,
                    "type": 2,
                    "typeString": "Users",
                    "title": "Users",
                    "value": "1",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 4,
                    "type": 3,
                    "typeString": "Inventory",
                    "title": "Inventory",
                    "value": "Unlimited",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": true,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 5,
                    "type": 4,
                    "typeString": "LegalBusinessEntity",
                    "title": "Legal Business Entity",
                    "value": "1",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 6,
                    "type": 13,
                    "typeString": "RevenuesPerMonthPerbusiness",
                    "title": "Revenues /month/business",
                    "value": "$15,000",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 7,
                    "type": 6,
                    "typeString": "AdditionalRegister",
                    "title": "Additional Register",
                    "value": "N/A",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": false,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 8,
                    "type": 7,
                    "typeString": "AdditionalLocationPlus1Reg",
                    "title": "Additional Location + 1Reg",
                    "value": "N/A",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": false,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 9,
                    "type": 8,
                    "typeString": "CCFeeWithCard",
                    "title": "CC Fee with Card",
                    "value": "2.59% + 15C",
                    "price": 15,
                    "percentValue": 2.59,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 10,
                    "type": 9,
                    "typeString": "StaticWebsite",
                    "title": "Static Website",
                    "value": "$9.99+",
                    "price": 9.99,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": true
                },
                {
                    "orderNo": 11,
                    "type": 10,
                    "typeString": "BasiceCommerceStore",
                    "title": "Basic eCommerce Store",
                    "value": "$49.99",
                    "price": 49.99,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": true
                },
                {
                    "orderNo": 12,
                    "type": 14,
                    "typeString": "LoyaltyProgram",
                    "title": "Customer Loyalty Program",
                    "value": "Basic",
                    "price": 39.99,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": true
                },
                {
                    "orderNo": 13,
                    "type": 12,
                    "typeString": "ReportingAndBusinessAnalytics",
                    "title": "Reporting & Business Analytics",
                    "value": "Standard",
                    "price": 0,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": false
                },
                {
                    "orderNo": 14,
                    "type": 17,
                    "typeString": "AdvancedLoyaltyProgram",
                    "title": "Advanced Loyalty Program",
                    "value": "$99.99/month",
                    "price": 99.99,
                    "percentValue": 0,
                    "isAvailable": true,
                    "unlimited": false,
                    "addMore": false,
                    "isCustomized": false,
                    "isActive": true,
                    "isAddon": true
                }
            ],
            "notes": [
                {
                    "data": "Additional location is a physical outlet with a differrent address",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Business entity is an entiy that will be defined for credit card processing",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Businesses not using credit cards will have 2% service charge of their gross receipts",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Allow cancellation any time",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Yearly subscription cancellation fee is 10% of the total contract",
                    "isActive": true,
                    "features": []
                },
                {
                    "data": "Monthly subscription cancellation requires 30 day notice",
                    "isActive": true,
                    "features": []
                }
            ],
            "limits": [
                {
                    "period": 4,
                    "periodString": "Upfront",
                    "limitType": 1,
                    "limitTypeString": "MaxLocationsPerBusiness",
                    "value": 1
                },
                {
                    "period": 4,
                    "periodString": "Upfront",
                    "limitType": 2,
                    "limitTypeString": "MaxRegistersPerBusiness",
                    "value": 2
                },
                {
                    "period": 3,
                    "periodString": "Yearly",
                    "limitType": 4,
                    "limitTypeString": "MaxRevenuePerBusiness",
                    "value": 250000
                }
            ],
            "prices": [
                 {
                    "period": 2,
                    "periodString": "Monthly",
                    "priceLimitType": 1,
                    "priceLimitTypeString": "billed monthly (USD)",
                    "value": 959.99
                },
                {
                    "period": 2,
                    "periodString": "Yearly",
                    "priceLimitType": 2,
                    "priceLimitTypeString": "billed annually (USD)",
                    "value": 767.99
                },
              
                
            ],
            "wlApplicationEnvironment": 2,
            "id": "1628516995000",
            "createdDate": "2024-01-11T05:03:01.7210769Z",
            "partitionKey": "0"
        },

 
    
    ]
