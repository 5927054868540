<div class="card p-0 m-0">
<div class="checkout-container">
  <div class="breadcrumb">
    <span class="cursor-pointer" (click)="goBack()">Billing & Plans</span><i class="pi pi-angle-right text-lg px-2"></i> <span class="current">Checkout</span>
  </div>
  <p class="description">
    Select a suitable plan according to your business needs.
  </p>

  <div class="checkout-grid">
    <!-- Plan Details Section -->
    <div class="plan-details">
      <h3>Plan Details</h3>
      <div class="current-plan">
        <div class="current-plan-left">
          <p>Current Plan</p>
          <h4>{{planData?.planName}}</h4>
        </div>
        <div class="current-plan-right">
          <p>Upgrade to</p>
          <div class="dropdown-wrapper">
            <select class="dropdown" [(ngModel)]="select" (change)="onPlanChange(select)">
              <option [value]="plan.planId" *ngFor="let plan of plans">{{plan.title}}</option>
            </select>
          </div>
          <a class="see-details flex align-items-center cursor-pointer" (click)="goToPricing()">
            See plan details <img class="icon px-2" src="assets/images/see_all_features.svg" alt="">
          </a>
        </div>
      </div>

      <h3>Payment</h3>
      <div class="payment-options">
        <label class="radio-option" *ngFor="let price of _signup.selectedPlanDetails?.prices">
          <input 
          type="radio"
          name="plan"
          [value]="price.periodString" 
         [(ngModel)]="_signup.selectedPlanInterval" 
         (change)="onPlanIntervalChange(_signup.selectedPlanInterval)"
          />
          <span class="option-label">
            <span class="flex align-items-center" *ngIf="price?.periodString === 'Yearly'">
              <strong>{{price?.periodString}}</strong><span class="save">(Save $240)</span>
            </span>
            <strong *ngIf="price?.periodString !== 'Yearly'">{{price?.periodString}}</strong>
            <span class="option-description">{{price?.value | currency}}/month billed {{ price?.periodString === 'Yearly' ?  'yearly' : 'monthly'}}</span>
          </span>
        </label>

      </div>
    </div>

    <!-- Summary Section -->
    <div class="summary">
      <div class="flex justify-content-between pricing">
            <span>
        <strong>{{_signup.selectedPlanDetails?.title}}</strong>
      <p class="plan-duration pt-2">{{_signup.selectedPlanInterval}} subscription till {{getNextDate(_signup.selectedPlanInterval) | date:'dd MMM, yyyy'}}</p>
      </span>
      <span>
        <strong>{{selectedPlanPrice | currency}}</strong>
      <p class="plan-duration pt-2">Every {{(_signup.selectedPlanInterval === 'Yearly' ? 'Year' : 'Month')}}</p>
      </span> 
      </div>
      <div class="pricing-details">
       
        <div class="pricing-row py-1">
          <span>Discount </span>
          <strong class="discount">{{'-$'+discount}}</strong>
        </div>
        <div class="pricing-row py-1">
          <span>Sales Tax <span>(8.25%)</span></span>
          <strong>{{saleTax | currency}}</strong>
        </div>
      </div>
      <div class="coupon-section flex-column">
       <div class="flex">
          <input
          type="text"
          class="coupon-input"
          placeholder="Coupon or discount code here"
          [(ngModel)]="discountValue"
          (change)="inputVlaue()"
        />
        <button class="apply-btn" (click)="apply()" [disabled]="discountValue === null || discountValue === '' ">Apply</button>
       </div>
        <small class="invalid-code" *ngIf="inValidCode">Code not valid</small>
      </div>
      <div class="applied-coupon" *ngIf="applyDiscount !== null">
        <span>{{applyDiscount}}</span>
        <button class="remove-coupon" (click)="cancel()" >✕</button>
      </div>
      <div class="total-section">
        <div class="pricing-row px-5 py-2">
          <span>Subtotal</span>
          <span>{{subTotal | currency}}</span>
        </div>
        <div class="pricing-row">
         <span class="total-amount px-5 py-5">
          <strong>Amount due</strong>
          <strong>{{totalAmount | currency}} USD</strong>
         </span>
       
        </div>
      </div>
    </div>
    <span></span>
    <span><button class="proceed-btn" (click)="paymentProceed()">Proceed to Payment</button>
    <p class="note">You will be charged in the next step</p></span>
  </div>
</div>