<div class="flex justify-content-between align-content-center bb">

 <span class="back-btn mx-4 my-2"  (click)="setTheme('back')">
  <img class="px-2" width="30px" src="assets/images/back_arrow.svg" alt=""> Back
 </span>
 
  <span class="top-text text-xl font-semibold">
          You are previewing Template
  </span>
    <span class="usetheme-btn mx-4 my-2"  (click)="setTheme('usetheme',_businessSetup?.selectedTheme)">
        Use Template
     </span>
    
</div>
<div class="flex  justify-content-center">
    <div class="col flex justify-content-center box mx-5" >
      <!-- <div class="innerImage" [style.background-image]="_businessSetup?.selectedTheme?.isAvailable === true ? 'url(' + 'https://temp-p7q3z6.lincsell.com/wp-content/uploads/2024/09/template_alpha-scaled.webp' + ')' : ''"> -->
      <!-- <div class="innerImage" [style.background-image]="_businessSetup?.selectedTheme?.isAvailable === true ? 'url(' + _businessSetup?.selectedTheme?.imageUrl + ')' : ''">

      </div> -->
      <div class="image-container">
        <img [src]="_businessSetup?.selectedTheme?.imageUrl" alt="Image 1">
    </div>
    <!-- <img  class="w-full h-full" alt=""> -->
    </div>

 
</div>