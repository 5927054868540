import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { WhiteLabelList } from 'src/app/_data/white-label-list';
import { UserSubscriptionInfo } from 'src/app/_models/subscription-management/user-subscription-info';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { SignupService } from 'src/app/_services/signup.service';
import { SubscriptionPlanService } from 'src/app/_services/subscription-plan.service';
 

interface Plan {
    id:number
    name: string;
    price: number;
    billingCycle: string;
    recommended?: boolean;
    button:string;
    url:string;
  }

  interface Invoice {
    invoiceNumber: string;
    date: string;
    plan: string;
    recurrence?: string;
    charge: string;
    nextPayment?: string;
    status: 'Active' | 'Expired';
  }

@Component({
    selector: 'app-billing-and-plans',
    templateUrl: './billing-and-plans.component.html',
    styleUrls: ['./billing-and-plans.component.scss'],
})
export class BillingAndPlansComponent implements OnInit {
 
    isMonthly:boolean = false
   _global = GlobalService
   plans: any[] = [];
   currentPlanDetails:any = null
   trialDaysLeft: number = 0;
   invoices: any[] = []
   
//   Change Plan
upgradeRequested: boolean = false;
whiteLabelList = WhiteLabelList;
who: any = null;
currentPlanID: number = 0;
changedPlanID: number = 0;

selectedPlanForSubscription = <any>{};
planChanged: boolean = false;

buylocationList = <any>{};
buyRegisterList = <any>{};
ShowCustomConfirmation = false;
confirmationText: any;
CurrentAction = 0;
ErrorMessage = '';
currentSubscriptionCharges = 0;
newSubscriptionCharges = 0;
subscriptionChangeNotes = '';
upgradeSucessMessage = '';
 
  planData: any = null;
    constructor(
        public _signup: SignupService,
        private _router: Router,
        public _abd: AppBaseDataService,
        private activeRouter: ActivatedRoute,
        private _spinner: NgxSpinnerService,
        private _subscriptionPlanService: SubscriptionPlanService,
    ) {}



    ngOnInit(): void {
    this.daysLeft()
    this.getCurrentPlanDetails() 
    this.getInvoices()
  }
  getInvoices(){
    try {
     this._abd.showSpinner()
     this._subscriptionPlanService.getBusinessBillingInvoices().subscribe((res:UserSubscriptionInfo | any)=>{
      this._abd.hideSpinner()      
      if(res.success){
           this.invoices = res.data.map((val,index,array)=>{
            return{
              invoiceNumber:val.invoiceNo,
              date:val.dateTime,
              plan:val.planName,
              recurrence:val.planInterval,
              charge:val.grandTotal,
              nextPayment:val.nextChargeDate,
              status:index === array.length - 1 ? "Active" : "Expired"
            }
           }).reverse();
             }
     })
   } catch (error) {
    
   }
  }
  getRecurrence(value:any){
    if(value === 1){
      return "Monthly"
    }else if( value === 12){
      return "Yearly"
    }else{
      return "-"
    }
  }
  getCurrentPlanDetails(){
    try {
        this._abd.showSpinner()
               this._subscriptionPlanService.getBusinessInvoice().subscribe(
                    (res: any) => {
                       this._abd.hideSpinner()
                       this.currentPlan() 
                       if (res.responseStatusCode == 903) {
                            this.planData = res.data.currentSubscription;
                            this.isMonthly = (this.planData?.subscriptionInterval === 1 ? true: false )
                        }
                    },
                    (error) => {
                      this.currentPlan()
                        this._abd.hideSpinner()
                    }
                );
    } catch (error) {
        this._abd.hideSpinner()
        this.currentPlan()
    }
}
  currentPlan(){ 
    this._abd.showSpinner()
    try {
            this._signup.getSubscriptionPlans().subscribe((res)=>{
        this._abd.hideSpinner()
              if(res.success){
                this.plans =  res.data.filter((p)=>p.isTrial !== true) 
                this.plans = this.plans.map((p)=>{
                  return{
                     ...p,prices:p.prices.find(price => price.periodString === (this.isMonthly ? 'Monthly' : 'Yearly'))
                  }
                }) 
                this.plans = this.plans.map((item, index) => {
                 
                  const btnTexts = [];
                  if(item.planId === this.planData.planId){
                    item.button = btnTexts[index] = 'Your Current Plan'
                    item.selected = true
                  }else{
                    item.button = btnTexts[index] = 'Upgrade to '+item?.title.toLowerCase()
                    item.selected = false
                  }                  

                  return item;
                });
      }  
 
    })
    } catch (error) {
      this._abd.hideSpinner()
    }
    
  }

    
    currentInterval(value:boolean){
      this.isMonthly = value
      this.currentPlan()
      this._signup.selectedPlanInterval = value === true ? "Monthly" : "Yearly"
    }
    goToCheckOut(value:any){
       let planId = this.plans.filter((v)=>v.selected === true)[0]?.planId
       this._signup.selectedPlanDetails = value;
       localStorage.setItem('selectedPlanDetails',JSON.stringify(this._signup.selectedPlanDetails))
      if(this._global.userInfo?.businessSetup[0]?.isFreeTrial){
       this._router.navigate(["app/check-out"])
       }else{
        this.selectedPlanForSubscription = this._signup.selectedPlanDetails
        if(value?.planId  >  planId){
          this.upgradePlan()
        }else{
          this.downgradePlan()
        }
       }

    }
    goToPricing(){
      window.open("https://lincsell.com/pricing/","_blank");
    }
    daysLeft(){
      let businessSetup = this._global.userInfo?.businessSetup[0];
      let planInfo = this._global.userInfo?.businessSetup[0]?.subscription;
      this.currentPlanDetails = this._global.userInfo?.businessSetup[0]
      let IsUserOnTrial=businessSetup?.isFreeTrial
      if (IsUserOnTrial) {
          let startDate: Date = new Date();
          let endDate: Date = new Date(businessSetup?.freeTrialEndDate.toString());

          let diff = Math.abs(endDate.getTime() - startDate.getTime());
          return Math.ceil(diff / (1000 * 3600 * 24));

      }
      else {
          let startDate: Date = new Date(businessSetup?.createdDate.toString());
          let endDate: Date = new Date(planInfo?.nextChargeDate.toString());

          let diff = Math.abs(endDate.getTime() - startDate.getTime());
          return Math.ceil(diff / (1000 * 3600 * 24));
      }

    }

    performAction() {
      this.ErrorMessage = '';
      //upgrade subscription
      if (this.CurrentAction == 1) {
          this.upgradeSubPlan();
      } else if (this.CurrentAction == 2) {
          this.downgradeSubPlan();
      }
  }


 upgradePlan() {
      this.ErrorMessage = '';  
          this.ShowCustomConfirmation = true;
          this.CurrentAction = 1;
          this.upgradeSubPlan('false');
      
  }
  downgradePlan() {
          this.ErrorMessage = '';
          this.ShowCustomConfirmation = true;
          this.CurrentAction = 2;
          this.downgradeSubPlan('false');
  }

  upgradeSubPlan(execute = 'true') {
      let Sucess = false;
      try {
          this.ErrorMessage = '';
          this.upgradeSucessMessage = '';
          this._abd.showSpinner();
          let newplan = {
              BuySubscriptionPlanId: this.selectedPlanForSubscription.planId,
          };
          let stripe:boolean = false;
          if(this._global.userInfo.businessSetup[0]?.subscription?.transactionDetails?.paymentMethod === "Stripe"){
            stripe = true
          }else{
           stripe = false
          }
          this._subscriptionPlanService
              .upgradeSubscription(newplan, true, execute,stripe)
              .subscribe((res) => {
                  if (res.success) {
                      Sucess = true;
                      this.subscriptionChangeNotes = res.data.note;
                      this.currentSubscriptionCharges =
                          res.data.currentSubscriptionCharges;
                      this.newSubscriptionCharges =
                          res.data.newSubscriptionCharges;
                      if (execute == 'true') {
                          this.upgradeSucessMessage =
                              'You have sucessfully updated your plan.';
                              this.getCurrentPlanDetails() 
                              this.getInvoices()
                          
                      }
                  } else {
                      this.ErrorMessage =
                          'Plan change failed, Please try again or contact support!';
                  }
              });
      } catch {}
  }
  downgradeSubPlan(execute = 'true') {
      let Sucess = false;
      this.upgradeSucessMessage = '';
      try {
          this.ErrorMessage = '';
          this._abd.showSpinner();
          let newplan = {
              BuySubscriptionPlanId: this.selectedPlanForSubscription.planId,
          };
          let stripe:boolean = false;
         if(this._global.userInfo.businessSetup[0]?.subscription?.transactionDetails?.paymentMethod === "Stripe"){
           stripe = true
         }else{
          stripe = false
         }
         
          this._subscriptionPlanService
              .upgradeSubscription(newplan, false, execute, stripe)
              .subscribe((res) => {
                  if (res.success) {
                      Sucess = true;
                      this.subscriptionChangeNotes = res.data.note;
                      this.currentSubscriptionCharges =
                          res.data.currentSubscriptionCharges;
                      this.newSubscriptionCharges =
                          res.data.newSubscriptionCharges;
                     
                          if (execute == 'true') {
                          this.upgradeSucessMessage =
                              'You have sucessfully updated your plan.';
                              this.getCurrentPlanDetails() 
                              this.getInvoices()
                      }
                  } else {
                      this.ErrorMessage =
                          'An error occured during the operation, please try again.';
                  }
              });
      } catch {
      } finally {
      }
  }
  

}
