import { GlobalService } from 'src/app/_services/global.service';
import { Vendor } from './../_models/vendor';
import { Department } from 'src/app/_models/department';
import { DepartmentService } from './department.service';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, single, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { CategoryService } from './category.service';
import { ManufacturersService } from './manufacturers.service';
import { VendorsService } from './vendors.service';
import { GroupBy, GroupByIdNumber, ReportsModel, transferTypeInterface } from '../_models/reports/reports';
import autoTable, { Cell } from 'jspdf-autotable';
import { UsStates } from '../_data/us-states';
import { Router } from '@angular/router';
import { SystemConfigurations, TenderScreenConfig } from '../_models/configuration-widgets/business-config';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { IndustryType } from '../_models/industry';
import { formatDate, formatNumber } from '@angular/common';
import * as FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConnMessageService } from './conn-message.service';
import { AppMainComponent } from '../app.main.component';
import { MenuItem } from 'primeng/api';
import { FilterServiceService } from './filter-service.service';
import { UserService } from './user.service';
import { PermissionCheckerService } from './permission-checker.service';

@Injectable({
  providedIn: 'root'
})
export class AppBaseDataService {
  getScreenWidth: any;
  getScreenHeight: any;

  whichRoute = 'any'
  isAdmin:boolean = false;
  isSetupGuide:boolean = false;
  appLeftMenu: MenuItem[]
  appIndexedDbName = 'app-indexed-db'
  systemConfigs: SystemConfigurations = null
  usaStates = UsStates
  orderTypes = [{ Type: "Online", id: 1 }, { Type: "Offline", id: 2 }, { Type: "All", id: 3 }];
  productSellType = [{ Type: "Sell Online", id: 1 }, { Type: "Sell In-Store", id: 2 },{ Type: "All", id: 3 }];
  productSellTypeFilter = [{ Type: "All", id: 3, Value: 'All' }, { Type: "In-Store", id: 2, Value: 'Offline' }, { Type: "Online", id: 1, Value: 'Online' }];
  tenderType = [
    { id: 1, label: 'Cash', value: 'Cash' },
    { id: 2, label: 'Credit Card', value: 'CreditCard' },
    { id: 3, label: 'Zelle', value: 'Zelle' },
    { id: 4, label: 'Venomo', value: 'Venomo' },
    { id: 5, label: 'Paypal', value: 'Paypal' },
  ]
  selectedTenderType = { id: 1, label: 'Cash', value: 'Cash' }
  isDownloadPosApp = false
  locations: any = null
  actLocations: any = null
  departments: Department[] = null
  actDepartments: any = null
  categories: any = null
  actCategories: any = null
  vendors: Vendor[] = null
  actVendors: any = null
  manufacturers: any = null
  actManufacturers: any = null
  uom: any = null
  uomCalc: any = []
  unitClasses: any = null
  brands: any = null
  registers: any = null
  selectedRegister: any = null
  cashiers: any = null
  selectedCashier: any = null // showCashier = false

  singleDate: any = null
  dateRange: any = null
  searchText = ''
  productsearchText = ''
  OverViewproductsearchText = ''
  tempSearchText = ''

  selectedProductSellType = { Type: "Sell Online", id: 1 }
  selectedProductSellTypeFilter = { Type: "All", id: 3, Value: 'All' }

  isUserOnDashboard:boolean = false;

  selectedLocation: any = null
  selectedExtra1Location: any = null
  selectedDepartment: any = null
  selectedCategory: any = null
  selectedVendor: any = null
  selectedManufacturer: any = null
  selectedUom: any = null
  selectedUnitClasses: any = null
  selectedBrands: any = null
  selectedGroup: any = null
  selectedChannel: any = null
  selectedTranferType: any = null
  selectedTranferStatus: any = null
  selectedStatus: any = null
  selectedInventoryAdjustment: any = null

  selectedTransactionType: any = ''
  selectedInvoiceStatus: any = ''
  selectedInvoiceType: any = ''

  singleDateForReconcile: any = null


  rightsGroup: any = []
  rightsGroupRoles: any = []

  roles: any = []
  rights: any = []

  groupby: Array<GroupBy> = [
    {
      id: "Department",
      title: "Department",
    },
    {
      id: "Location",
      title: "Store",
    },
    {
      id: "Date",
      title: "Date",
    }
  ];

  channel: Array<GroupByIdNumber> = [
    {
      id: -1,
      title: "All",
    },
    {
      id: 0,
      title: "Automatically",
    },
    {
      id: 1,
      title: "QR Code",
    },
    {
      id: 2,
      title: "Manual Entry",
    }
  ];

  transferType: Array<transferTypeInterface> = [
    {
      id: 1,
      title: "All",
      value: "All"
    },
    {
      id: 2,
      title: "Transfer-Out",
      value: "TransferOut"
    },
    {
      id: 3,
      title: "Transfer-In",
      value: "TransferIn"
    }
  ]

  transferStatus: Array<transferTypeInterface> = [
    {
      id: 1,
      title: "All",
      value: "All"
    },
    {
      id: 2,
      title: "Draft",
      value: "Draft"
    },
    {
      id: 3,
      title: "Cancelled",
      value: "Cancelled"
    },
    {
      id: 4,
      title: "Posted",
      value: "Posted"
    },
    {
      id: 5,
      title: "Transferred",
      value: "Transferred"
    },
    {
      id: 6,
      title: "Completed",
      value: "Completed"
    },
    {
      id: 7,
      title: "Pending",
      value: "Pending"
    },
    {
      id: 8,
      title: "Received",
      value: "Received"
    },
    {
      id: 9,
      title: "Rejected",
      value: "Rejected"
    },
    {
      id: 10,
      title: "Closed",
      value: "Closed"
    },
    {
      id: 11,
      title: "InDraft",
      value: "InDraft"
    }
  ]

  inventoryAdjustmentTypes = [
    { name: "All", title: "All" },
    { name: "StockTransferIn", title: "Transfer- In" },
    { name: "StockTransferOut", title: "Transfer Out" },
    { name: "DirectPurchase", title: "Purchase" },
    { name: "InventoryCount", title: "Inventory Count" },
    { name: "Damage", title: "Damage" },
    { name: "ReturnToSender", title: "Return to Sender" },
    { name: "Lost", title: "Lost" },
    { name: "Expired", title: "Expired" },
    { name: "AutoAdjustment", title: "Auto Adjustment" }
  ]

  private groupby1 = new BehaviorSubject<GroupBy[]>([{ id: '', title: 'None' }]);
  public groupby$ = this.groupby1.asObservable();

  private categories1 = new BehaviorSubject<any>(null);
  public categories$ = this.categories1.asObservable();


  private departmentsBS = new BehaviorSubject<any>(null);
  public departmentsBS$ = this.departmentsBS.asObservable();

  private manufacturer1 = new BehaviorSubject<any>(null);
  public manufacturer$ = this.manufacturer1.asObservable();

  private locationsBS = new BehaviorSubject<any>(null);
  public locationsBS$ = this.locationsBS.asObservable();

  private vendorsBS = new BehaviorSubject<any>(null);
  public vendorsBS$ = this.vendorsBS.asObservable();

  reports: Array<ReportsModel> = [
    {
      reportId: 1,
      componentName: 'Daily Sales Report',
      paraReportName: 'daily-sales-report',
      reportURL: environment.InventoryBaseURL + environment.Report,
      colheader: [['Date', 'Location', 'Invoices', 'Sold Items', 'Return Items', 'Void Items'
        , 'Subtotal', 'Discount', 'Net Sale', 'Sales Tax', 'Shipping', 'Shipping Tax', 'Other Charges','Tip Amount', 'Grand Total']],
      colflds: ['date', 'locationName', 'totalInvoices', 'totalSaleItems', 'totalReturnItems', 'totalVoidItems', 'subTotal', 'discount', 'netSale', 'salesTax', 'shippingAmount', 'shippingTax', 'otherChargesAmount', 'tipAmount','grandTotal'],
      summaryHeader: [['Date', 'Location', 'Invoices', 'Sold Items', 'Return Items', 'Void Items', 'Subtotal ($)', 'Discount ($)', 'Net Sale', 'Sales Tax ($)', 'Shipping ($)', 'Shipping Tax ($)', 'Other Charges', 'Tip Amount','Grand Total ($)']],
      summaryflds: ['totalInvoices', 'totalSaleItems', 'totalReturnItems', 'totalVoidItems', 'subTotal', 'discount', 'netSales', 'salesTax', 'shippingAmount', 'shippingTax', 'otherChargesAmount', 'tipAmount','grandTotal'],
      reportTopFilterBar: {
        showSearchText: false,
        showLocationsDropdown: false,
        showLocationsMultiselect: true,
        showSingleDate: true,
        showDateRange: false,
        searchTextLabel: 'Search Daily Sales Report'
      },
      reportsFilterOption: {
        showSearchText: true,
        showSearchButton: true,
        showLocationsDropdown: false,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Search Daily Sales Report'
      },
    },
    {
      reportId: 2,
      componentName: 'Hourly Sales Report',
      paraReportName: 'hourly-sales-report',
      reportURL: environment.InventoryBaseURL + environment.Report,
      colheader: [['Location', 'Hours', '# Invoices', 'Items', 'Return Items', 'Void Items', 'Cost of Goods', 'Net Sale', 'Sales Tax', 'Total Sales']],
      colflds: ['locationName', 'hour', 'invoiceCount', 'salesItemCount', 'returnItemCount', 'voidItemCount', 'totalCost', 'totalSale', 'salesTax', 'grandTotal'],
      summaryHeader: [['Location', 'Hours', '# Invoices', 'Items', 'Return Items', 'Void Items', 'Cost of Goods ($)', 'Net Sale ($)', 'Sales Tax ($)', 'Total Sales ($)']],
      summaryflds: ['invoiceCount', 'salesItemCount', 'returnItemCount', 'voidItemCount', 'totalCost', 'totalSale', 'salesTax', 'grandTotal'],
      reportTopFilterBar: {
        showSearchText: false,
        showLocationsDropdown: false,
        showLocationsMultiselect: true,
        showSingleDate: true,
        showDateRange: false,
        searchTextLabel: 'Search Daily Sales Report'
      },
      reportsFilterOption: {
        showSearchText: false,
        showSearchButton: true,
        showLocationsDropdown: false,
        showLocationsMultiselect: true,
        showSingleDate: true,
        showDateRange: false,
        searchTextLabel: 'Search Daily Sales Report'
      },
    },
    {
      reportId: 3,
      componentName: 'Credit Card Sales Report',
      paraReportName: 'credit-card-sales-report',
      reportURL: environment.InventoryBaseURL + environment.Report,
      colheader: [['Name', 'Sale', 'Fees', 'Return', 'Voids', 'Type', 'Last 4', 'Approval', 'Invoice', 'Ref #', 'Entry Method', 'Station', 'Date', 'Time', 'Clerk', 'Tip Amount','Charged']],
      colflds: ['customerName', 'sales', 'feesTotal', 'returns', 'voids', 'type', 'last4', 'approval', 'invoiceNumber', 'refNumber', 'entryMethod', 'station', 'date', 'time', 'cashier', 'tipAmount', 'invoiceTotal'],
      reportTopFilterBar: {
        showSearchText: false,
        // showLocationsDropdown: true,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Search Daily Sales Report'
      },
      reportsFilterOption: {
        showSearchText: false,
        // showLocationsDropdown: true,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
        showSearchButton: true,
        searchTextLabel: 'Search Daily Sales Report'
      },
    },
    {
      reportId: 4,
      componentName: 'Credit Card Summary Report',
      paraReportName: 'credit-card-summary-report',
      reportURL: environment.InventoryBaseURL + environment.Report,
      colheader: [['Location', 'Type', 'Transaction Count', 'CC Total', 'Return', 'Void Total','Fee Total']],
      colflds: ['locationName', 'type', 'invoiceCount', 'creditCardTotal', 'returnsTotal', 'voidsTotal','feesTotal'],
      summaryHeader: [['Location', 'Type', 'Invoice Count', 'CC Total ($)', 'Return ($)', 'Void Total ($)','Fee Total ($)']],
      summaryflds: ['invoiceCount', 'creditCardTotal', 'returnsTotal', 'voidsTotal','feesTotal'],
      reportTopFilterBar: {
        showSearchText: false,
        // showLocationsDropdown: true,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Search Daily Sales Report'
      },
      reportsFilterOption: {
        showSearchText: false,
        // showLocationsDropdown: true,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Search Daily Sales Report'
      },
    },
    {
      reportId: 5,
      componentName: 'Tender Report',
      paraReportName: 'tender-report',
      reportURL: environment.InventoryBaseURL + environment.Report,
      colheader: [['Location Name', 'Payment Method', 'Invoice Count', 'Payment Method Count', 'Total Sales']],
      colflds: ['locationName', 'paymentMethod', 'invoiceCount', 'paymentMethodCount', 'totalSales'],
      summaryHeader: [['Location Name', 'Payment Method', 'Invoice Count', 'Payment Method Count', 'Total Sales ($)']],
      summaryflds: ['invoiceCount', 'paymentMethodCount', 'totalSales'],
      reportTopFilterBar: {
        showSearchText: false,
        // showLocationsDropdown: true,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Search Daily Sales Report'
      },
      reportsFilterOption: {
        showSearchText: false,
        // showLocationsDropdown: true,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Search Daily Sales Report'
      },
    },
    {
      reportId: 6,
      componentName: 'Return Report',
      paraReportName: 'return-report',
      reportURL: environment.InventoryBaseURL + environment.Report,
      colheader: [['Location', 'Cashier Name', '# Return Invoices', 'Cash', 'Credit Card', '# Items Return', 'Tax', 'Total Value']],
      colflds: ['locationName', 'userName', 'invoiceCount', 'cash', 'creditCard', 'itemsCount', 'tax', 'grandTotal'],
      summaryHeader: [['Location', 'Cashier Name', '# Return Invoices', 'Cash ($)', 'Credit Card ($)', '# Items Return', 'Tax ($)', 'Total Value ($)']],
      summaryflds: ['invoiceCount', 'cash', 'creditCard', 'itemsCount', 'tax', 'grandTotal'],
      reportTopFilterBar: {
        showSearchText: false,
        showLocationsDropdown: false,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Search Daily Sales Report'
      },
      reportsFilterOption: {
        showSearchText: false,
        showLocationsDropdown: false,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Search Daily Sales Report'
      },
    },
    {
      reportId: 7,
      componentName: 'Department Sales Report',
      paraReportName: 'department-sales-report',
      reportURL: environment.InventoryBaseURL + environment.Report,
      colheader: [['Group Value', 'Total Sales', 'Invoice Count', 'Total Profit']],
      colflds: ['groupValue', 'totalSales', 'invoiceCount', 'totalProfit'],
      summaryHeader: [['Group Value', 'Total Sales ($)', 'Invoice Count', 'Total Profit ($)']],
      summaryflds: ['totalSales', 'invoiceCount', 'totalProfit'],
      columns: [{
        field: 'groupValue',
        header: 'Group Value',
        display: 'table-cell',
      },
      {
        field: 'totalSales',
        header: 'Total Sales',
        display: 'table-cell',
      },
      {
        field: 'invoiceCount',
        header: 'Invoice Count',
        display: 'table-cell',
      },
      {
        field: 'totalProfit',
        header: 'Total Profit',
        display: 'table-cell',
      }
      ],
      groupBy: [
        {
          id: "Department",
          title: "Department",
        },
        {
          id: "Location",
          title: "Store",
        },
        {
          id: "Date",
          title: "Date",
        }
      ],
      reportTopFilterBar: {
        showGroupByDropdown: true,
        showDepartmentDropdown: true,
        showSearchText: false,
        // showLocationsDropdown: true,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Search Daily Sales Report'
      },
      reportsFilterOption: {
        showGroupByDropdown: true,
        showDepartmentDropdown: true,
        showSearchText: false,
        // showLocationsDropdown: true,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Search Daily Sales Report'
      },
    },
    {
      reportId: 7,
      componentName: 'Daily Sales Report',
      paraReportName: 'daily-sales-report',
      reportURL: environment.InventoryBaseURL + environment.Report,
      colheader: [['Group Value', 'Total Sales', 'Invoice Count', 'Total Profit']],
      colflds: ['groupValue', 'totalSales', 'invoiceCount', 'totalProfit'],
      summaryHeader: [['Total Sales', 'Invoice Count', 'Total Profit']],
      summaryflds: ['totalSales', 'invoiceCount', 'totalProfit'],
      columns: [{
        field: 'groupValue',
        header: 'Group Value',
        display: 'table-cell',
      },
      {
        field: 'totalSales',
        header: 'Total Sales',
        display: 'table-cell',
      },
      {
        field: 'invoiceCount',
        header: 'Invoice Count',
        display: 'table-cell',
      },
      {
        field: 'totalProfit',
        header: 'Total Profit',
        display: 'table-cell',
      }
      ],
      reportTopFilterBar: {
        showGroupByDropdown: false,
        showDepartmentDropdown: false,
        showSearchText: false,
        // showLocationsDropdown: true,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Search Daily Sales Report'
      },
      reportsFilterOption: {
        showGroupByDropdown: false,
        showDepartmentDropdown: false,
        showSearchText: false,
        // showLocationsDropdown: true,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Search Daily Sales Report'
      },
    },
    {
      reportId: 8,
      componentName: 'Sales Tax Report',
      paraReportName: 'sales-tax-report',
      reportURL: environment.InventoryBaseURL + environment.Report,
      colheader: [['Date', 'Location', 'Tax Rate', 'Taxable Sales', 'Non-Taxable Sales', 'Tax Amount']],
      colflds: ['groupValue', 'locationName', 'taxRete', 'taxableSales', 'nonTaxableSales', 'taxAmount'],
      summaryHeader: [['Date', 'Location', 'Tax Rate', 'Taxable Sales', 'Non-Taxable Sales', 'Tax Amount']],
      summaryflds: ['taxableSales', 'nonTaxableSales', 'taxAmount'],
      groupBy: [
        {
          id: "Date",
          title: "Date",
        },
        {
          id: "Location",
          title: "Store",
        },
        {
          id: "Year",
          title: "Year",
        }
      ],
      reportTopFilterBar: {
        showGroupByDropdown: false,
        showSearchText: false,
        // showLocationsDropdown: true,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Search Daily Sales Report'
      },
      reportsFilterOption: {
        showGroupByDropdown: false,
        showSearchText: false,
        // showLocationsDropdown: true,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Search Daily Sales Report'
      },
    },
    {
      reportId: 10,
      componentName: 'Daily Money Report',
      paraReportName: 'daily-money-report',
      reportURL: environment.InventoryBaseURL + environment.Report,
      colheader: [[]],
      colflds: [],
      summaryHeader: [[]],
      summaryflds: [],
      reportTopFilterBar: {
        showExportOption: true,
        showSearchText: false,
        showLocationsDropdown: false,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Search Daily Money Report'
      },
      reportsFilterOption: {
        showSearchText: false,
        showSearchButton: true,
        showLocationsDropdown: false,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Search Daily Money Report'
      },
    },
    {
      reportId: 11,
      componentName: 'Tip Summary Report',
      paraReportName: 'tip-summary-report',
      reportURL: environment.InventoryBaseURL + environment.Report,
      colheader: [['Date','Location','Register','Cashier','Total Invoices','Tip Collected','Total Sale']],
      colflds: ['date' ,'locationName','registerId','cashierId','totalInvoices','tipCollected','totalSale'], 
      summaryHeader: [['Tip Collected','Total Sale']],
      summaryflds: ['tipCollected','totalSale'],
      reportTopFilterBar: {
        showExportOption: false,
        showSearchText: false,
        showLocationsDropdown: false,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
          },
      reportsFilterOption: {
        showSearchText: false,
        showSearchButton: true,
        showLocationsDropdown: false,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
      },
    },
    {
      reportId: 12,
      componentName: 'Tip Detail Report',
      paraReportName: 'tip-detail-report',
      reportURL: environment.InventoryBaseURL + environment.Report,
      colheader: [['Date-Time','Location','Register','Invoices','Cashier','Tip Collected','Total Sale']],
      colflds: ['date' ,'locationName','registerId','invoiceNumber','cashierId','tipCollected','totalSale'], 
      summaryHeader: [['Tip Collected','Total Sale']],
      summaryflds: ['tipCollected','totalSale'],
      reportTopFilterBar: {
        showExportOption: false,
        showSearchText: false,
        showLocationsDropdown: false,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
          },
      reportsFilterOption: {
        showSearchText: false,
        showSearchButton: true,
        showLocationsDropdown: false,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
      },
    },
    {
      reportId: 13,
      componentName: 'Time Tracking Summary Report',
      paraReportName: 'time-tracking-summary-report',
      reportURL: environment.InventoryBaseURL + environment.Report,
      colheader: [['Date','Business ','Location ','Employee ','Clock-In ','Clock-Out ','Hours Worked','Overtime','Hours Remaining']],
      colflds: ['date','businessName','locationName','employeeName','clockIn','clockOut','fTotalHours','fOverTime','fShortHour'], 
      summaryHeader: [['Date','Business ','Location ','Employee ','Clock-In','Clock-Out','Hours Worked','Overtime','Hours Remaining']],
      summaryflds: ['fTotalHours','fOverTime','fShortHour'],
      reportTopFilterBar: {
        showExportOption: false,
        showSearchText: true,
        showLocationsDropdown: false,
        showLocationsMultiselect: false,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Enter Text To Search'
          },
      reportsFilterOption: {
        showSearchText: true,
        showSearchButton: true,
        showLocationsDropdown: true,
        showLocationsMultiselect: false,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Enter Text To Search'
      },
    },
    {
      reportId: 14,
      componentName: 'Time Tracking Detail Report',
      paraReportName: 'time-tracking-detail-report',
      reportURL: environment.InventoryBaseURL + environment.Report,
      colheader: [['Date','Employee Name','Employee Role','Employee Email','Clock-In ','Clock-Out ','Total Hours']],
      colflds: ['date' ,'employeeName','employeeRole','employeeEmail','clockIn','clockOut','totalHours'], 
      summaryHeader: [['Date','Employee Name','Employee Role','Employee Email','Clock-In','Clock-Out','Total Hours']],
      summaryflds: ['totalHours'],
      reportTopFilterBar: {
        showExportOption: false,
        showLocationsDropdown: false,
        showLocationsMultiselect: false,
        showSingleDate: false,
        showDateRange: true,
        showSearchText: true,
        searchTextLabel: 'Enter Text To Search'
          },
      reportsFilterOption: {
        showSearchText: true,
        showSearchButton: true,
        showLocationsDropdown: true,
        showLocationsMultiselect: false,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Enter Text To Search'
      },
    },
    {
      reportId: 15,
      componentName: 'Cash Discount Sales Report',
      paraReportName: 'cash-discount-sales-report',
      reportURL: environment.InventoryBaseURL + environment.Report,
      colheader: [['Date', 'Name', 'Invoice', 'Cash Discount', 'Sale', 'Returen', 'Voids', 'Station', 'Clerk']],
     colflds: ['date', 'customerName',   'invoiceNumber', 'cashDiscount', 'sales', 'returns', 'voids', 'locationName', 'cashier'],
      reportTopFilterBar: {
        showSearchText: false,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
        searchTextLabel: 'Search Cash Discount Sales Report'
      },
      reportsFilterOption: {
        showSearchText: false,
        // showLocationsDropdown: true,
        showLocationsMultiselect: true,
        showSingleDate: false,
        showDateRange: true,
        showSearchButton: true,
        searchTextLabel: 'Search Daily Sales Report'
      },
    },
    {
      // Customers
      paraReportName: 'customer',
      summaryHeader: [['First Name','Last Name','Phone','City','State','Zip','Reward Points','Reward Points Value','Status']],
      colflds: ['firstName' ,'lastName','phone','city','state','postalCode','rewardPoints','rewardPointsValue', 'isActive'],
    },
    // Coupon Management
    {
      //1) Sales Channel 
      paraReportName: 'Sales Channel',
      summaryHeader: [['#','Full Name','Status']],
      colflds: ['index','fullName', 'status'],
    },
    {
      //2) Coupon Code 
      paraReportName: 'Coupon Codes',
      summaryHeader: [['#','Coupon Name','Code','Discount Type', 'Value','Status']],
      colflds: ['index','discountName', 'couponCodes',  'discountValueType', 'discountValue', 'status'],
    },
    //3) Reports
    {
      //1) Coupon Code / Coupon Code Report
      paraReportName: 'Coupon Summary',
      colheader: [['Channel Name','Coupon Code', 'Discount Type','Discount Value', 'Subscription Plan', 'No # Coupon Used',  'Discount Amount']],
      colflds: [ 'channelName',  'couponCode', 'discountType', 'discountValue','subscriptionPlanList', 'couponUsed',  'discountAmount'],
      summaryHeader: [['Channel Name','Coupon Code', 'Discount Type','Discount Value', 'Subscription Plan', 'No # Coupon Used',  'Discount Amount']],
      summaryflds: [],
    },
    {
      //2) Coupon Code / Sales Channel Report
      paraReportName: 'Sales Channel Report',
      colheader: [['Channel Name','Coupon Code', 'Leads','Sign-up', 'Trial', 'Lite',  'Standard','Plus',  'Premium',  'Custom',  'Plan Cost',  'Hardware Cost','Add-ons','Total']],
      colflds: [ 'channelName',  'couponCode', 'leads', 'signup','trial', 'lite',  'standard','plus','premium','custom','planCost','hardwareCost','addons','total'],
      summaryHeader: [['Channel Name','Coupon Code', 'Leads','Sign-up', 'Trial', 'Lite',  'Standard','Plus',  'Premium',  'Custom',  'Plan Cost',  'Hardware Cost','Add-ons','Total']],
      summaryflds: [],
    }
  ]

  //-- Invoice related
  transactionTypes = [
    {
      id: 0,
      transactionType: "All Types",
      selected: true,
      enumType: 0
    },
    {
      id: 1,
      transactionType: "Sale",
      selected: true,
      enumType: 1
    },
    {
      id: 2,
      transactionType: "Return",
      selected: true,
      enumType: 2
    },
    {
      id: 3,
      transactionType: "Void",
      selected: true,
      enumType: 3
    },
  ]

  invoiceStatus = [
    {
      id: 0,
      title: "All Types"
    },
    {
      id: 2,
      title: "Completed"
    },
    {
      id: 1,
      title: "Draft"
    },
    {
      id: 3,
      title: "Hold"
    },
    {
      id: 4,
      title: "Processing"
    },
    {
      id: 5,
      title: "Pending Payment"
    },
    {
      id: 6,
      title: "Cancelled"
    },
    {
      id: 7,
      title: "Refunded"
    },
    {
      id: 8,
      title: "Failed"
    }
  ]

  invoiceType = [
    {
      id: 0,
      title: "All Types",
    },
    {
      id: 1,
      title: "Sale",
    },
    {
      id: 2,
      title: "Refund",
    },
    {
      id: 3,
      title: "Void",
    },
  ]

  public static postedType = [
    {
      id: 0,
      title: "All Types",
    },
    {
      id: 1,
      title: "Draft",
    },
    {
      id: 2,
      title: "Posted",
    },
  ]

  WeightUOM = [
    { id: 0, title: 'lb' },
    { id: 1, title: 'g' },
    { id: 2, title: 'kg' },
    { id: 3, title: 'oz' },
    { id: 4, title: 'mg' },
  ]

  DimensionUOM = [
    { id: 0, title: 'm' },
    { id: 1, title: 'cm' },
    { id: 2, title: 'mm' },
    { id: 3, title: 'in' },
    { id: 4, title: 'yd' },
  ]

  businessTypes = [
    {
      id: 3,
      title: "Sole Proprietorship",
      selected: false,
      desc: 'I own an unincorporated business by myself'
    },
    {
      id: 2,
      title: "Partnership",
      selected: false,
      desc: 'My business is owned by a two or more people'
    },
    {
      id: 1,
      title: "Corporation",
      selected: true,
      desc: 'My business has different stakeholders'
    },
  ]

  industryTypes: IndustryType[] = [];

  currencies = [
    {
      id: 1,
      title: "US $"
    },
    {
      id: 2,
      title: "Canadian $"
    },
  ]

  zipcodeCityStateObj = {
    zipcode: '',
    city: '',
    stateObj: null
  }

  _globals = GlobalService
  timeTrackingConfigration: any;

  constructor(
    private _bs: BaseService,
    private _catService: CategoryService,
    private _vendorService: VendorsService,
    private _manufacturers: ManufacturersService,
    private _departmentService: DepartmentService,
    private _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
    private _router: Router,
    private _filterService: FilterServiceService,
    private _permissionsCheckerService:PermissionCheckerService,
  ) {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }

  spinnerTimeoutInSeconds = 1 * 60 //-- time is in secs
  spinnerTimeout: any = ''
  showSpinner(timeout: any = this.spinnerTimeoutInSeconds) {
    timeout *= 1000
    clearTimeout(this.spinnerTimeout)
    this._spinner.show();
    this.spinnerTimeout = setTimeout(() => {
      // this._connMessageService.showToastMessage(`Action is taking too long to complete, please check with administrator!`, 'error')
      this.spinnerTimeout.
        this._spinner.hide();
      clearTimeout(this.spinnerTimeout)
    }, timeout);
  }

  hideSpinner() {
    this._spinner.hide()
    clearTimeout(this.spinnerTimeout)
  }

  showErrorAndQuit(msg = '') {
    this._spinner.hide() // on the safe side
    this._connMessageService.showToastMessage(`Fatel error: ${msg}, quitting!`, 'error')
    this._router.navigate([""])
  }

  appCookedData(needFilteredData: boolean = true) {
    if (needFilteredData) {
      this.actCategories = this.categories.filter((f) => f.isActive)
      this.actDepartments = this.departments.filter((f) => f.isActive)
      this.actManufacturers = this.manufacturers.filter((f) => f.isActive)
      this.actVendors = this.vendors.filter((f) => f.isActive)
      this.actLocations = this.locations.filter(f => !f.isDeleted && f.active)

      const obj = {
        locations: this.actLocations,
        cats: this.actCategories,
        departments: this.actDepartments,
        manufacturers: this.actManufacturers,
        vendors: this.actVendors
      }
      return obj
    } else {
      const obj = {
        locations: this.locations,
        cats: this.categories,
        departments: this.departments,
        manufacturers: this.manufacturers,
        vendors: this.vendors
      }
      return obj
    }
  }

  resetSelectVariables() {
    this.searchText = ''
    this.selectedLocation = null
    this.selectedDepartment = null
    this.selectedCategory = null
    this.selectedVendor = null
    this.selectedManufacturer = null
    this.selectedUom = null
    this.selectedUnitClasses = null
    this.selectedBrands = null
    this.selectedProductSellType = { Type: "Sell In-Store", id: 2 }
    //this.selectedProductSellTypeFilter = { Type: "All", id: 3, Value: 'All' }
    this.selectedTransactionType = ''
    this.selectedInvoiceStatus = ''
    this.selectedInvoiceType = ''
  }

  setAppBaseData() {
    this.resetSelectVariables()
  }

  phoneValidator(): ValidatorFn {
    let value = ''
    return (control: AbstractControl): ValidationErrors | null => {
      try {
        value = control.value.replace(/\D/g, '')
      } catch { }
      return value.length == 10 ? null : { phoneValError: true }
    }
  }

  resetFilters() {

    try {

      if (window.location.href.includes('/handheld')) {
        // console.log("pagechanged");
        eval('invokeCSharpAction("pagereloaded")')
      }


    }
    catch
    {

    }

    this.searchText = ''
    this.selectedLocation = null
    this.selectedExtra1Location = null
    this._filterService.ProductSearchTextInput = ''
    this._filterService.selectedProductSellTypeFilter = []
    this._filterService.RestFiltersandPages();
    this.initDates()
  }

  loadInit() {

    this.getIndustryTypes().subscribe(x => {
      this.industryTypes = x
      this.industryTypes.map(x => {
        x.selected = false
        x.description = ''
      })
    });
    this.getRoles()
    this.getRightsByRightsGroup() // to b removed later

    this.getBusinessConfig().subscribe();
    return this._bs.httpGetParams(environment.InventoryBaseURL + '/AllFilters').pipe(map(res => {
      if (res.success) {
        this.locations = res.data.locations
        this.departments = res.data.departments
        this.categories = res.data.categories
        this.categories.map(item => {
          try {
            item.departmentName = this.departments.filter(dep => dep.id == item.departmentId)[0].departmentName
          }
          catch {
            item.departmentName = ''
          }
        })
        // console.log('APP-BASE-DATA', res.data)
        this.vendors = res.data.vendors
        this.manufacturers = res.data.manufacturers
        this.uom = res.data.units
        this.unitClasses = res.data.unitClasses
        this.brands = res.data.brands
        this.registers = res.data.registers

        for (let i = 0; i < this.locations.length; i++)
          this.locations[i].selected = false

        for (let i = 0; i < this.departments.length; i++)
          this.departments[i].selected = false

        for (let i = 0; i < this.vendors.length; i++) {
          this.vendors[i].selected = false;
          this.vendors[i].stateName = this._globals.getUsaStateNameByAbb(this.vendors[i].state)

          this.initManufacturer(this.manufacturers)
          this.initCategories(this.categories);
          this.initDepartments(this.departments);
          this.initLocations(this.locations)
          this.initVendors(this.vendors);
        }

        this.actCategories = this.categories.filter((f) => f.isActive)
        this.actDepartments = this.departments.filter((f) => f.isActive)
        this.actManufacturers = this.manufacturers.filter((f) => f.isActive)
        this.actVendors = this.vendors.filter((f) => f.isActive)
        this.actLocations = res.data.locations.filter(f => !f.isDeleted && f.active)
        this.initDates()
        this.initDepartments(this.departments)
        //console.log('APP-BASE-DATA', res.data)
        return res
      }
    }))

  }
  // ~~~
  getRightsByRightsGroup(platform: string = 'All') {
    // let rightsGroupsRoles: any = []
    this.getRightGroups_1().subscribe(res => {
      if (res.success) {
        let rightGroups = res.data.rightsGroup
        this.rightsGroup = res.data.rightsGroup
        let rights: any = []
        for (let i = 0; i < rightGroups.length; i++) {
          rightGroups[i].children = []
          rightGroups[i].label = rightGroups[i].name
        }
        // //console.log('<++RIGHT-GROUPS 1 ++>', rightGroups)
        this.getRights_1(platform).subscribe(res => {
          if (res.success) {
            let rts = res.data.right
            for (let i = 0; i < rts.length; i++) {
              rts[i].label = rts[i].name
              rts[i].expanded = true
              rts[i].isParent = true
            }
            this.rights = rts
            // //console.log('<++RIGHTS++>', rts)
            for (let i = 0; i < rightGroups.length; i++) {
              let r1 = rts.filter(f => f.groupId == rightGroups[i].rightsGroupId && f.parentId == 0)
              for (let j = 0; j < r1.length; j++) {
                const r2 = rts.filter(f => f.parentId == r1[j].rightId)
                if (r2.length) {
                  r1[j].children = r2
                  rts[i].isParent = false
                }

              }
              rightGroups[i].children = JSON.parse(JSON.stringify(r1))
            }
            // //console.log('<++RIGHT-GROUPS 2 ++>', rightGroups)
            this.rightsGroupRoles = rightGroups
          }
        }) //-- this.getRights_1
      }
    }) //-- this.getRightGroups_1
  }

  getIndustryTypes() {
    let url = environment.AuthenticationURL + environment.GetIndustries;
    return this._bs.httpGet(url).pipe(map(res => {
      if (res.success) {
        return res.data;
      }
    }))

  }

  getRightGroups() {
    this.getRightGroups_1().subscribe(res => {
      if (res.success) {
        this.rightsGroup = res.data
        for (let i = 0; i < this.rightsGroup.length; i++)
          this.rightsGroup[i].selected = false
      }
    })
  }

  getRoles() {
    this.getRoles_1().subscribe(res => {
      if (res.success) {
        this.roles = res.data.role
        for (let i = 0; i < this.roles.length; i++)
          this.roles[i].selected = false
      }
    })
  }

  getRightGroups_1() {
    let url = environment.BusinessBaseURL + environment.GetRightsGroup;
    return this._bs.httpGet(url).pipe(map(res => {
      return res;
    }))
  }

  getRoles_1() {
    let url = environment.BusinessBaseURL + environment.GetRoles;
    return this._bs.httpGet(url).pipe(map(res => {
      return res;
    }))
  }

  getRights(platform: string = 'All') {
    this.getRights_1(platform).subscribe(res => {
      if (res.success) {
        this.rights = res.data
      }
    })
  }

  getRights_1(platform: string = 'All') {
    let url = environment.BusinessBaseURL + environment.GetRights;
    return this._bs.httpPost(url, JSON.stringify({
      "plateform": platform,
    })).pipe(map(response => {
      return response;
    }))
  }

  getUomNameById(id) {
    try {
      const x = this.uom.find(f => f.id == id).unitName
      if (x) return x
      else return ''
    }
    catch {
      return ''
    }
  }

  setGroupByOptions(options): void {
    this.groupby1.next(options);
  }

  initCategories(e: any) {
    this.categories1.next(e);
  }

  initDepartments(e: any) {
    this.departmentsBS.next(e);
  }

  initActDepartments() {
    try {
      this.actDepartments = this.departments.filter((f) => f.isActive)
      return this.actDepartments
    } catch {
      this.actDepartments = []
    }
  }

  initActCategories() {
    try {
      this.actCategories = this.categories.filter((f) => f.isActive)
      return this.actCategories
    } catch {
      this.actCategories = []
    }
  }

  initActManufacturers() {
    try {
      this.actManufacturers = this.manufacturers.filter((f) => f.isActive)
      return this.actManufacturers
    } catch {
      this.actManufacturers = []
    }
  }

  resetAllBehaviorSubjects() {
    this.initDepartments(this.initActDepartments())
    this.initCategories(this.initActCategories())
    this.initManufacturer(this.initActManufacturers())
    this.initVendors(this.initActVendors())
    this.initLocations(this.initActLocations())
  }

  initActVendors() {
    try {
      this.actVendors = this.vendors.filter((f) => f.isActive)
      return this.actVendors
    } catch {
      this.actVendors = []
    }
  }

  initActLocations() {
    try {
      this.actLocations = this.locations.filter(f => !f.isDeleted && f.active)
      return this.actLocations
    } catch {
      this.actLocations = []
    }
  }

  initManufacturer(e: any) {
    this.manufacturer1.next(e);
  }

  initLocations(e: any) {
    this.locationsBS.next(e);
  }

  initVendors(e: any) {
    this.vendorsBS.next(e);
  }

  initDateRange() {
    this.dateRange = []
    this.dateRange.push(new Date(new Date().setDate(new Date().getDate() - 7)))
    this.dateRange.push(new Date())
    return
  }

  initSingleDate() {
    this.singleDate = new Date()
  }

  initDates() {
    this.singleDate = new Date()
    this.dateRange = []
    this.dateRange.push(new Date(new Date().setDate(new Date().getDate() - 7)))
    this.dateRange.push(new Date())
  }

  getBusinessConfig() {
    let url = environment.BusinessBaseURL + environment.GetBusinessConfig;

    return this._bs.httpGet(url).pipe(map(res => {
      if (res.success) {
        this.systemConfigs = res.data
        //console.log('SYSTEM-CONFIGS', this.systemConfigs)
      }
      return res;
    }))
  }

  getAllDepartments() {
    let params = new HttpParams();
    params = params.append('pageNo', 0);
    params = params.append('pageSize', 1500);

    this._departmentService.getAllDepartments(params).subscribe(res => {
      if (res.success)
        this.departments = res.data

      for (let i = 0; i < this.departments.length; i++)
        this.departments[i].selected = false

      this.actDepartments = this.departments.filter((f) => f.isActive)
      this.initDepartments(this.actDepartments);
    })
  }


  getAllDepartmentsObs() {
    let params = new HttpParams();
    params = params.append('pageNo', 0);
    params = params.append('pageSize', 1500);

    return this._departmentService.getAllDepartments(params).pipe(map(res => {
      if (res.success)
        this.departments = res.data
      for (let i = 0; i < this.departments.length; i++)
        this.departments[i].selected = false

      this.actDepartments = this.departments.filter((f) => f.isActive)
      return res.data
    }))
  }

  getAllLocations(showSpinner: boolean = true) {

    let url = environment.BusinessBaseURL + environment.GetAllLocations
    return this._bs.httpGet(url, showSpinner).subscribe(res => {
      if (res.success) {
        this.locations = res.data
        this.initLocations(this.locations)
      }

      else this.locations = null
    })
  }

  getAllCategories() {

    let params = new HttpParams();
    params = params.append('pageNo', 0);
    params = params.append('pageSize', 1500);

    this._catService.getAllCategories(params).subscribe(res => {
      if (res.success) {
        this.categories = res.data
        this.categories.map(item => {
          try {
            item.departmentName = this.departments.filter(dep => dep.id == item.departmentId)[0].departmentName
          }
          catch {
            item.departmentName = ''
          }
        })
        this.initCategories(this.categories);
      }
      else
        this.categories = null
    })
  }

  getAllVendors() {
    let params = new HttpParams();
    params = params.append('pageNo', 0);
    params = params.append('pageSize', 1500);

    this._vendorService.getAllVendors(params).subscribe(res => {
      if (res.success) {
        this.vendors = res.data
        this.initVendors(this.vendors)
      }
      else
        this.vendors = null

    })
  }

  getAllManufacturers() {
    let params = new HttpParams();
    params = params.append('pageNo', 0);
    params = params.append('pageSize', 500);

    this._manufacturers.getAllManufacturers(params).subscribe(res => {
      if (res.success) {
        this.manufacturers = res.data
        this.initManufacturer(this.manufacturers)
      }
      else
        this.manufacturers = null
    })
  }

  getCityAndStateInfo(zipcode: string) {
    if (GlobalService.validateZipCode(zipcode)) {
      return this.getCityAndStateInfoUsingZipCode(parseInt(zipcode)).pipe(map(res => {
        return res
      }))
    }
  }

  getCityAndStateInfoUsingZipCode(zip: number) {
    let url = environment.BaseURL + environment.ZipInfoService + "/" + zip;

    return this._bs.httpGet(url).pipe(map(res => {
      return res;
    }))
  }

  getTenderLocations(model:any) {
    let url = environment.BusinessBaseURL + "GetLocationByBusiness?BusinessName="+model;
    return this._bs.httpGet(url).pipe(map(response =>{
      return response
    }));
  }
  getTenderConfiguration(model:any) {
    let url = environment.BusinessBaseURL + "TenderConfig?"+model;
    return this._bs.httpGet(url).pipe(map(response =>{
      return response
    }));
  }
  saveAdminTenderConfiguration(queryString:any,value: TenderScreenConfig) {
    let url = environment.BusinessBaseURL + "PTenderConfig?"+queryString;
    return this._bs.httpPost(url, value).pipe(map(response =>{
      return response
    }));
  }
  getTimeTrackingConfiguration() {
    let url = environment.BusinessBaseURL + "GetBTimeTracking";
    return this._bs.httpGet(url).pipe(map(response =>{
      return response
    }));
  }
  getTimeTrackingConfigurationWithFilter(filter:any) {
    let url = environment.BusinessBaseURL + "GetBTimeTracking?filtervalue=" + filter;
    return this._bs.httpGet(url).pipe(map(response =>{
      return response
    }));
  }

  getCustomerGroupConfiguration() {
    let url = environment.BusinessBaseURL + "CustomerGroup";
    return this._bs.httpGet(url).pipe(map(response =>{
      return response
    }));
  }

  //-----------------------------------------------------------
  //          VALIDATIONS
  //-----------------------------------------------------------
  validateZipcode(value: string) {
    // if (!value || value.replace(/\D/g, '').length !== 5) return
    return this.getCityAndStateInfo(value).pipe(map(res => {
      if (res.success && res.data.city != undefined) {
        if (res.data.city == 'Unknown') return false
        else return true
      }
      else { return false }
    }))
  }


  makeCsvCols(header: any, field: any) {
    let ret: any = []
    for (let i = 0; i < field.length; i++) {
      let xx = header[0][i]
      ret.push({ field: field[i], header: header[0][i] })
    }
    return ret
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  exportExcel(dataList: any, colheader: any, colfields: any, reportname: any, printsummary: boolean = false) {
    let data = this.refineDataForExport(dataList, colfields, printsummary);
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      xlsx.utils.sheet_add_aoa(worksheet, [colheader], { origin: "A1" });
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array"
      });
      this.saveAsExcelFile(excelBuffer, reportname);
    });
  }

  exportPdf(datalist: any, colheader: any, colfields: any, reportname: any, printsummary: boolean = false, singleDate: boolean = false,printDateLocaton?:boolean) {

    let data = this.refineDataForExport(datalist, colfields, printsummary, true);
    // //console.log(data);
    const doc = new jsPDF('l', 'pt', 'letter');

    // Check if the slecected location is an array. If not an array then convert the object into an array
    let printLocation: any = [];


    if (Array.isArray(this.selectedLocation) == false) {
      if (this.selectedLocation == null) {
        printLocation = [];
      } else {
        printLocation = [this.selectedLocation]
      }
    } else {
      printLocation = this.selectedLocation
    }



    if (Array.isArray(this.selectedLocation) == false) {
      if (this.selectedLocation == null) {
        printLocation = [];
      } else {
        printLocation = [this.selectedLocation]
      }
    } else {
      printLocation = this.selectedLocation
    }
    // if Print location and start and end date at top of file
    let isPrint = printDateLocaton === false ? false : true
   
    // Add file information to the top of the pdf
   if(isPrint === false ){
    doc.text([this._globals.userInfo.businessSetup[0].businessDisplayName, reportname], 396, 30, { align: "center", baseline: "bottom" });
    autoTable(doc, {
      startY: 60,
      head: colheader,
      body: data,
      didDrawPage: (dataArg) => {
        doc.setFontSize(10);
        doc.setTextColor(40);
      }
    })
  }else{
      if (singleDate) {
      doc.text("Date: " + formatDate(this.singleDate, 'MM/dd/yyyy', 'en-US'), 40, 30);
      doc.text("Location: " + printLocation.map(item => item.locationName).join(', '), 40, 80);
      doc.text([this._globals.userInfo.businessSetup[0].businessDisplayName, reportname], 396, 100, { align: "center", baseline: "bottom" });
    }
    else {
      doc.text(["Start Date: " + formatDate(this.dateRange[0], 'MM/dd/yyyy', 'en-US'),
      "End Date: " + formatDate(this.dateRange[1], 'MM/dd/yyyy', 'en-US')],
        40, 30);
      doc.text("Locations: " + printLocation.map(item => item.locationName).join(', '), 40, 80);
      doc.text([this._globals.userInfo.businessSetup[0].businessDisplayName, reportname], 396, 100, { align: "center", baseline: "bottom" });
    }
    autoTable(doc, {
      startY: 150,
      head: colheader,
      body: data,
      didDrawPage: (dataArg) => {
        doc.setFontSize(10);
        doc.setTextColor(40);
      }
    })
  }

    doc.save(reportname + ' (' +
      formatDate(new Date(), 'MM/dd/yyyy', 'en-US') + ')' + '.pdf')
  }
  exportPOPdf(datalist: any, colheader: any, colfields: any, obj?:any,status?:any) {
 
    let dataList = this.refineDataForExport(datalist, colfields);
     const doc = new jsPDF('l', 'pt', 'letter');
    let tableHeight; 
    let tablePages;  
     let pages;
     doc.setFont("helvetica", "bold");
     doc.setFontSize(14);
     let text
     if(status === true){
       text = 'PURCHASE ORDER'
     }else{
       text = 'Received'
     }
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth = doc.getTextWidth(text);
      const x = (pageWidth - textWidth) / 2;
      doc.text(text, x, 30);
     doc.setLineWidth(0.5);
     doc.line(x, 30 + 1, x + textWidth, 30 + 1);

    //   Business Info
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text('Business Name',40,45);
    doc.setLineWidth(0.5);
    const textWidth1 = doc.getTextWidth('Business Name');
    doc.line(40, 45 + 1, 40 + textWidth1, 45 + 1);
    doc.text([this._globals.userInfo.businessSetup[0].businessDisplayName],130,45);
    const textWidth2 = doc.getTextWidth(this._globals.userInfo.businessSetup[0].businessDisplayName);
    doc.line(130, 45 + 1, 130 + textWidth2, 45 + 1);
    doc.setFont("helvetica", "normal");
    doc.text('Address',40,60),
    doc.text(obj?.bAddress,130,60),
    doc.text('Contact',40,75),
    doc.text(obj?.bContact,130,75),
    doc.text('Location',40,90),
    doc.text(obj?.location,130,90),
    doc.text('Email',40,105),
    doc.text(obj?.bEmail,130,105),
    doc.text('PO#',40,120),
    doc.text(obj?.poNumber,130,120),
    doc.text('Created By',40,135),
    doc.text(obj?.requestedBy,130,135),
    doc.text('Created Date',40,150),
    doc.text(formatDate(new Date(obj?.createdDate), 'MM/dd/yyyy', 'en-US'),130,150),
    doc.text('Status',40,165),
    doc.text(obj?.poStatus,130,165),
  // Company Info
  doc.setFont("helvetica", "bold");
    doc.text('Vendor Company ',500,45);
    doc.setLineWidth(0.5);
    const textWidth3 = doc.getTextWidth('Vendor Company');
    doc.line(500, 45 + 1, 500 + textWidth3, 45 + 1);
    doc.text(obj?.company,600,45);
    const textWidth4 = doc.getTextWidth(obj?.company);
    doc.line(600, 45 + 1, 600 + textWidth4, 45 + 1);
    doc.setFont("helvetica", "normal");
    doc.text('Vender Name',500,60),
    doc.text(obj?.vendorName,600,60),
    doc.text('Address',500,75),
    doc.text(obj?.address,600,75),
    doc.text('Email',500,90),
    doc.text( obj?.email,600,90),
    doc.text('Contact',500,105),
    doc.text(this._globals.generatePhoneFormatUS(obj?.phone) || "",600,105),
    doc.text('Vender Account',500,120),
    doc.text(obj?.account,600,120),
    doc.text('Receiving Date',500,135),
    doc.text( formatDate(new Date(obj?.receiveDate), 'MM/dd/yyyy', 'en-US'),600,135),
    autoTable(doc, {
      startY: 180,
      tableWidth: 'auto',
      head: colheader,
      body: dataList,
      headStyles: {
        fillColor: [35, 220, 50], 
        fontStyle: 'bold',
      },
      styles:{
        lineWidth: 0.1, 
        lineColor: [158, 158, 158],
        // lineColor: [117, 117, 117],
        valign: 'middle',
        halign: 'center', 
        cellPadding: 4,
      },
      didDrawPage: (dataArg) => {
        doc.setFontSize(10);
        doc.setTextColor(40);
        tablePages = dataArg.pageCount
        tableHeight = dataArg.cursor.y
      
        const text = obj?.footerNoteText
        // const pageWidth1 = doc.internal.pageSize.getWidth();
        // // const textWidth1 = doc.getTextWidth(text);
        // const textWidth1 =  doc.getStringUnitWidth(text) * 10 / doc.internal.scaleFactor;;
        // const x = (pageWidth1 - textWidth1) / 2;
        // doc.setFont("helvetica", "normal");
        doc.text(text, 40, 590);
       

        //       let lines = doc.splitTextToSize(obj?.footerNoteText,710);  
  // let y = 580;
  // for (let i = 0; i < lines.length; i++) {
  //   doc.setFont("helvetica", "normal");
  //   doc.text(lines[i], 40, y);
  //     y += 10;
  //    }
        // doc.text("This document is system-generated and does not require a signature or stamp​", 40,580);
       },
  })

   if(tableHeight < 460){
   // Note 
   doc.setFontSize(10);
   doc.setFont("helvetica", "bold");
   doc.text('Notes:',45,tableHeight + 20),
   doc.setLineWidth(0.5);
   const textWidth1 = doc.getTextWidth('Notes:');
   doc.setDrawColor(0,0,0);
   doc.line(45,tableHeight + 21, 45 + textWidth1,tableHeight + 21);
   doc.setFont("helvetica", "normal");
   var lines = doc.splitTextToSize(obj?.note, 350);  
   var y = tableHeight + 35;
   for (var i = 0; i < lines.length; i++) {
    doc.setFillColor(242, 242, 242);
    doc.rect(40, y-12, 400, 20, 'F');  
    doc.text(lines[i], 45, y);
       y += 15;
   }

   
   //  Total Calculation
   doc.text('Sub Total',600,tableHeight + 20);
   doc.text("$ "+obj?.subTotal,700,tableHeight + 20);
   doc.text('Total Discount',600,tableHeight + 35),
   doc.text("$ "+obj?.discountTotal,700,tableHeight + 35),
   doc.text('Total Tax',600,tableHeight + 50),
   doc.text("$ "+obj?.taxTotal,700,tableHeight + 50),
   doc.text('Shipping',600,tableHeight + 65),
   doc.text( "$ "+obj?.shipping,700,tableHeight + 65),
   doc.setLineWidth(1.5);
   doc.line(590,tableHeight + 75, 750,tableHeight + 75);
   doc.text('Grand Total',600,tableHeight + 90),
   doc.text("$ "+obj?.grandTotal,700,tableHeight + 90)
   }else{
    doc.addPage()
      // Note 
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text('Notes',45,30),
    doc.setLineWidth(0.5);
    const textWidth1 = doc.getTextWidth('Notes:');
    doc.setDrawColor(0,0,0);
    doc.line(45,30, 45 + textWidth1,30);
    doc.setFont("helvetica", "normal");
    var lines = doc.splitTextToSize(obj?.note, 350);  
    var y:any = 50;
    for (var i = 0; i < lines.length; i++) {
      doc.setFillColor(242, 242, 242);
      doc.rect(40, y-12, 400, 20, 'F');
        doc.text(lines[i], 45, y);
        y += 15;
    }
    
    //  Total Calculation
    doc.text('Sub Total',600, 30);
    doc.text("$ "+obj?.subTotal,700, 30);
    doc.text('Total Discount',600,45),
    doc.text("$ "+obj?.discountTotal,700,45),
    doc.text('Total Tax',600,60),
    doc.text("$ "+obj?.taxTotal,700,60),
    doc.text('Shipping',600,75),
    doc.text( "$ "+obj?.shipping,700,75),
    doc.setLineWidth(1.5);
    doc.line(590,tableHeight + 90, 750,tableHeight + 90);
    doc.text('Grand Total',600,105),
    doc.text("$ "+obj?.grandTotal,700,105)
   }
    pages = doc.getNumberOfPages()
     if(pages !== tablePages){
          for (var i = 0; i <= pages; i++) {
            doc.setFontSize(10);
            doc.setFont("helvetica", "normal");
            doc.text(obj?.footerNoteText, 40, 590);
            // let lines = doc.splitTextToSize(obj?.footerNoteText,450);  
            // let y = 570;
            // for (let i = 0; i < lines.length; i++) {
            //   doc.setFont("helvetica", "normal");  
            //   doc.text(lines[i], 40, y);
            //     y += 15;
            //    }
  }
     }
    return doc;
  }

  parseForExport(pdata: any, cols: any) {
    let data = JSON.parse(JSON.stringify(pdata))

    try {
      for (let i = 0; i < data.length; i++) {
        let row = data[i]
        for (let j = 0; j < cols.length; j++) {
          if (!row.hasOwnProperty(cols[j].dataKey)) break;
          const col = cols[j]
          const r = row

          if (col.type == 'date')
            row[col.dataKey] = formatDate(new Date(row[col.dataKey]), 'MM/dd/yyyy, h:mm a', 'en-US')
          else if (col.type == 'currency')
            row[col.dataKey] = (row[col.dataKey]).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })
        }
      }
    }
    catch { }
    return data
  }

  refineDataForExport(dataList: any, colflds: any, printsummary: boolean = false, printPDF: boolean = false) {
    let data = []
    let summary = []
    // //console.log(dataList);
    // //console.log(colflds);
    /*
      Loop through the data from API and create a new array with only the columns we need to print
    */
    dataList.map(value => {
      let row = [];
      colflds.map(cols => {
        /*
          Format the date and hour variables to be printed
        */

        if (cols == 'date' || cols == 'lastEdited' || cols == 'lastPurchaseDate' || cols == 'lastPuchaseDate' || cols == 'invoiceDate') {
          row.push(value[cols] !== ""? formatDate(value[cols], 'MM/dd/yyyy', 'en-US'): '');
        } else if (cols == 'dateTime') {
          row.push(formatDate(value[cols], 'MM/dd/yyyy, h:mm a', 'en-US'));
        }
        else if(cols == 'clockIn'){
          row.push(value[cols] !== "0001-01-01T00:00:00"? formatDate(value[cols], 'h:mm a', 'en-US'): '-');
        }
        else if(cols == 'clockOut'){
          row.push(value[cols] !== "0001-01-01T00:00:00"? formatDate(value[cols], 'h:mm a', 'en-US'): '-');
        }
         else if (cols == 'hour') {
          var suffix = value[cols] <= 12 ? "AM" : "PM"
          row.push(((value[cols] + 11) % 12 + 1) + suffix);
        } else if (dataList[0][cols] instanceof Object || dataList[0][cols] instanceof Array) {
          row.push("");
        }
        else if (cols == 'discountAmount') {
          row.push('$'+value[cols]);
        } 
        else if (cols == 'currentPrice') {
          row.push('$'+value[cols]);
        } 
        else if (cols == 'totalPurchase') {
          row.push('$'+value[cols]);
        } 
        else if (cols == 'isActive') {
          row.push(value[cols] ? 'Active' : 'Inactive');
        }else if (cols == 'discountValue') {
         if(value['discountValueType'] == 0 ){
          row.push('$'+value[cols])
         }else if(value['discountValueType'] == 1 ){
          row.push(value[cols] + '%')
         }else{
          row.push('-');
         }
          // row.push(value[cols] === 0 ?  '-' : value[cols]);
        }
        else if (cols == 'phone') {
          row.push( this._globals.generatePhoneFormatUS(value[cols]));
        }
        else if (cols == 'status') {
          row.push(value[cols] === true ? 'Enable' : 'Disable');
          
        }
        else if (cols == 'customerName') {
            row.push(value[cols]);
        }
        else if (cols == 'rewardPointsValue'|| cols == 'planCost' || cols == 'hardwareCost' || cols == 'addons' || cols == 'purchasePrice' || cols == 'totalDiscountReceied' || cols == 'currentProductPrice' || cols == 'lastTotalPurchase' || cols == 'total') {
          row.push( '$'+value[cols].toFixed(2));
        }else if (cols == 'discountValueType' || cols == 'discountType') {
          if(value[cols] === 0){
            row.push('Flat')
          }else if(value[cols] === 1){
            row.push('Percentage')
          }else if(value[cols] === 2){
            row.push('Tracking Only')
          }
        }
        else if (printPDF) {
          if (cols == 'value' || cols == 'cash' || cols == 'creditCard' || cols == 'tax' || cols == 'grandTotal' ||
            cols == 'subTotal' || cols == 'discount' || cols == 'sales' || cols == 'shippingAmount' ||
            cols == 'shippingTax' || cols == 'salesTax' || cols == 'totalSales' || cols == 'totalCost' ||
            cols == 'totalSale' || cols == 'totalProfit' || cols == 'taxableSales' || cols == 'nonTaxableSales' ||
            cols == 'creditCardTotal' || cols == 'returnsTotal' || cols == 'voidsTotal' || cols == 'feesTotal' ||
            cols == 'taxAmount' || cols == 'otherChargesAmount' || cols == 'invoiceTotal' || cols == 'sellingPrice' ||
            cols == 'salesTotal' || cols == 'loyaltySales' || cols == 'valuePerLoyaltyCustomer' ||
            cols == 'discountReceived' || cols == 'pointsAcquiredDollarValue' || cols == 'purchase' ||
            cols == 'totalDiscount' || cols == 'totalReturned' || cols == 'netSales' || cols == 'netCost' ||
            cols == 'grossProfit' || cols == 'unitPrice' || cols == 'totalPrice' || cols == 'shippingCost') {
            row.push('$' + value[cols].toFixed(2));
          } else if (cols == 'percent' || cols == 'taxRete' || cols == 'grossmargin') {
            row.push(value[cols].toFixed(2) + '%');
          }
           else {
            if (cols == 'trackStock' || cols == 'sellOnline') {
                if (value[cols] == true) {
                    row.push("Yes");
                }
                else {
                    row.push("No");
                }
            }
            else {
                row.push(value[cols]);
            }
          }
        }

        else {

          row.push(value[cols]);
        }
      })
      data.push(row);
    })
    /*
      Code ends here
    */

    /*
      Following Code executes only if printsummary is true
      1. The code will map the data to a new array with only the columns we need to print
    */
    if (printsummary) {
      let row = []
      colflds.map(value => {
        summary = [];
        
        if (typeof dataList[0][value] == 'string' || value == 'hour' || value == 'taxRete' || value == 'percent' || value == 'valuePerLoyaltyCustomer' || value == 'grossmargin' || value == 'id' || value == 'status' || value == 'discountValue' || value == 'discountType' || value == 'couponCode' || value == 'channelName' ) {
        
          // if(value == 'fTotalHours' || value == 'fShortHour' || value == 'fOverTime' ){
          //   let timeArray =dataList.map((v)=>v[value])
          //   let totalMinutes = 0;
          //   for (const time of timeArray) {
          //     const [hours, minutes] = time.split(':').map(Number);
          //     totalMinutes += hours * 60 + minutes;
          //   }
          //   const totalHours = Math.floor(totalMinutes / 60);
          //   const remainingMinutes = totalMinutes % 60;
          //   row.push( `${totalHours}:${remainingMinutes.toString().padStart(2, '0')}`);
          //  }
          // this code run for transfer detail report changes
          if (typeof dataList[0][value] == 'string'
         || value == 'channelName' ||  
            value == 'status' ||
            value == 'transferNo' ||
            value == 'productName' ||
            value == 'skuCode' ||
            value == 'barcode' ||
            value == 'senderLocation' ||
            value == 'discountType' ||
            value == 'receiverLocation'
                        
            ) {
            summary.push("");
          }
        
          else {
            summary.push("*Total*");
          }
          if(value == 'fTotalHours' || value == 'fShortHour' || value == 'fOverTime' ){
          let timeArray =dataList.map((v)=>v[value])
          let totalMinutes = 0;
          for (const time of timeArray) {
            const [hours, minutes] = time.split(':').map(Number);
            totalMinutes += hours * 60 + minutes;
          }
          const totalHours = Math.floor(totalMinutes / 60);
          const remainingMinutes = totalMinutes % 60;
          row.push( `${totalHours}:${remainingMinutes.toString().padStart(2, '0')}`);
         }
        }
        else {
          // Number Type but not Sum like currency
        if(  value == 'couponUsed'   || value=='leads' || value=='signup' || value=='trial' || value=='lite' || value=='standard' || value=='plus' || value=='premium' || value=='custom'){
            summary = dataList.reduce((sum, item) => sum + item[value], 0);
          }
          // else if(value == 'fTotalHours' || value == 'fShortHour' || value == 'fOverTime' ){
          //    let timeArray =dataList.map((v)=>v[value])
          //    let totalMinutes = 0;
          //    for (const time of timeArray) {
          //      const [hours, minutes] = time.split(':').map(Number);
          //      totalMinutes += hours * 60 + minutes;
          //    }
          //    const totalHours = Math.floor(totalMinutes / 60);
          //    const remainingMinutes = totalMinutes % 60;
          //    row.push( `${totalHours}:${remainingMinutes.toString().padStart(2, '0')}`);
          //     // `${totalHours}:${remainingMinutes.toString().padStart(2, '0')}`;
          //   }
          else{
            summary = dataList.reduce((sum, item) => sum + item[value], 0).toFixed(2);
          }
        }
        if (value=='planCost'  || value=='hardwareCost' || value == 'value' || value == 'cash' || value == 'creditCard' || value == 'tax' || value == 'grandTotal' || value == 'subTotal' || value == 'discount' || value == 'sales' || value == 'shippingAmount' || value == 'shippingTax' || value == 'salesTax' || value == 'totalSales' || value == 'totalCost' || value == 'totalSale' || value == 'totalProfit' || value == 'taxableSales' || value == 'nonTaxableSales' || value == 'creditCardTotal' || value == 'returnsTotal' || value == 'voidsTotal' || value == 'feesTotal' || value == 'taxAmount' || value == 'otherChargesAmount' || value == 'invoiceTotal' || value == 'sellingPrice' || value == 'salesTotal' || value == 'loyaltySales' || value == 'discountReceived' || value == 'pointsAcquiredDollarValue' || value == 'totalDiscount' || value == 'totalReturned' || value == 'netSales' || value == 'netCost' || value == 'grossProfit' || value == 'unitPrice' || value == 'totalPrice' || value == 'shippingCost' || value == 'discountAmount' || value == 'addons' || value == 'total') {
          row.push('$' + summary);
        }
        // else if(value == 'percent' || value == 'taxRete'){
        //   row.push(summary + ' %');
        // }
        else {
          row.push(summary);
        }
      })
      data.push(row);
    }
    /*
      Code ends here
    */
    return data;
  }


  //--------- TRANSFERS
  transferStatusOut = [
    { id: 1, title: 'Draft' },
    { id: 2, title: 'Cancelled' },
    { id: 3, title: 'Posted' },
    { id: 4, title: 'Transferred' },
    { id: 5, title: 'Completed' },
    { id: 6, title: 'Rejected' },
  ]

  getTransfersOutStatus(data) {
    const ret = this.transferStatusOut.find(f => f.id == data).title
    return ret
  }

  getTransfersOutClass(data) {
    if (data == 0) return 'p-mr-2 new-chip'
    if (data == 1) return 'p-mr-2 draft-chip'
    if (data == 2) return 'p-mr-2 cancelled-chip'
    if (data == 3) return 'p-mr-2 posted-chip'
    if (data == 4) return 'p-mr-2 transferred-chip'
    if (data == 5) return 'p-mr-2 completed-chip'
    if (data == 6) return 'p-mr-2 rejected-chip'
  }

  transfersInStatus = [
    { id: 1, title: 'Pending' },
    { id: 2, title: 'Received' },
    { id: 3, title: 'Rejected' },
    { id: 4, title: 'Closed' },
    { id: 5, title: 'Draft' },
  ]

  getTransfersInStatus(data) {
    const ret = this.transfersInStatus.find(f => f.id == data).title
    return ret
  }

  getTransfersInClass(data) {
    if (data == 1) return 'p-mr-2 pending-chip'
    if (data == 2) return 'p-mr-2 received-chip'
    if (data == 3) return 'p-mr-2 rejected-chip'
    if (data == 4) return 'p-mr-2 closed-chip'
    if (data == 5) return 'p-mr-2 draft-chip'
    if (data == -1) return 'p-mr-2 draft-chip'
    return ''
  }

  isUserLoggedOut() {
    const model = JSON.parse(localStorage.getItem('user'))
    if (!model) return true
    else return false
  }

 async  initMenuList(ok2go = true) {
    // ok2go = true
    const what = '/handheld/'
    const us = localStorage.getItem('whichRoute')
    if (window.location.href.includes('/handheld')) {
      this.whichRoute = 'inventory'
      localStorage.setItem('whichRoute', 'handheld')

      if (this.isUserLoggedOut()) this._router.navigate(['handheld/signin'])

      this.appLeftMenu = [
        // {
        //   label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/']
        // },
        {
          label: 'Inventory',
          icon: 'pi pi-pw pi-list',
          // visible: await this._permissionsCheckerService.isPermissionValid(['2027','2028','2029','2030','2031','2032','2034','2035','2061','2103']),
          items: [
            { label: 'Overview', 
              // icon: 'pi pi-fw pi-bookmark', 
              routerLink: [what + 'inventory-report'], 
            // visible: await this._permissionsCheckerService.isPermissionValid(['2061'],'app/inventory-report'),
            command: (event) => { this.resetFilters() }, },
            { label: 'Products', 
              // icon: 'pi pi-fw pi-tags', 
              routerLink: [what + 'products'], 
            // visible: await this._permissionsCheckerService.isPermissionValid(['2031'],'app/products'),
            command: (event) => { this.resetFilters() } },
            { label: 'Departments', 
              icon: 'pi pi-fw pi-table', 
              routerLink: [what + 'departments'], 
            // visible: await this._permissionsCheckerService.isPermissionValid(['2028'],'app/departments'),
            command: (event) => { this.resetFilters() } },
            { label: 'Categories', 
              icon: 'pi pi-fw pi-qrcode', 
              routerLink: [what + 'categories'], 
            // visible: await this._permissionsCheckerService.isPermissionValid(['2027'],'app/categories'),
            command: (event) => { this.resetFilters() } },
            { label: 'Manufacturer', icon: 'pi pi-fw pi-user', routerLink: [what + 'manufacturer'], 
            // visible: await this._permissionsCheckerService.isPermissionValid(['2029'],'app/manufacturer'),
            command: (event) => { this.resetFilters() } },
            { label: 'Vendors', icon: 'pi pi-fw pi-users', routerLink: [what + 'vendors'], 
            // visible: await this._permissionsCheckerService.isPermissionValid(['2030'],'app/vendors'),
            command: (event) => { this.resetFilters() } },
            { label: 'Inventory Count', icon: 'pi pi-fw pi-clone', routerLink: [what + 'inventory-count'], 
            // visible: await this._permissionsCheckerService.isPermissionValid(['2032'],'app/inventory-count'),
            command: (event) => { this.resetFilters() } },
            { label: 'Purchase Orders', icon: 'pi pi-fw pi-list', routerLink: [what + 'purchase-orders'],
          //  visible: await this._permissionsCheckerService.isPermissionValid(['2035'],'app/purchase-orders'),
          },
            {
              label: 'Transfers', icon: '', 
               visible: await this._permissionsCheckerService.isPermissionValid(['2103']),
              items: [
                { label: 'Transfer Out', icon: 'pi pi-arrow-up-left', 
                // visible: await this._permissionsCheckerService.isPermissionValid([''],'app/transfers-list'),
                command: (event) => { this.transferListClicked() } },
                { label: 'Transfer In', icon: 'pi pi-arrow-down-right', 
                // visible: await this._permissionsCheckerService.isPermissionValid([''],'app/transfers-receive-list'),
                command: (event) => { this.transferReceivedListClicked() } }]
            },
            { label: 'Inventory Adjustment', icon: 'pi pi-fw pi-clone', routerLink: [what + 'app-inventory-adjustment-list'], 
            // visible: await this._permissionsCheckerService.isPermissionValid(['2034'],'app/app-inventory-adjustment-list'),
            command: (event) => { this.resetFilters() } },
            //   { label: 'Add Inventory Adjustment', icon: 'pi pi-fw pi-clone', routerLink: [what + 'app-inventory-adjustment'],command: (event) => { this.resetFilters() } },
          ]
        },
        {
          label: 'Reports', icon: 'pi pi-fw pi-chart-pie',
          //  visible: await this._permissionsCheckerService.isPermissionValid(['']),
          items: [
            // { label: 'Daily Sales', icon: 'pi pi-fw pi-chart-line', routerLink: [what + 'daily-sales-report'],command: (event) => { this.resetFilters() } },
            // { label: 'Tenders', icon: 'pi pi-fw pi-dollar', routerLink: [what + 'tender-report'],command: (event) => { this.resetFilters() } },
            // { label: 'Hourly Sales', icon: 'pi pi-fw pi-clock', routerLink: [what + 'hourly-sales-report'],command: (event) => { this.resetFilters() } },
           
            { label: 'Product Sales', icon: 'pi pi-fw pi-chart-line', routerLink: [what + 'product-report'], 
            // visible: await this._permissionsCheckerService.isPermissionValid(['2104'],'app/product-report'),
            command: (event) => { this.resetFilters() } },
            { label: 'Departmental Sales', icon: 'pi pi-fw pi-chart-line', routerLink: [what + 'department-sales-report'], 
            // visible: await this._permissionsCheckerService.isPermissionValid(['2031'],'app/department-sales-report'),
            command: (event) => { this.resetFilters() } },
           
            // { label: 'Category Sales', icon: 'pi pi-fw pi-chart-line', routerLink: [what + 'category-sales-report'],command: (event) => { this.resetFilters() } },
            // { label: 'Sales Tax', icon: 'pi pi-fw pi-money-bill', routerLink: [what + 'sales-tax-report-v2'],command: (event) => { this.resetFilters() } },
            // { label: 'Gross Profit', icon: 'pi pi-fw pi-chart-line', routerLink: [what + 'gross-profit-report'],command: (event) => { this.resetFilters() } },
            // { label: 'Credit Card Sales', icon: 'pi pi-fw pi-credit-card', routerLink: [what + 'credit-card-sales-report'],command: (event) => { this.resetFilters() } },
            // { label: 'Credit Card Summary', icon: 'pi pi-fw pi-credit-card', routerLink: [what + 'credit-card-summary-report'],command: (event) => { this.resetFilters() } },
            // { label: 'Refunds', icon: 'pi pi-fw pi-replay', routerLink: [what + 'return-report'],command: (event) => { this.resetFilters() } },
            // { label: 'Daily Money', icon: 'pi pi-fw pi-chart-line', routerLink: [what + 'daily-money-report'],command: (event) => { this.resetFilters() } },
            // { label: 'Discounts', icon: 'pi pi-fw pi-percentage', routerLink: [what + 'discount-sales-report'],command: (event) => { this.resetFilters() } },
            // { label: 'Loyalty', icon: 'pi pi-fw pi-chart-line', routerLink: [what + 'loyalty-report'],command: (event) => { this.resetFilters() } },
            // { label: 'Transfers ', icon: 'pi pi-fw pi-chart-line', routerLink: [what + 'transfer-report'],command: (event) => { this.resetFilters() } },
            // { label: 'Transfer Details', icon: 'pi pi-fw pi-chart-line', routerLink: [what + 'transfer-detail-report'],command: (event) => { this.resetFilters() } },
          ]
        },
        // {
        //   label: 'Sales Invoices', icon: 'pi pi-fw pi-shopping-cart', routerLink: [what + 'sales-invoices']

        // },
        // {
        //   label: 'Customers', icon: 'pi pi-fw pi-users',
        //   items: [
        //     { label: 'Customers List', icon: 'pi pi-fw pi-user', routerLink: [what + 'customers'],command: (event) => { this.resetFilters() } },
        //     // {label: 'Group', icon: 'pi pi-fw pi-users', routerLink: [what + 'customer-group']}
        //   ]
        // },

        // {
        //   label: 'Discounts', icon: 'pi pi-fw pi-percentage', routerLink: [what + 'discounts']
        // },
        // {
        //   label: 'Settings', icon: 'pi pi-fw pi-cog',
        //   items: [
        //     { label: 'Locations', icon: 'pi pi-fw pi-book', routerLink: [what + 'locations-list'],command: (event) => { this.resetFilters() } },
        //     { label: 'Users', icon: 'pi pi-fw pi-user', routerLink: [what + 'users-list'],command: (event) => { this.resetFilters() } },
        //     { label: 'Business', icon: 'pi pi-fw pi-shopping-bag', routerLink: [what + 'business'],command: (event) => { this.resetFilters() } },
        //   ]
        // },
        // {
        //   label: 'Purchase Orders Receive', icon: 'pi pi-fw pi-list', routerLink: [what + 'purchase-orders-receive-list']
        // },
        // {
        //   label: 'Purchase Orders', icon: 'pi pi-fw pi-list', routerLink: [what + 'purchase-orders']
        // },
        // {
        //   label: 'Signup', icon: 'pi pi-fw pi-user-plus', routerLink: ['setup/subscription-plans']
        // },
      ]
      if (ok2go) this._router.navigate([what + 'inventory-report'])
      // this._router.navigate([what + 'inventory-report'])

      return
    }

    localStorage.setItem('whichRoute', 'any')
    if (this.isUserLoggedOut()) this._router.navigate(['pages/signin'])
 
    if (window.location.host.includes('admin.connect') || window.location.host.includes('admin.saasypos') || window.location.host.includes('lsocalhost')) {
      this.isAdmin = true;
    this.appLeftMenu = [
        // {
        //   label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/']
        // },
        {
          label: 'Customers', icon: 'pi pi-fw pi-home', routerLink: ['bizz/customers-list']
        },
        { label: 'Sales Channel', icon: '', routerLink: ['bizz/sales-channel']},
        { label: 'Coupon Codes', icon: '', routerLink: ['bizz/coupon-codes']},
        {
          label: 'Reports', icon: '',
          items: [
            { label: 'Coupon', icon: '', routerLink: ['bizz/coupon-summary']},
            // { label: 'Customer Tracking', icon: '', routerLink: ['bizz/customer-tracking']},
             { label: 'Sales Channel', icon: '', routerLink: ['bizz/sales-channel-report']},
         ]
        },
      ]
 
     }
     else {
      this.isAdmin = false
       this.appLeftMenu = [
           {
               label: '── Store Management',
               //  icon: 'pi pi-home',
               items: [
                   {
                       label: 'Dashboard',
                       icon: 'dashboard',
                       routerLink: ['/'],
                   },
                   {
                       label: 'Webstore',
                       icon: 'webstore',
                       routerLink: ['app/overview'],
                       items: [
                           {
                               label: 'Overview',
                               routerLink: ['app/overview'],
                               // visible: await this._permissionsCheckerService.isPermissionValid(['2089'],'app/discounts'),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                           //  {
                           //      label: 'Design',
                           //      routerLink: ['app/online-store'],
                           //      // visible: await this._permissionsCheckerService.isPermissionValid(['2089'],'app/discounts'),
                           //      command: (event) => {
                           //          this.resetFilters();
                           //      },
                           //  },
                       ],
                   },
                   {
                       label: 'Reports',
                       icon: 'reports',
                       //  visible: await this._permissionsCheckerService.isPermissionValid(['2057','2048','2049','2104','2051','2063','2064','2052','2053','2056','2065','2066','2069','2067','2068','2069','2033','2071','2072','2073','2074','2075','2076','2077','2078','2079','2080','2081','2082','2083','2084','2085','2086','2087','2050','2054','2088']),
                       items: [
                           {
                               label: 'Sales Reports',
                               icon: '',
                               // visible: await this._permissionsCheckerService.isPermissionValid(['2057','2048','2049','2104','2051','2063']),
                               items: [
                                   {
                                       label: 'Daily Sales',
                                       // icon: 'pi pi-fw pi-calendar',
                                       routerLink: ['app/daily-sales-report'],
                                       // visible: await this._permissionsCheckerService.isPermissionValid(['2048'],'app/daily-sales-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   {
                                       label: 'Hourly Sales',
                                       // icon: 'pi pi-fw pi-clock',
                                       routerLink: ['app/hourly-sales-report'],
                                       // visible: await this._permissionsCheckerService.isPermissionValid(['2049'],'app/hourly-sales-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   {
                                       label: 'Product Sales',
                                       // icon: 'pi pi-fw pi-chart-line',
                                       routerLink: ['app/product-report'],
                                       // visible: await this._permissionsCheckerService.isPermissionValid(['2104'],'app/product-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   {
                                       label: 'Departmental Sales',
                                       // icon: 'pi pi-fw pi-chart-line',
                                       routerLink: [
                                           'app/department-sales-report',
                                       ],
                                       // visible: await this._permissionsCheckerService.isPermissionValid(['2051'],'app/department-sales-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   {
                                       label: 'Category Sales',
                                       // icon: 'pi pi-fw pi-chart-line',
                                       routerLink: [
                                           'app/category-sales-report',
                                       ],
                                       // visible: await this._permissionsCheckerService.isPermissionValid(['2063'],'app/category-sales-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                               ],
                           },
                           {
                               label: 'Money Reports',
                               icon: '',
                               // visible: await this._permissionsCheckerService.isPermissionValid(['2064','2052','2053','2056','2065','2066','2069','2067','2068','2069']),
                               items: [
                                   {
                                       label: 'Daily Money',
                                       // icon: 'pi pi-fw pi-chart-line',
                                       routerLink: ['app/daily-money-report'],
                                       //  // visible: await this._permissionsCheckerService.isPermissionValid(['2056'],'app/daily-money-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   {
                                       label: 'Tenders',
                                       // icon: 'pi pi-fw pi-dollar',
                                       routerLink: ['app/tender-report'],
                                       //  // visible: await this._permissionsCheckerService.isPermissionValid(['2065'],'app/tender-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   {
                                       label: 'Credit Card Sales',
                                       // icon: 'pi pi-fw pi-credit-card',
                                       routerLink: [
                                           'app/credit-card-sales-report',
                                       ],
                                       //  // visible: await this._permissionsCheckerService.isPermissionValid(['2052'],'app/credit-card-sales-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   {
                                       label: 'Credit Card Summary',
                                       //icon: 'pi pi-fw pi-credit-card',
                                       routerLink: [
                                           'app/credit-card-summary-report',
                                       ],
                                       //  // visible: await this._permissionsCheckerService.isPermissionValid(['2053'],'app/credit-card-summary-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   {
                                       label: 'Cash Discount Sales',
                                       //icon: 'pi pi-fw pi-chart-line',
                                       routerLink: [
                                           'app/cash-discount-sales-report',
                                       ],
                                       // // visible: await this._permissionsCheckerService.isPermissionValid(['2052'],'app/cash-discount-sales-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   {
                                       label: 'Cash Summary',
                                       // icon: 'pi pi-fw pi-money-bill',
                                       routerLink: ['app/daily-cash-summarybd'],
                                       //  // visible: await this._permissionsCheckerService.isPermissionValid(['2066'],'app/daily-cash-summarybd'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   {
                                       label: 'Cash Count',
                                       //icon: 'pi pi-fw pi-dollar',
                                       routerLink: ['app/cash-count-report'],
                                       //  // visible: await this._permissionsCheckerService.isPermissionValid(['2067'],'app/cash-count-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   {
                                       label: 'Cash Drawer',
                                       //icon: 'pi pi-fw pi-list',
                                       routerLink: ['app/cash-drawer-report'],
                                       //  // visible: await this._permissionsCheckerService.isPermissionValid(['2068'],'app/cash-drawer-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   {
                                       label: 'Till Report',
                                       //icon: 'pi pi-fw pi-list',
                                       routerLink: ['app/till-summary-report'],
                                       //  // visible: await this._permissionsCheckerService.isPermissionValid(['2069'],'app/till-summary-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                               ],
                           },
                           {
                               label: 'Inventory Reports',
                               icon: '',
                               // visible: await this._permissionsCheckerService.isPermissionValid(['2033','2071','2072','2073','2074']),
                               items: [
                                   {
                                       label: 'Transfers ',
                                       //icon: 'pi pi-fw pi-chart-line',
                                       routerLink: ['app/transfer-report'],
                                       // visible: await this._permissionsCheckerService.isPermissionValid(['2071'],'app/transfer-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   {
                                       label: 'Transfer Details',
                                       //icon: 'pi pi-fw pi-chart-line',
                                       routerLink: [
                                           'app/transfer-detail-report',
                                       ],
                                       // visible: await this._permissionsCheckerService.isPermissionValid(['2072'],'app/transfer-detail-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   //   { label: 'Purchasing Report', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/app-purchasing-report'],command: (event) => { this.resetFilters() } },
                                   {
                                       label: 'Adjustment Summary',
                                       //icon: 'pi pi-fw pi-chart-line',
                                       routerLink: [
                                           'app/app-inventory-adjustement-summary-report',
                                       ],
                                       // visible: await this._permissionsCheckerService.isPermissionValid(['2073'],'app/app-inventory-adjustement-summary-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   {
                                       label: 'Adjustment Details',
                                       //icon: 'pi pi-fw pi-chart-line',
                                       routerLink: [
                                           'app/app-inventory-adjustment-detail',
                                       ],
                                       // visible: await this._permissionsCheckerService.isPermissionValid(['2074'],'app/app-inventory-adjustment-detail'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                               ],
                           },
                           {
                               label: 'Loyalty Reports',
                               icon: '',
                               // visible: await this._permissionsCheckerService.isPermissionValid(['2075','2076','2077','2078','2079','2080']),
                               items: [
                                   {
                                       label: 'Loyalty',
                                       // icon: 'pi pi-fw pi-chart-line',
                                       routerLink: ['app/loyalty-report'],
                                       // visible: await this._permissionsCheckerService.isPermissionValid(['2076'],'app/loyalty-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   {
                                       label: 'Reward Discount',
                                       // icon: 'pi pi-fw pi-chart-line',
                                       routerLink: [
                                           'app/app-loyalty-discount-report',
                                       ],
                                       // visible: await this._permissionsCheckerService.isPermissionValid(['2077'],'app/app-loyalty-discount-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   // { label: 'Tiered Reward Customer Report', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/app-tiered-reward-customer-report'], command: (event) => { this.resetFilters() } },
                                   {
                                       label: 'Reward Report',
                                       // icon: 'pi pi-fw pi-chart-line',
                                       routerLink: [
                                           'app/app-tiered-reward-report',
                                       ],
                                       // visible: await this._permissionsCheckerService.isPermissionValid(['2078'],'app/app-tiered-reward-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   {
                                       label: 'Reward Summary',
                                       // icon: 'pi pi-fw pi-chart-line',
                                       routerLink: [
                                           'app/app-tiered-reward-summary-report',
                                       ],
                                       // visible: await this._permissionsCheckerService.isPermissionValid(['2079'],'app/app-tiered-reward-summary-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   {
                                       label: 'Product Reward',
                                       // icon: 'pi pi-fw pi-chart-line',
                                       routerLink: [
                                           'app/app-tiered-reward-product-report',
                                       ],
                                       // visible: await this._permissionsCheckerService.isPermissionValid(['2080'],'app/app-tiered-reward-product-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                               ],
                           },
                           {
                               label: 'Tip Reports',
                               icon: '',
                               // visible: await this._permissionsCheckerService.isPermissionValid(['2081','2082','2083']),
                               items: [
                                   {
                                       label: 'Tip Summary Report',
                                       // icon: 'pi pi-fw pi-chart-line',
                                       routerLink: ['app/tip-summary'],
                                       // visible: await this._permissionsCheckerService.isPermissionValid(['2082'],'app/tip-summary'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   {
                                       label: 'Tip Detail Report',
                                       // icon: 'pi pi-fw pi-chart-line',
                                       routerLink: ['app/tip-detail-report'],
                                       // visible: await this._permissionsCheckerService.isPermissionValid(['2083'],'app/tip-detail-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                               ],
                           },
                           {
                               label: 'Time Tracking Reports',
                               icon: '',
                               // visible: await this._permissionsCheckerService.isPermissionValid(['2084','2085','2086']),
                               items: [
                                   {
                                       label: 'Time Tracking Summary',
                                       // icon: 'pi pi-fw pi-chart-line',
                                       routerLink: [
                                           'app/time-tracking-summary',
                                       ],
                                       // visible: await this._permissionsCheckerService.isPermissionValid(['2085'],'app/time-tracking-summary'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                                   {
                                       label: 'Time Tracking Detail',
                                       // icon: 'pi pi-fw pi-chart-line',
                                       routerLink: [
                                           'app/time-tracking-detail-report',
                                       ],
                                       // visible: await this._permissionsCheckerService.isPermissionValid(['2086'],'app/time-tracking-detail-report'),
                                       command: (event) => {
                                           this.resetFilters();
                                       },
                                   },
                               ],
                           },

                           //  {
                           //   label: 'Customers Reports', icon: '',
                           //   //  // visible: await this._permissionsCheckerService.isPermissionValid(['2084','2085','2086']),
                           //   items: [
                           //     // { label: 'Group', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/customer-group-report'],
                           //     //   // // visible: await this._permissionsCheckerService.isPermissionValid(['2088'],'app/discount-sales-report'),
                           //     //  command: (event) => { this.resetFilters() } },
                           //     { label: 'Purchase Summary', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/customer-purchase-summary'],
                           //       // // visible: await this._permissionsCheckerService.isPermissionValid(['2088'],'app/discount-sales-report'),
                           //      command: (event) => { this.resetFilters() } },
                           //     { label: 'Purchase Details', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/customer-purchase-details'],
                           //       // // visible: await this._permissionsCheckerService.isPermissionValid(['2088'],'app/discount-sales-report'),
                           //      command: (event) => { this.resetFilters() } },
                           //   ]
                           // },
                           {
                               label: 'Gross Profit',
                               // icon: 'pi pi-fw pi-chart-line',
                               routerLink: ['app/gross-profit-report'],
                               // visible: await this._permissionsCheckerService.isPermissionValid(['2087'],'app/gross-profit-report'),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                           {
                               label: 'Refunds',
                               // icon: 'pi pi-fw pi-replay',
                               routerLink: ['app/return-report'],
                               // visible: await this._permissionsCheckerService.isPermissionValid(['2050'],'app/return-report'),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                           {
                               label: 'Sales Tax',
                               // icon: 'pi pi-fw pi-money-bill',
                               routerLink: ['app/sales-tax-report-v2'],
                               // visible: await this._permissionsCheckerService.isPermissionValid(['2054'],'app/sales-tax-report-v2'),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                           {
                               label: 'Discounts',
                               // icon: 'pi pi-fw pi-percentage',
                               routerLink: ['app/discount-sales-report'],
                               // visible: await this._permissionsCheckerService.isPermissionValid(['2088'],'app/discount-sales-report'),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                           {
                               label: 'Audit Trial',
                               // icon: 'pi pi-fw pi-chart-line',
                               routerLink: ['app/audit-trial'],
                               // // visible: await this._permissionsCheckerService.isPermissionValid(['2088'],'app/discount-sales-report'),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                       ],
                   },
               ],
           },
           {
               label: '── Orders & Sales',
               //  icon: 'pi pi-home',
               items: [
                   {
                       label: 'Orders',
                       icon: 'orders',
                       items: [
                           {
                               label: 'Sales Invoices',
                              //  icon: 'pi pi-fw pi-shopping-cart',
                               routerLink: ['app/sales-invoices'],
                              //  visible:
                              //      await this._permissionsCheckerService.isPermissionValid(
                              //          ['2044'],
                              //          'app/sales-invoices'
                              //      ),
                           },
                           {
                               label: 'Purchase Orders',
                               //  icon: 'pi pi-fw pi-list',
                               routerLink: ['app/purchase-orders'],
                               //  visible:
                               //      await this._permissionsCheckerService.isPermissionValid(
                               //          ['2035'],
                               //          'app/purchase-orders'
                               //      ),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                     
                       ],
                   },
                   {
                       label: 'Products',
                       icon: 'products',
                       //  visible: await this._permissionsCheckerService.isPermissionValid(['2027','2028','2029','2030','2031','2032','2034','2035','2061','2103']),
                       items: [
                           {
                               label: 'Overview',
                               //  icon: 'pi pi-fw pi-bookmark',
                               routerLink: ['app/inventory-report'],
                               //  visible: await this._permissionsCheckerService.isPermissionValid(['2061'],'app/inventory-report'),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                           {
                               label: 'Products',
                               //  icon: 'pi pi-fw pi-tags',
                               routerLink: ['app/products'],
                               //  visible:
                               //      await this._permissionsCheckerService.isPermissionValid(
                               //          ['2031'],
                               //          'app/products'
                               //      ),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                           {
                               label: 'Departments',
                               //  icon: 'pi pi-fw pi-table',
                               routerLink: ['app/departments'],
                               //  visible:
                               //      await this._permissionsCheckerService.isPermissionValid(
                               //          ['2028'],
                               //          'app/departments'
                               //      ),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                           {
                               label: 'Categories',
                               //  icon: 'pi pi-fw pi-qrcode',
                               routerLink: ['app/categories'],
                               //  visible:
                               //      await this._permissionsCheckerService.isPermissionValid(
                               //          ['2027'],
                               //          'app/categories'
                               //      ),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                           {
                               label: 'Manufacturer',
                               //  icon: 'pi pi-fw pi-user',
                               routerLink: ['app/manufacturer'],
                               //  visible:
                               //      await this._permissionsCheckerService.isPermissionValid(
                               //          ['2029'],
                               //          'app/manufacturer'
                               //      ),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                           {
                               label: 'Vendors',
                               //  icon: 'pi pi-fw pi-users',
                               routerLink: ['app/vendors'],
                               //  visible:
                               //      await this._permissionsCheckerService.isPermissionValid(
                               //          ['2030'],
                               //          'app/vendors'
                               //      ),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                           {
                               label: 'Inventory Count',
                               //  icon: 'pi pi-fw pi-clone',
                               routerLink: ['app/inventory-count'],
                               //  visible:
                               //      await this._permissionsCheckerService.isPermissionValid(
                               //          ['2032'],
                               //          'app/inventory-count'
                               //      ),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                           {
                               label: 'Inventory Adjustment',
                               //  icon: 'pi pi-fw pi-clone',
                               routerLink: [
                                   'app/app-inventory-adjustment-list',
                               ],
                               //  visible:
                               //      await this._permissionsCheckerService.isPermissionValid(
                               //          ['2034'],
                               //          'app/app-inventory-adjustment-lis'
                               //      ),
                               command: (event) => {
                                   this.resetFilters();
                               },
                               
                           },
                           {
                            label: 'Transfers',
                            icon: '',
                            //  visible: await this._permissionsCheckerService.isPermissionValid([''],'app/'),
                            items: [
                                {
                                    label: 'Transfer Out',
                                    //  icon: 'pi pi-arrow-up-left',
                                    routerLink: ['app/transfers-list'],
                                    // visible: await this._permissionsCheckerService.isPermissionValid([''],'app/'),
                                    command: (event) => {
                                        this.resetFilters();
                                    },
                                },
                                {
                                    label: 'Transfer In',
                                    //  icon: 'pi pi-arrow-down-right',
                                    routerLink: [
                                        'app/transfers-receive-list',
                                    ],
                                    //  visible: await this._permissionsCheckerService.isPermissionValid([''],'app/'),
                                },
                            ],
                        },
                           //   { label: 'Add Inventory Adjustment', icon: 'pi pi-fw pi-clone', routerLink: ['app/app-inventory-adjustment'],command: (event) => { this.resetFilters() } },
                           {
                               label: 'Label Printing',
                               //  icon: 'pi pi-fw pi-print',
                               routerLink: ['app/label-printing'],
                               //  visible: await this._permissionsCheckerService.isPermissionValid(['2062'],'app/label-printing'),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                       ],
                   },
                   {
                       label: 'Customers',
                       icon: 'customers',
                       routerLink: ['app/customers'],
                       //  visible:
                       //      await this._permissionsCheckerService.isPermissionValid(
                       //          ['2046', '2047', '2060'],
                       //          'app/customers'
                       //      ),
                       command: (event) => {
                           this.resetFilters();
                       },
                       //  label: 'Customers', icon: 'pi pi-fw pi-users',
                       //   visible: await this._permissionsCheckerService.isPermissionValid(['2046','2047','2060'],'app/customers'),
                       //  command: (event) => { this.resetFilters() },
                       //  items: [
                       //    { label: 'Customers List', icon: 'pi pi-fw pi-user', routerLink: ['app/customers'], command: (event) => { this.resetFilters() } },
                       //    {label: 'Group', icon: 'pi pi-fw pi-users', routerLink: ['app/customer-group']}
                       //  ]
                   },
               ],
           },
           {
               label: '── Business Growth ',
               //  icon: 'pi pi-home',
               items: [
                   {
                       label: 'Discounts',
                       icon: 'discounts',
                       routerLink: ['app/discounts'],
                       //  visible:
                       //      await this._permissionsCheckerService.isPermissionValid(
                       //          ['2089'],
                       //          'app/discounts'
                       //      ),
                       command: (event) => {
                           this.resetFilters();
                       },
                   },

                   this._globals.userInfo?.businessSetup[0].enableLoyaltyReward
                       ? {
                             label: 'Loyalty Program',
                             icon: 'loyalty',
                             //  visible:
                             //      await this._permissionsCheckerService.isPermissionValid(
                             //          ['2100', '2101', '2102']
                             //      ),
                             items: [
                                 {
                                     label: 'General',
                                     //  icon: 'pi pi-fw pi-cog',
                                     routerLink: [
                                         'app/loyalty-program-general',
                                     ],
                                     //  visible:
                                     //      await this._permissionsCheckerService.isPermissionValid(
                                     //          ['2101'],
                                     //          'app/loyalty-program-general'
                                     //      ),
                                     command: (event) => {
                                         this.resetFilters();
                                     },
                                 },
                                 {
                                     label: 'Reward Configuration',
                                     //  icon: 'pi pi-dollar',
                                     routerLink: ['app/reward-configuration'],
                                     //  visible:
                                     //      await this._permissionsCheckerService.isPermissionValid(
                                     //          ['2102'],
                                     //          'app/reward-configuration'
                                     //      ),
                                     command: (event) => {
                                         this.resetFilters();
                                     },
                                 },
                             ],
                         }
                       : {
                             label: 'Loyalty Program',
                             icon: 'loyalty',
                             routerLink: ['app/loyalty-program-subscribe'],
                             //  visible: await this._permissionsCheckerService.isPermissionValid([''],'app/'),
                             command: (event) => {
                                 this.resetFilters();
                             },
                         },
                   //  {
                   //   label: 'Reconciliation',
                   //   icon: '',
                   //   routerLink: ['app/app-cash-reconciliation-new'],
                   //   // visible: await this._permissionsCheckerService.isPermissionValid(['2090'],'app/app-cash-reconciliation-new'),
                   //   command: (event) => {
                   //       this.resetFilters();
                   //   },
                   //   },
               ],
           },
           {
               label: '── Configurations ',
               //  icon: 'pi pi-home',
               items: [
                   {
                       label: 'Settings',
                       icon: 'settings',
                       //  visible:
                       //      await this._permissionsCheckerService.isPermissionValid(
                       //          [
                       //              '2006',
                       //              '2007',
                       //              '2008',
                       //              '2009',
                       //              '2010',
                       //              '2011',
                       //              '2012',
                       //              '2013',
                       //              '2014',
                       //              '2015',
                       //              '2091',
                       //              '2018',
                       //              '2019',
                       //              '2020',
                       //              '2021',
                       //              '2022',
                       //              '2023',
                       //              '2024',
                       //              '2092',
                       //              '2093',
                       //              '2094',
                       //              '2100',
                       //              '2095',
                       //              '2096',
                       //              '2097',
                       //              '2098',
                       //              '2099',
                       //          ]
                       //      ),
                       items: [
                           {
                               label: 'Locations',
                               //  icon: 'pi pi-fw pi-book',
                               routerLink: ['app/locations-list'],
                               //  visible:
                               //      await this._permissionsCheckerService.isPermissionValid(
                               //          ['2011', '2012', '2013', '2014', '2015'],
                               //          'app/locations-list'
                               //      ),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                           {
                               label: 'Users',
                               //  icon: 'pi pi-fw pi-user',
                               routerLink: ['app/users-list'],
                               //  visible:
                               //      await this._permissionsCheckerService.isPermissionValid(
                               //          ['2006', '2007', '2008', '2009', '2010'],
                               //          'app/users-list'
                               //      ),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                           {
                               label: 'Time & Attendance',
                               //  icon: 'pi pi-fw pi-clock',
                               routerLink: ['app/time-attendance'],
                               // visible: await this._permissionsCheckerService.isPermissionValid(['2006','2007','2008','2009','2010'],'app/users-list'),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                           {
                               label: 'Business',
                               //  icon: 'pi pi-fw pi-shopping-bag',
                               routerLink: ['app/business'],
                               //  visible:
                               //      await this._permissionsCheckerService.isPermissionValid(
                               //          [
                               //              '2091',
                               //              '2018',
                               //              '2019',
                               //              '2020',
                               //              '2021',
                               //              '2022',
                               //              '2023',
                               //              '2024',
                               //              '2092',
                               //              '2093',
                               //              '2094',
                               //          ],
                               //          'app/business'
                               //      ),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                           {
                               label: 'Subscription',
                               //  icon: 'pi pi-fw pi-table',
                               routerLink: ['app/subscription-management'],
                               //  visible:
                               //      await this._permissionsCheckerService.isPermissionValid(
                               //          ['2095', '2096', '2097', '2098', '2099'],
                               //          'app/subscription-management'
                               //      ),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },
                       ],
                   },
                   {
                       label: 'Profile',
                       icon: 'profile',
                       items: [
                           {
                               label: 'Billing & Plans',
                               //  icon: 'pi pi-fw pi-book',
                               routerLink: ['app/billing-and-plans'],
                               //  visible:
                               //      await this._permissionsCheckerService.isPermissionValid(
                               //          ['2011', '2012', '2013', '2014', '2015'],
                               //          'app/locations-list'
                               //      ),
                               command: (event) => {
                                   this.resetFilters();
                               },
                           },                    
                       ],
                   },
               ],
           },
       ];

      //  =  [
      //    {
      //      label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/']
      //    },
      //    {
      //     label: 'Online Store', icon: 'pi pi-fw pi-desktop', routerLink: ['app/online-store'],
      //     // visible: await this._permissionsCheckerService.isPermissionValid(['2089'],'app/discounts'),
      //      command: (event) => { this.resetFilters()}
      //    },
      //    {
      //      label: 'Inventory',
      //      icon: 'pi pi-pw pi-list',
      //      visible: await this._permissionsCheckerService.isPermissionValid(['2027','2028','2029','2030','2031','2032','2034','2035','2061','2103']),
      //      items: [
      //        { label: 'Overview', icon: 'pi pi-fw pi-bookmark', routerLink: ['app/inventory-report'], 
      //        visible: await this._permissionsCheckerService.isPermissionValid(['2061'],'app/inventory-report'),
      //        command: (event) => { this.resetFilters() } },
      //        { label: 'Products', icon: 'pi pi-fw pi-tags', routerLink: ['app/products'], 
      //        visible: await this._permissionsCheckerService.isPermissionValid(['2031'],'app/products'),
      //        command: (event) => { this.resetFilters() } },
      //        { label: 'Departments', icon: 'pi pi-fw pi-table', routerLink: ['app/departments'], 
      //        visible: await this._permissionsCheckerService.isPermissionValid(['2028'],'app/departments'),
      //        command: (event) => { this.resetFilters() } },
      //        { label: 'Categories', icon: 'pi pi-fw pi-qrcode', routerLink: ['app/categories'], 
      //        visible: await this._permissionsCheckerService.isPermissionValid(['2027'],'app/categories'),
      //        command: (event) => { this.resetFilters() } },
      //        { label: 'Manufacturer', icon: 'pi pi-fw pi-user', routerLink: ['app/manufacturer'], 
      //        visible: await this._permissionsCheckerService.isPermissionValid(['2029'],'app/manufacturer'),
      //        command: (event) => { this.resetFilters() } },
      //        { label: 'Vendors', icon: 'pi pi-fw pi-users', routerLink: ['app/vendors'], 
      //        visible: await this._permissionsCheckerService.isPermissionValid(['2030'],'app/vendors'),
      //        command: (event) => { this.resetFilters() } },
      //        { label: 'Inventory Count', icon: 'pi pi-fw pi-clone', routerLink: ['app/inventory-count'], 
      //        visible: await this._permissionsCheckerService.isPermissionValid(['2032'],'app/inventory-count'),
      //        command: (event) => { this.resetFilters() } },
      //        { label: 'Purchase Orders', icon: 'pi pi-fw pi-list', routerLink: ['app/purchase-orders'], 
      //        visible: await this._permissionsCheckerService.isPermissionValid(['2035'],'app/purchase-orders'),
      //        command: (event) => { this.resetFilters() } },
      //        {
      //          label: 'Transfers', icon: '', 
      //          //  visible: await this._permissionsCheckerService.isPermissionValid([''],'app/'),
      //          items: [
      //            { label: 'Transfer Out', icon: 'pi pi-arrow-up-left', routerLink: ['app/transfers-list'], 
      //            // visible: await this._permissionsCheckerService.isPermissionValid([''],'app/'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Transfer In', icon: 'pi pi-arrow-down-right', routerLink: ['app/transfers-receive-list'],
      //           //  visible: await this._permissionsCheckerService.isPermissionValid([''],'app/'),
      //           }]
      //        },
      //        { label: 'Inventory Adjustment', icon: 'pi pi-fw pi-clone', routerLink: ['app/app-inventory-adjustment-list'], 
      //        visible: await this._permissionsCheckerService.isPermissionValid(['2034'],'app/app-inventory-adjustment-lis'),
      //        command: (event) => { this.resetFilters() } },
      //        //   { label: 'Add Inventory Adjustment', icon: 'pi pi-fw pi-clone', routerLink: ['app/app-inventory-adjustment'],command: (event) => { this.resetFilters() } },
      //        { label: 'Label Printing', icon: 'pi pi-fw pi-print', routerLink: ['app/label-printing'], 
      //       //  visible: await this._permissionsCheckerService.isPermissionValid(['2062'],'app/label-printing'),
      //        command: (event) => { this.resetFilters() } },
         
      //       ]
      //    },
      //    {
      //      label: 'Reports', icon: 'pi pi-fw pi-chart-pie',
      //      visible: await this._permissionsCheckerService.isPermissionValid(['2057','2048','2049','2104','2051','2063','2064','2052','2053','2056','2065','2066','2069','2067','2068','2069','2033','2071','2072','2073','2074','2075','2076','2077','2078','2079','2080','2081','2082','2083','2084','2085','2086','2087','2050','2054','2088']),
      //      items: [
      //        {
      //          label: 'Sales Reports', icon: '', 
      //          visible: await this._permissionsCheckerService.isPermissionValid(['2057','2048','2049','2104','2051','2063']),
      //          items: [
      //            { label: 'Daily Sales', icon: 'pi pi-fw pi-calendar', routerLink: ['app/daily-sales-report'], 
      //             visible: await this._permissionsCheckerService.isPermissionValid(['2048'],'app/daily-sales-report'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Hourly Sales', icon: 'pi pi-fw pi-clock', routerLink: ['app/hourly-sales-report'], 
      //             visible: await this._permissionsCheckerService.isPermissionValid(['2049'],'app/hourly-sales-report'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Product Sales', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/product-report'], 
      //             visible: await this._permissionsCheckerService.isPermissionValid(['2104'],'app/product-report'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Departmental Sales', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/department-sales-report'], 
      //             visible: await this._permissionsCheckerService.isPermissionValid(['2051'],'app/department-sales-report'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Category Sales', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/category-sales-report'], 
      //             visible: await this._permissionsCheckerService.isPermissionValid(['2063'],'app/category-sales-report'),
      //            command: (event) => { this.resetFilters() } },

      //          ]
      //        },
      //        {
      //          label: 'Money Reports', icon: '',
      //           visible: await this._permissionsCheckerService.isPermissionValid(['2064','2052','2053','2056','2065','2066','2069','2067','2068','2069']),
      //          items: [
      //            { label: 'Daily Money', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/daily-money-report'], 
      //            //  visible: await this._permissionsCheckerService.isPermissionValid(['2056'],'app/daily-money-report'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Tenders', icon: 'pi pi-fw pi-dollar', routerLink: ['app/tender-report'], 
      //            //  visible: await this._permissionsCheckerService.isPermissionValid(['2065'],'app/tender-report'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Credit Card Sales', icon: 'pi pi-fw pi-credit-card', routerLink: ['app/credit-card-sales-report'], 
      //            //  visible: await this._permissionsCheckerService.isPermissionValid(['2052'],'app/credit-card-sales-report'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Credit Card Summary', icon: 'pi pi-fw pi-credit-card', routerLink: ['app/credit-card-summary-report'], 
      //            //  visible: await this._permissionsCheckerService.isPermissionValid(['2053'],'app/credit-card-summary-report'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Cash Discount Sales', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/cash-discount-sales-report'], 
      //             // visible: await this._permissionsCheckerService.isPermissionValid(['2052'],'app/cash-discount-sales-report'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Cash Summary', icon: 'pi pi-fw pi-money-bill', routerLink: ['app/daily-cash-summarybd'], 
      //            //  visible: await this._permissionsCheckerService.isPermissionValid(['2066'],'app/daily-cash-summarybd'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Cash Count', icon: 'pi pi-fw pi-dollar', routerLink: ['app/cash-count-report'], 
      //            //  visible: await this._permissionsCheckerService.isPermissionValid(['2067'],'app/cash-count-report'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Cash Drawer', icon: 'pi pi-fw pi-list', routerLink: ['app/cash-drawer-report'], 
      //            //  visible: await this._permissionsCheckerService.isPermissionValid(['2068'],'app/cash-drawer-report'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Till Report', icon: 'pi pi-fw pi-list', routerLink: ['app/till-summary-report'], 
      //            //  visible: await this._permissionsCheckerService.isPermissionValid(['2069'],'app/till-summary-report'),
      //            command: (event) => { this.resetFilters() } },
      //          ]
      //        },
      //        {
      //          label: 'Inventory Reports', icon: '', 
      //           visible: await this._permissionsCheckerService.isPermissionValid(['2033','2071','2072','2073','2074']),
      //          items: [
      //            { label: 'Transfers ', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/transfer-report'], 
      //             visible: await this._permissionsCheckerService.isPermissionValid(['2071'],'app/transfer-report'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Transfer Details', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/transfer-detail-report'], 
      //             visible: await this._permissionsCheckerService.isPermissionValid(['2072'],'app/transfer-detail-report'),
      //            command: (event) => { this.resetFilters() } },
      //            //   { label: 'Purchasing Report', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/app-purchasing-report'],command: (event) => { this.resetFilters() } },
      //            { label: 'Adjustment Summary', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/app-inventory-adjustement-summary-report'], 
      //             visible: await this._permissionsCheckerService.isPermissionValid(['2073'],'app/app-inventory-adjustement-summary-report'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Adjustment Details', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/app-inventory-adjustment-detail'], 
      //             visible: await this._permissionsCheckerService.isPermissionValid(['2074'],'app/app-inventory-adjustment-detail'),
      //            command: (event) => { this.resetFilters() } },


      //          ]
      //        },
      //        {
      //          label: 'Loyalty Reports', icon: '',
      //           visible: await this._permissionsCheckerService.isPermissionValid(['2075','2076','2077','2078','2079','2080']),
      //          items: [
      //            { label: 'Loyalty', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/loyalty-report'], 
      //             visible: await this._permissionsCheckerService.isPermissionValid(['2076'],'app/loyalty-report'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Reward Discount', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/app-loyalty-discount-report'], 
      //             visible: await this._permissionsCheckerService.isPermissionValid(['2077'],'app/app-loyalty-discount-report'),
      //            command: (event) => { this.resetFilters() } },
      //            // { label: 'Tiered Reward Customer Report', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/app-tiered-reward-customer-report'], command: (event) => { this.resetFilters() } },
      //            { label: 'Reward Report', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/app-tiered-reward-report'], 
      //             visible: await this._permissionsCheckerService.isPermissionValid(['2078'],'app/app-tiered-reward-report'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Reward Summary', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/app-tiered-reward-summary-report'], 
      //             visible: await this._permissionsCheckerService.isPermissionValid(['2079'],'app/app-tiered-reward-summary-report'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Product Reward', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/app-tiered-reward-product-report'], 
      //             visible: await this._permissionsCheckerService.isPermissionValid(['2080'],'app/app-tiered-reward-product-report'),
      //            command: (event) => { this.resetFilters() } },

      //          ]
      //        },
      //        {
      //          label: 'Tip Reports', icon: '', 
      //           visible: await this._permissionsCheckerService.isPermissionValid(['2081','2082','2083']),
      //          items: [
      //            { label: 'Tip Summary Report', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/tip-summary'], 
      //             visible: await this._permissionsCheckerService.isPermissionValid(['2082'],'app/tip-summary'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Tip Detail Report', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/tip-detail-report'], 
      //             visible: await this._permissionsCheckerService.isPermissionValid(['2083'],'app/tip-detail-report'),
      //            command: (event) => { this.resetFilters() } },               ]
      //        },
      //        {
      //          label: 'Time Tracking Reports', icon: '',
      //           visible: await this._permissionsCheckerService.isPermissionValid(['2084','2085','2086']),
      //          items: [
      //            { label: 'Time Tracking Summary', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/time-tracking-summary'], 
      //             visible: await this._permissionsCheckerService.isPermissionValid(['2085'],'app/time-tracking-summary'),
      //            command: (event) => { this.resetFilters() } },
      //            { label: 'Time Tracking Detail', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/time-tracking-detail-report'], 
      //             visible: await this._permissionsCheckerService.isPermissionValid(['2086'],'app/time-tracking-detail-report'),
      //            command: (event) => { this.resetFilters() } },               ]
      //        },
   
      //       //  {
      //       //   label: 'Customers Reports', icon: '',
      //       //   //  visible: await this._permissionsCheckerService.isPermissionValid(['2084','2085','2086']),
      //       //   items: [
      //       //     // { label: 'Group', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/customer-group-report'], 
      //       //     //   // visible: await this._permissionsCheckerService.isPermissionValid(['2088'],'app/discount-sales-report'),
      //       //     //  command: (event) => { this.resetFilters() } },
      //       //     { label: 'Purchase Summary', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/customer-purchase-summary'], 
      //       //       // visible: await this._permissionsCheckerService.isPermissionValid(['2088'],'app/discount-sales-report'),
      //       //      command: (event) => { this.resetFilters() } },
      //       //     { label: 'Purchase Details', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/customer-purchase-details'], 
      //       //       // visible: await this._permissionsCheckerService.isPermissionValid(['2088'],'app/discount-sales-report'),
      //       //      command: (event) => { this.resetFilters() } },
      //       //   ]
      //       // },
      //        { label: 'Gross Profit', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/gross-profit-report'], 
      //         visible: await this._permissionsCheckerService.isPermissionValid(['2087'],'app/gross-profit-report'),
      //        command: (event) => { this.resetFilters() } },
      //        { label: 'Refunds', icon: 'pi pi-fw pi-replay', routerLink: ['app/return-report'], 
      //         visible: await this._permissionsCheckerService.isPermissionValid(['2050'],'app/return-report'),
      //        command: (event) => { this.resetFilters() } },
      //        { label: 'Sales Tax', icon: 'pi pi-fw pi-money-bill', routerLink: ['app/sales-tax-report-v2'], 
      //         visible: await this._permissionsCheckerService.isPermissionValid(['2054'],'app/sales-tax-report-v2'),
      //        command: (event) => { this.resetFilters() } },
      //        { label: 'Discounts', icon: 'pi pi-fw pi-percentage', routerLink: ['app/discount-sales-report'], 
      //         visible: await this._permissionsCheckerService.isPermissionValid(['2088'],'app/discount-sales-report'),
      //        command: (event) => { this.resetFilters() } },
      //        { label: 'Audit Trial', icon: 'pi pi-fw pi-chart-line', routerLink: ['app/audit-trial'], 
      //         // visible: await this._permissionsCheckerService.isPermissionValid(['2088'],'app/discount-sales-report'),
      //        command: (event) => { this.resetFilters() } },
     
      //      ]
      //    },
      //    {
      //      label: 'Sales Invoices', icon: 'pi pi-fw pi-shopping-cart', routerLink: ['app/sales-invoices'],
      //      visible: await this._permissionsCheckerService.isPermissionValid(['2044'],'app/sales-invoices'),
      //    },
      //    {
      //      label: 'Customers', icon: 'pi pi-fw pi-users', routerLink: ['app/customers'],
      //        visible: await this._permissionsCheckerService.isPermissionValid(['2046','2047','2060'],'app/customers'),
      //      command: (event) => { this.resetFilters()}
      //     //  label: 'Customers', icon: 'pi pi-fw pi-users',  
      //     //   visible: await this._permissionsCheckerService.isPermissionValid(['2046','2047','2060'],'app/customers'),
      //     //  command: (event) => { this.resetFilters() },
      //     //  items: [
      //     //    { label: 'Customers List', icon: 'pi pi-fw pi-user', routerLink: ['app/customers'], command: (event) => { this.resetFilters() } },
      //     //    {label: 'Group', icon: 'pi pi-fw pi-users', routerLink: ['app/customer-group']}
      //     //  ]
      //    },

      //    {
      //      label: 'Discounts', icon: 'pi pi-fw pi-percentage', routerLink: ['app/discounts'],
      //      visible: await this._permissionsCheckerService.isPermissionValid(['2089'],'app/discounts'),
      //    },
      //    //{ label: 'Reconciliation', icon: 'pi pi-fw pi-book', routerLink: ['app/cash-reconciliation-report'], command: (event) => { this.resetFilters() } },
      //    { label: 'Reconciliation', icon: 'pi pi-fw pi-book', routerLink: ['app/app-cash-reconciliation-new'], 
      //     visible: await this._permissionsCheckerService.isPermissionValid(['2090'],'app/app-cash-reconciliation-new'),
      //    command: (event) => { this.resetFilters() } },

      //    {
      //      label: 'Settings', icon: 'pi pi-fw pi-cog',
      //       visible: await this._permissionsCheckerService.isPermissionValid(['2006','2007','2008','2009','2010','2011','2012','2013','2014','2015','2091','2018','2019','2020','2021','2022','2023','2024','2092','2093','2094','2100','2095','2096','2097','2098','2099']),
      //      items: [
      //        { label: 'Locations', icon: 'pi pi-fw pi-book', routerLink: ['app/locations-list'], 
      //         visible: await this._permissionsCheckerService.isPermissionValid(['2011','2012','2013','2014','2015'],'app/locations-list'),
      //        command: (event) => { this.resetFilters() } },
      //        {  label: 'Users', 
      //           icon: 'pi pi-fw pi-user', 
      //           routerLink: ['app/users-list'], 
      //           visible: await this._permissionsCheckerService.isPermissionValid(['2006','2007','2008','2009','2010'],'app/users-list'),
      //           command: (event) => { this.resetFilters() } },
      //        {  label: 'Time & Attendance', 
      //           icon: 'pi pi-fw pi-clock', 
      //           routerLink: ['app/time-attendance'], 
      //           // visible: await this._permissionsCheckerService.isPermissionValid(['2006','2007','2008','2009','2010'],'app/users-list'),
      //           command: (event) => { this.resetFilters() } },
      //        { label: 'Business', icon: 'pi pi-fw pi-shopping-bag', routerLink: ['app/business'], 
      //         visible: await this._permissionsCheckerService.isPermissionValid(['2091','2018','2019','2020','2021','2022','2023','2024','2092','2093','2094'],'app/business'),
      //        command: (event) => { this.resetFilters() } },
      //        { label: 'Subscription', icon: 'pi pi-fw pi-table', routerLink: ['app/subscription-management'], 
      //         visible: await this._permissionsCheckerService.isPermissionValid(['2095','2096','2097','2098','2099'],'app/subscription-management'),
      //        command: (event) => { this.resetFilters() } },
      //      ]
      //    },
      //    // {
      //    //   label: 'Purchase Orders', icon: 'pi pi-fw pi-list', routerLink: ['app/purchase-orders']
      //    // },
      //    this._globals.userInfo?.businessSetup[0].enableLoyaltyReward ?
      //      {
      //        label: 'Loyalty Program', icon: 'pi pi-fw pi-dollar',
      //         visible: await this._permissionsCheckerService.isPermissionValid(['2100','2101','2102']),
      //        items: [
      //          { label: 'General', icon: 'pi pi-fw pi-cog', routerLink: ['app/loyalty-program-general'], 
      //           visible: await this._permissionsCheckerService.isPermissionValid(['2101'],'app/loyalty-program-general'),
      //          command: (event) => { this.resetFilters() } },
      //          { label: 'Reward Configuration', icon: 'pi pi-dollar', routerLink: ['app/reward-configuration'], 
      //           visible: await this._permissionsCheckerService.isPermissionValid(['2102'],'app/reward-configuration'),
      //          command: (event) => { this.resetFilters() } },
      //        ]
      //      } : {

      //        label: 'Loyalty Program', icon: 'pi pi-fw pi-dollar', routerLink: ['app/loyalty-program-subscribe'], 
      //         //  visible: await this._permissionsCheckerService.isPermissionValid([''],'app/'),
      //        command: (event) => { this.resetFilters() }
      //      },
      //  ]
     }
    if (ok2go) this._router.navigate([""])
  }
  isLinkActive(link): string {
           if(this._router.url.includes(link)){
            return 'activeStyle'
           }
  }

  transferListClicked() {
    this.resetFilters()
    if (this.whichRoute = 'inventory')
      this._router.navigate(['handheld/' + 'transfers-list'])
    else
      this._router.navigate(['app/transfers-list'])
  }

  transferReceivedListClicked() {
    this.resetFilters()
    if (this.whichRoute = 'inventory')
      this._router.navigate(['handheld/' + 'transfers-receive-list'])
    else
      this._router.navigate(['app/transfers-receive-list'])
  }

  setOptionToLocalStorage(component, optionName, option) {
    let str = localStorage.getItem(optionName)
    if (!str) {
      let list = []
      list.push({
        component: component,
        option: option
      })
      localStorage.setItem(optionName, JSON.stringify(list))
      return
    }

    let list = JSON.parse(str)
    if (!Array.isArray(list)) list = Object.keys(list)

    let found = false
    for (let i = 0; i < list.length; i++) {
      if (list[i].component == component) {
        list[i].option = option
        found = true
        break
      }
    }

    if (!found) {
      list.push({
        component: component,
        option: option
      })
    }

    localStorage.setItem(optionName, JSON.stringify(list))
  }

  getOptionsFromLocalStorage(component, optionName) {
    let str = localStorage.getItem(optionName)
    if (!str) return null

    let list = JSON.parse(str)
    if (!Array.isArray(list)) list = Object.keys(list)

    let found = false
    for (let i = 0; i < list.length; i++) {
      if (list[i].component == component) {
        return list[i].option
      }
    }

    return null
  }

  getProductSellTypeFilter(id) {
    let f = this.productSellTypeFilter
    for (let i = 0; i < f.length; i++) {
      if (id == f[i].id)
        return f[i]
    }
    let ret = f.find(f => id == 3)

    return ret
  }


  getWordsWithSpace(str) {
    if (str != '' && str != undefined) {
      return str.replace(/[A-Z]/g, ' $&').trim()
    }

  }



}

