<div class="flex justify-content-center align-items-center  py-2" style="background-color: #000;">
    <img src="assets/images/lincsell_logo_white.png" width="32px" height="22px" alt="">
</div>
<div class="flex align-items-center justify-content-center pt-4 ">
    <img src="assets/images/clock.svg" width="45px" alt="">
</div>
<div class="trial-notice">
    <p>
      <strong>{{ getRemaintingTime() }} days left in your free trial - 
      <a class="upgrade-link">Upgrade your plan</a> before it ends!
    </strong></p>
    <p class="subtext">
      You are currently on a free trial plan. You can explore all the features and set up your store for 30-days. <br>
      Upgrade your plan to ensure uninterrupted access to grow your business and keep selling without limits.
    </p>
  </div>
  
  <div class="plans-container">
    <div class="plan-card" *ngFor="let plan of plans">
     <div class="plan-header-card">
         <div class="plan-header">{{ plan.title }}</div>
     </div>
     <div class="px-5 py-4">
      <div class="plan-price py-2">
        <span class="price">${{ plan?.price }}</span><span class="per-month">/month</span>
      </div>
      <p class="plan-description p-0 m-0">billed annually or</p>
      <p class="plan-description p-0 m-0">{{ plan.durationType }}</p>
      <button class="upgrade-button w-full" (click)="goToBilling(plan)">Upgrade Plan</button>
       <a  class="detailed-features cursor-pointer" (click)="goToPlanDetails()">See detailed features</a>
     </div>

    </div>
  </div>
  
  <div class="additional-options flex align-items-center flex-column  ">
    <label class="checkbox-container">
      <input type="checkbox" [(ngModel)]="showTrialMessage"/>
      <span class="checkbox-label">Don't show this again until my free trial ends</span>
    </label>
    <button class="continue-button" (click)="goToHome()">Continue using free trial</button>
  </div>