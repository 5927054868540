import { Injectable } from '@angular/core';
import { Observable, Subject, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UsStates } from '../_data/us-states';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { WhiteLabelList } from '../_data/white-label-list';
import { CurrentPlan } from '../_data/current-plan';

@Injectable({
    providedIn: 'root',
})
export class SignupService {
    whiteLabel = WhiteLabelList[0]
    currentPlan = CurrentPlan
    selectedPlanDetails:any = null 
    selectedPlanInterval:string = "Yearly"
    steps: any = [];
    industries: any = [];
    selectBtn: any;
    subscriptionPlans: any = [];
    eComSubscriptionPlans: any = [];
    customerAppSubscriptionPlans: any = [];
    haveHardware: any = [];
    doProcessOnlinePayments: any = [];
    hardWarePackagesList: any = [];
    showSelectedPlan: boolean = false;
    selectedHaveHardwareId = 1;
    selectedDoProcessOnlinePaymentsId = 0;
    currentSubscriptionPlan: string = '';
    public upgradeButtonClicked = new Subject<any>();
    currentStep = 1;
    ok2proceed = false;
    discountAmount:any;
    // Coupon Code Discount
    couponCodeDiscount:any =0;
    couponCodeData:any

    subscriptionPlan: any = null;
    selectedHardware: any = [];
    selectedHardwareWithDiscount: any = [];
    selectedPlan: any = '';
    selectedCustomerAppPlan: any = null;
    selectedPlanTimeframe = 'BilledMonthly';
    planTimeframe: any = [];
    checkoutPageInfo: any = null;
    checkoutPageCalcVars: any = null;

    selectedWebsiteAndOnlineStorePlan: any = null;
    selectedECommerceWebsitePlan: any = null;
    selectedDomainPlan: any = null;
    customAppBaseData: any = [];
    customAppBaseDataId = 1;

    isViewOnly = false;
    ok2savePersonalInfo = false;
    ok2saveBusinessInfo = false;

    personalInfo: any = null;
    businessInfo: any = null;
    customerBillTo: any = null;

    outerSelectedPlanTime = 'Monthly';
    outerLocationQty = 1;
    outerRegisterQty = 1;

    subscriptionPlanLocationsQty = 1;
    subscriptionPlanRegistersQty = 1;

    showCustomDomain = false;

    mainReturnObject: any;
    planChanged: boolean = false;

    loyaltyProgramAppBaseData: any = [];
    loyaltyProgramBaseDataId = 1;
    userAccountPendingForPayment: boolean = false; // use this variable after login
    showPlanSelectionModal: boolean = false;
    //--------
    creditCardFee = [
        {
            title: 'Card Present',
            lite: '2.59% + 15C',
            standard: '2.39% + 15C',
            plus: '2.19% + 15C',
            premium: '1.99% + 15C',
            enterprise: 'Negotiated',
        },
        {
            title: 'Cash Discount Program',
            lite: '2.99% + 15C',
            standard: '2.99% + 15C',
            plus: '2.99% + 15C',
            premium: '2.99% + 15C',
            enterprise: 'Negotiated',
        },
        {
            title: 'Card Not Present:',
            lite: '3.49% + 15C',
            standard: '3.39% + 15C',
            plus: '3.29% + 15C',
            premium: '3.19% + 15C',
            enterprise: 'Negotiated',
        },
    ];

    websiteOnlineeCommerceStore = [
        {
            title: 'Static Website',
            lite: '9.99+',
            standard: 'Included',
            plus: 'Included',
            premium: 'Included',
            enterprise: 'Customized',
        },
        {
            title: 'Basic eCommerce',
            lite: '$49.99',
            standard: '$49.99',
            plus: 'Included',
            premium: 'Included',
            enterprise: 'Customized',
        },
        {
            title: 'Standard eCommerce',
            lite: '$299.99',
            standard: '$299.99',
            plus: '$299.99',
            premium: 'Included',
            enterprise: 'Customized',
        },
        {
            title: 'Premium eCommerce',
            lite: '499.99+',
            standard: '499.99+',
            plus: '499.99+',
            premium: '499.99+',
            enterprise: 'Customized',
        },
        {
            title: 'Domain: You.Connect-360.Net',
            lite: 'Included',
            standard: 'Included',
            plus: 'Included',
            premium: 'Included',
            enterprise: 'Included',
        },
        {
            title: 'Domain: You.com',
            lite: '$19.99/Month',
            standard: '$19.99/Month',
            plus: '$19.99/Month',
            premium: '$19.99/Month',
            enterprise: '$19.99/Month',
        },
        {
            title: 'Social Media Marketing Services:',
            lite: 'Additional',
            standard: 'Additional',
            plus: 'Additional',
            premium: 'Additional',
            enterprise: 'Custom',
        },
    ];

    customerApp = [
        {
            title: 'Loyalty',
            lite: '$99.99',
            standard: '$99.99',
            plus: '$99.99',
            premium: 'Included',
            enterprise: 'Customized',
        },
        {
            title: 'Shopping & Loyalty (includes curbside)',
            lite: '$199.99',
            standard: '$199.99',
            plus: '$199.99',
            premium: '$199.99',
            enterprise: 'Customized',
        },
        {
            title: 'Customized Design & Features',
            lite: '399.99+',
            standard: '399.99+',
            plus: '399.99+',
            premium: '399.99+',
            enterprise: 'Customized',
        },
    ];

    hardware = [
        {
            title: 'POS Platform',
            lite: 'Tablet (Android/iOS) / PAX A920 Pro',
            standard: 'PAX E700/E800 or Tablets',
            plus: 'PAX E700/E800 or Tablets',
            premium: 'PAX E700/E800 or Tablets',
            enterprise: 'PAX E700/E800 or Tablets',
        },
        {
            title: 'Payment Device',
            lite: 'Mobile Card reader / Built-in',
            standard: 'Built-in or Pinpad',
            plus: 'Built-in or Pinpad',
            premium: 'Built-in or Pinpad',
            enterprise: 'Built-in or Pinpad',
        },
        {
            title: 'Printer',
            lite: 'BlueTooth / built-in',
            standard: 'Built-in or BlueTooth',
            plus: 'Built-in or BlueTooth',
            premium: 'Built-in or BlueTooth',
            enterprise: 'Built-in or BlueTooth',
        },
        {
            title: 'A920 Pro',
            lite: '$695',
            standard: '$695',
            plus: '$695',
            premium: '$695',
            enterprise: '$695',
        },
        {
            title: 'E700',
            lite: '',
            standard: '$1,495',
            plus: '$1,495',
            premium: '$1,495',
            enterprise: '$1,495',
        },
        {
            title: 'E800',
            lite: '',
            standard: '$1,795',
            plus: '$1,795',
            premium: '$1,795',
            enterprise: '$1,795',
        },
    ];

    additionalEquipment = [
        {
            title: 'Lane 3000 Card Reader',
            lite: 'Additional',
            standard: 'Additional',
            plus: 'Additional',
            premium: 'Additional',
            enterprise: 'Additional',
        },
        {
            title: 'Receipt Printer',
            lite: 'Additional',
            standard: 'Additional',
            plus: 'Additional',
            premium: 'Additional',
            enterprise: 'Additional',
        },
        {
            title: 'Scanner',
            lite: 'Additional',
            standard: 'Additional',
            plus: 'Additional',
            premium: 'Additional',
            enterprise: 'Additional',
        },
        {
            title: 'Cash Drawer',
            lite: '$199',
            standard: '$199',
            plus: '$199',
            premium: '$199',
            enterprise: '$199',
        },
        {
            title: 'E700 48 Month Lease',
            lite: 'N/A',
            standard: '$80.00',
            plus: '$80.00',
            premium: '$80.00',
            enterprise: '$80.00',
        },
        {
            title: 'Cash Drawer',
            lite: 'N/A',
            standard: '$90.00',
            plus: '$90.00',
            premium: '$90.00',
            enterprise: '$90.00',
        },
        {
            title: 'Cash Drawer',
            lite: '1.95 Each',
            standard: '1.95 Each',
            plus: '1.95 Each',
            premium: '1.95 Each',
            enterprise: '1.95 Each',
        },
    ];

    support = [
        {
            title: 'Set-up Support',
            lite: '1 hr Phone Included',
            standard: '2 hrr Phone Included',
            plus: '3 hr Phone Included',
            premium: '4 hr Phone Included',
            enterprise: 'Dedicated Phone Line & Contact',
        },
        {
            title: 'Ongoing',
            lite: 'Email - 36 hr response',
            standard: 'Email - 24 hr response',
            plus: '1 hr phone included - 24 hr Email',
            premium: '2 hr phone included - 24 hr Email',
            enterprise: 'Dedicated Phone Line & Contact',
        },
        {
            title: 'On Demand (1hr Min)',
            lite: '$50/hr',
            standard: '$50/hr',
            plus: '$50/hr',
            premium: '$50/hr',
            enterprise: 'Dedicated Phone Line & Contact',
        },
    ];

    services = [
        {
            title: 'Data import/export/clean-up per file (1hr Min)',
            lite: '$75/hr',
            standard: '$75/hr',
            plus: '$75/hr',
            premium: '$75/hr',
            enterprise: '$75/hr',
        },
        {
            title: 'Custom Feature Development (10hr Min)',
            lite: '$100/hr',
            standard: '$100/hr',
            plus: '$100/hr',
            premium: '$100/hr',
            enterprise: '$100/hr',
        },
        {
            title: 'SMS Marketing - Online Store/App',
            lite: 'Additional',
            standard: 'Additional',
            plus: 'Additional',
            premium: 'Additional',
            enterprise: 'Custom',
        },
        {
            title: 'Email Marketing - Online Store/App',
            lite: 'Additional',
            standard: 'Additional',
            plus: 'Additional',
            premium: 'Additional',
            enterprise: 'Custom',
        },
        {
            title: 'Notifications - Online Store/AppNotifications - Online Store/App',
            lite: 'Additional',
            standard: 'Additional',
            plus: 'Included',
            premium: 'Included',
            enterprise: 'Custom',
        },
        {
            title: 'Coupons and art Library',
            lite: 'Additional',
            standard: 'Additional',
            plus: 'Additional',
            premium: 'Additional',
            enterprise: 'Custom',
        },
    ];
    notes = [
        {
            title: 'Additional location is a physical outlet with a differrent address',
            lite: '',
            standard: '',
            plus: '',
            premium: '',
            enterprise: '',
        },
        {
            title: 'Business entity is an entiy that will be defined for credit card processing',
            lite: '',
            standard: '',
            plus: '',
            premium: '',
            enterprise: '',
        },
        {
            title: 'Cancellations allowed with 30 days notice',
            lite: '',
            standard: '',
            plus: '',
            premium: '',
            enterprise: '',
        },
        {
            title: 'Yearly subscription cancellation fee is 10% of the total contract',
            lite: '',
            standard: '',
            plus: '',
            premium: '',
            enterprise: '',
        },
        {
            title: 'Premium web domain names will cost extra based on availability',
            lite: '',
            standard: '',
            plus: '',
            premium: '',
            enterprise: '',
        },
        {
            title: 'Service charge of gross receipts, if not processing credit cards',
            lite: '2%',
            standard: '1%',
            plus: '0.50%',
            premium: '0.50%',
            enterprise: 'Negotiated',
        },
    ];
    //--------

    _globals = GlobalService;

    savedSteps: number = 1;
    freeTrialAccount: boolean = false;
    accountVerified: boolean = false;
    usaStates = UsStates;
    loyaltyProgramPrice: number = 0;
    allowedLocationMoreThanOne: boolean = false;
    allowedRegisterMoreThanOne: boolean = false;
    constructor(private _bs: BaseService) {
        this.loadInit();
    }

    resetVars() {
        if (21 > 12) return;
        this.selectedHardware = [];
        // this.selectedPlan = ''
        this.selectedPlanTimeframe = '';
        this.planTimeframe = [];
        this.checkoutPageInfo = '';
        this.checkoutPageCalcVars = '';

        this.isViewOnly = false;
        this.ok2savePersonalInfo = false;
        this.ok2saveBusinessInfo = false;

        this.personalInfo = '';
        this.businessInfo = null;

        this.subscriptionPlanLocationsQty = 1;
        this.subscriptionPlanRegistersQty = 1;

        this.personalInfo = {
            userID: '',
            firstName: '',
            lastName: '',
            email: '',
            confirmEmail: '',
            password: '',
            confirmPassword: '',
            phone: '',
            companyName: '',
            doAgree: false,
            userExist: false,
        };

        this.businessInfo = {
            businessId: 0,
            userId: '',
            companyName: '',
            industryTypeId: 0,
            phone: '',
            email: '',
            address: '',
            zip: '',
            city: '',
            state: 'Alabama',
            selectedState: null,
        };

        this.customerBillTo = {
            email: '',
            firstName: '',
            company: '',
            address: '',
            city: '',
            state: 'Alabama',
            zip: '',
            country: '',
        };

        this.allowedLocationMoreThanOne = false;
        this.allowedRegisterMoreThanOne = false;
        this.checkoutPageInfo = {
            useBusinessAddressForShipment: true,
            address: '',
            zip: '',
            city: '',
            state: 'Alabama',
            promoCode: '',
        };

        this.checkoutPageCalcVars = {
            locationsQty: 1,
            registersQty: 1,
            loyaltyProgram: 0,
            planCost: 0,
            additionalLocationsCost: 0,
            additionalRegistersCost: 0,
            ecomCost: 0,
            customDomainCost: 0,
            customerAppPrice: 0,
            shoppingLoyaltyCost: 0,
            hardwareCost: 0,
            subTotal: 0,
            tax: 0,
            taxRate: 0,
            gTotal: 0,
            staticWebsite: 0,
            subscriptionPlanPrice: 0,
        };
    }

    getSubscriptionPlans() {
        //-- 'https://connect360.azure-api.net/'
        const url = environment.AuthenticationURL + 'GetSubscriptions';
        // const url = 'https://connect360-stg.azure-api.net/auth-svc/api/GetSubscriptions'
        return this._bs.httpGet(url).pipe(
            map((res) => {
                return res;
            })
        );
    }

    saveBusinessUserV2(model: any) {
        //-- "https://connect360-stg.azure-api.net/auth-svc/api/"
        const url = environment.AuthenticationURL + 'CreateBusinessUserV2';
        return this._bs.httpPost(url, model, true).pipe(
            map((res) => {
                return res;
            })
        );
    }

    saveBusinessInfoV2(model: any) {
        //-- "https://connect360-stg.azure-api.net/auth-svc/api/"
        const url = environment.AuthenticationURL + 'CreateBusinessInfoV2';
        return this._bs.httpPost(url, model, true).pipe(
            map((res) => {
                return res;
            })
        );
    }

    doesCompanyExist(value: any) {
        const url = environment.BaseURL + 'IsCompanyExist';
        let ret = false;
        return this._bs.httpPost(url, { CompanyName: value }).pipe(
            map((res) => {
                return res;
            })
        );
    }

    isUserExist(value: any) {
        const url = environment.BaseURL + 'IsUserExist';
        let ret = false;
        return this._bs.httpPost(url, { Username: value }).pipe(
            map((res) => {
                return res;
            })
        );
    }

    getIndustries() {
        //-- "https://connect360-stg.azure-api.net/auth-svc/api/"
        const url = environment.AuthenticationURL + 'GetIndustries';
        return this._bs.httpGet(url).pipe(
            map((res) => {
                return res;
            })
        );
    }

    getHardwarePlanList() {
        let url = environment.BaseURL + environment.GetHardwarePackages;
        return this._bs.httpGet(url).pipe(
            map((response) => {
                return response;
            })
        );
    }

    getHardwarePlanListv2() {
        let url = environment.BaseURL + environment.GetHardwarePackagesv2;
        return this._bs.httpGet(url).pipe(
            map((response) => {
                return response;
            })
        );
    }

    getEComSubscriptionPlans() {
        let url = environment.BaseURL + 'Subscription/Ecommerce';
        return this._bs.httpGet(url).pipe(
            map((response) => {
                return response;
            })
        );
    }

    getCustomerAppSubscriptionPlans() {
        let url = environment.BaseURL + 'Subscription/CustomerApp';
        return this._bs.httpGet(url).pipe(
            map((response) => {
                return response;
            })
        );
    }

    verifyOTP(obj: any) {
        const url = environment.AuthenticationURL + 'VerifyOTP';
        return this._bs.httpPost(url, obj).pipe(
            map((res) => {
                return res;
            })
        );
    }

    resendCode(obj: any) {
        const url = environment.AuthenticationURL + 'SendOTP';
        return this._bs.httpPost(url, obj).pipe(
            map((res) => {
                return res;
            })
        );
    }

    public loyaltyProgram(obj: any) {
        const url = environment.AuthenticationURL + 'LoyaltyProgram';
        return this._bs.httpPost(url, obj).pipe(
            map((res) => {
                return res;
            })
        );
    }

    calcSelectedHardwarePrice(selectedHardware) {
        try {
            if (!selectedHardware) return 0;
            let isAddon = false;
            let price = 0;
            let addonPrice = 0;
            let withoutAddonPrice: number = 0;
            try {
                price = selectedHardware.price * selectedHardware.selectedQty;
                withoutAddonPrice =
                    selectedHardware.price * selectedHardware.selectedQty;
                let items = selectedHardware.addons;
                if (selectedHardware.addonAdded) {
                    items.map(function (value) {
                        value.singleAddonTotalPrice = 0;
                        if (value.includeAddon) {
                            value.singleAddonTotalPrice =
                                selectedHardware.selectedQty *
                                value.prices.value;
                            addonPrice +=
                                addonPrice + value.singleAddonTotalPrice;
                        }
                    });
                }
                // for (let k = 0; k < items.length; k++)
                //     if (items[k].isSelected) addonPrice += items[k].price * selectedHardware.selectedQty
            } catch {}
            //price += addonPrice
            if (price <= 0 || selectedHardware.selectedQty <= 0)
                price = selectedHardware.price;
            selectedHardware.netPrice = price;
            selectedHardware.withoutAddonPrice = withoutAddonPrice;
            return price;
        } catch (error) {}
    }

    recalEcomPlans() {
        this.recalEcomPlans_1();
        let ti = null;
        ti = setInterval(() => {
            this.recalEcomPlans_1();
            clearInterval(ti);
        }, 1000);
    }

    recalEcomPlans_1() {
        // let ser = this
        let old = null;
        if (this.selectedWebsiteAndOnlineStorePlan)
            old = JSON.parse(
                JSON.stringify(this.selectedWebsiteAndOnlineStorePlan)
            );

        try {
            // let ecomplans = this.eComSubscriptionPlans
            const planid = this.selectedPlan.planId;

            for (let i = 0; i < this.eComSubscriptionPlans.length; i++) {
                // let pp = this.eComSubscriptionPlans[i].pricePlan
                for (
                    let j = 0;
                    j < this.eComSubscriptionPlans[i].pricePlan.length;
                    j++
                ) {
                    if (
                        this.eComSubscriptionPlans[i].pricePlan[j].planID ==
                        planid
                    ) {
                        this.eComSubscriptionPlans[i].price =
                            this.eComSubscriptionPlans[i].pricePlan[
                                j
                            ].monthlyPrice;
                        break;
                    }
                }
            }
            if (old) {
                // let x = ecomplans.find(f => f.id == old.id)
                this.selectedWebsiteAndOnlineStorePlan =
                    this.eComSubscriptionPlans.find((f) => f.id == old.id);
                this.selectedWebsiteAndOnlineStorePlan.price =
                    this.selectedWebsiteAndOnlineStorePlan.price * 1;
            }
        } catch {}
    }

    //--++--++--
    headerOnDesktopScreen() {
        return window.innerWidth > 575;
    }

    headerOnMobileScreen() {
        return window.innerWidth <= 575;
    }

    onMobileScreen() {
        return window.innerWidth < 992;
    }
    onDesktopScreen() {
        return window.innerWidth > 991;
    }
    //-----*****-----*****-----*****-----*****-----*****-----*****-----*****-----*****-----*****-----*****-----
    loadInit() {
        this.steps = [
            {
                id: 1,
                stepNumber: 1,
                title: 'Personal Info',
                detail: 'Tell us about yourself',
                // detail: 'Tell us about yourself to set up a secure account for you.',
                show: true,
            },
            {
                id: 2,
                stepNumber: 2,
                title: 'Business Info',
                detail: 'Tell us about your business',
                // detail: 'Tell us about your business for a personalized experience.',
                show: true,
            },
            {
                id: 3,
                stepNumber: 3,
                title: 'Verification',
                detail: 'Account verification via Email',
                // detail: 'Email verification through OTP',
                show: true,
            },
            {
                id: 4,
                stepNumber: 4,
                title: 'Website & Online Store',
                detail: 'Sell through all channels, please select a website type if you are interested',
                show: true,
            },
            {
                id: 5,
                stepNumber: 5,
                title: 'Loyalty Program',
                detail: 'Encourage customers to shop more',
                show: true,
            },
            // {
            //     id: 6,
            //     stepNumber: 6,
            //     title: 'Hardware',
            //     detail: 'Approved hardware devices for smooth business',
            //     show: true,
            // },
            {
                id: 6,
                stepNumber: 6,
                // stepNumber: 7,
                title: 'Checkout',
                detail: 'Detailed summary of the cart',
                show: true,
            },
            {
                id: 7,
                stepNumber: 7,
                // stepNumber: 8,
                title: 'Payment',
                detail: 'Pay the amount on Authorize.Net',
                show: true,
            },
        ];

        this.selectedWebsiteAndOnlineStorePlan = null;

        this.planTimeframe = [
            { name: 'Monthly', code: 'MN' },
            { name: 'Yearly', code: 'YR' },
        ];

        this.personalInfo = {
            userID: '',
            firstName: '',
            lastName: '',
            email: '',
            confirmEmail: '',
            password: '',
            confirmPassword: '',
            phone: '',
            companyName: '',
            doAgree: false,
            userExist: false,
        };

        this.businessInfo = {
            businessId: 0,
            userID: '',
            companyName: '',
            businessName: '',
            industryTypeId: 0,
            phone: '',
            email: '',
            address: '',
            zip: '',
            city: '',
            state: '',
            selectedState: null,
        };

        this.checkoutPageInfo = {
            useBusinessAddressForShipment: true,
            address: '',
            zip: '',
            city: '',
            state: '',
            promoCode: '',
        };

        this.checkoutPageCalcVars = {
            locationsQty: 1,
            registersQty: 1,
            loyaltyProgram: 0,
            planCost: 0,
            additionalLocationsCost: 0,
            additionalRegistersCost: 0,
            ecomCost: 0,
            customDomainCost: 0,
            shoppingLoyaltyCost: 0,
            hardwareCost: 0,
            subTotal: 0,
            tax: 0,
            gTotal: 0,
            staticWebsite: 0,
        };

        this.customAppBaseData = [
            {
                id: 1,
                answer: 'yes',
                name: 'Yes, I want a customer app.',
                detail: 'This will help you grow your business.',
            },
            {
                id: 2,
                answer: 'no',
                name: 'No, I dont want a customer app.',
                detail: 'My business is just growing.',
            },
        ];

        this.haveHardware = [
            {
                id: 0,
                answer: 'yes',
                name: 'Yes, I have a hardware device.',
                detail: `I will use my own hardware to run ${this.whiteLabel.wlName}`,
            },
            {
                id: 1,
                answer: 'no',
                name: 'No, I don’t have a hardware device.',
                detail: `I will need a hardware device to run ${this.whiteLabel.wlName}`,
            },
        ];

        this.doProcessOnlinePayments = [
            {
                id: 0,
                answer: 'yes',
                name: 'Yes, I plan to accept credit cards.',
                detail: 'Card payment processing will need a secure payment device.',
            },
            {
                id: 1,
                answer: 'no',
                name: 'No, I don’t plan to accept credit cards.',
                detail: 'A 2% monthly revenue fee applies if credit card processing is declined.',
                // detail: 'This will not require a secure payment device.',
            },
        ];

        this.loyaltyProgramAppBaseData = [
            {
                id: 1,
                answer: 'yes',
                name: 'Yes, I want to subscribe to loyalty program',
                detail: 'This will help you grow your business.',
                selected: false,
            },
            {
                id: 2,
                answer: 'no',
                name: 'No, I don’t need loyalty program now',
                detail: 'My business is just growing.',
                selected: false,
            },
        ];

        //--++--++--++
        this.subscriptionPlan = {
            planButtonValue: null,
        };
        this.planChanged = false;
        this.accountVerified = false;
        this.userAccountPendingForPayment = false;
        this.initFreeTrialAccount();
        this.initStepOneSavedForm();
        this.initStepTwoSavedForm();
        this.initStepFifthSavedForm();
        // this.initStepSixSavedForm();
        this.initStepEightSavedForm();
        this.initAccountVerified();
        this.planDurationSet();
    }

    initStepOneSavedForm() {
        let stepOneFound: any = sessionStorage.getItem('step-1');
        if (stepOneFound != undefined) {
            stepOneFound = JSON.parse(stepOneFound);
            this.personalInfo.firstName = stepOneFound.firstName;
            this.personalInfo.lastName = stepOneFound.lastName;
            this.personalInfo.email = stepOneFound.email;
            this.personalInfo.confirmEmail = stepOneFound.confirmEmail;
            this.personalInfo.phone = stepOneFound.phone;
            this.personalInfo.password = stepOneFound.password;
            this.personalInfo.confirmPassword = stepOneFound.confirmPassword;
            this.personalInfo.userID = stepOneFound.userID;
            this.personalInfo.doAgree = true;
        }
    }

    initStepTwoSavedForm() {
        let stepTwoFound: any = sessionStorage.getItem('step-2');
        if (stepTwoFound != undefined) {
            stepTwoFound = JSON.parse(stepTwoFound);
            this.businessInfo.companyName = stepTwoFound.companyName;
            this.businessInfo.phone = stepTwoFound.phone;
            this.businessInfo.email = stepTwoFound.email;
            this.businessInfo.address = stepTwoFound.address;
            this.businessInfo.zip = stepTwoFound.zip;
            this.businessInfo.city = stepTwoFound.city;
            this.businessInfo.businessId = stepTwoFound.businessId;
            this.businessInfo.userID = stepTwoFound.userID;
            this.businessInfo.industryTypeId = stepTwoFound.industryTypeId;
            let checkState = stepTwoFound.state || null;
            if (checkState != undefined && checkState != null) {
                this.businessInfo.state = this.usaStates.filter(
                    (f) => f.name == checkState
                )[0]?.name;
            }
            // if (checkState != undefined) {
            //     this.businessInfo.state = this.usaStates.filter(
            //         (f) => f.abbreviation == checkState
            //     )[0].abbreviation;
            // }
        }
    }

    initStepFifthSavedForm() {
        let loyaltyProgramAppBaseData: any = sessionStorage.getItem(
            'loyaltyProgramAppBaseData'
        );
        if (loyaltyProgramAppBaseData != undefined) {
            loyaltyProgramAppBaseData = JSON.parse(loyaltyProgramAppBaseData);
            if (loyaltyProgramAppBaseData.id == 1) {
                this.loyaltyProgramAppBaseData[0].selected =
                    loyaltyProgramAppBaseData.selected ? true : false;
            } else {
                this.loyaltyProgramAppBaseData[1].selected =
                    loyaltyProgramAppBaseData.selected ? true : false;
            }
        }
    }

    initStepSixSavedForm() {
        let selectedHaveHardwareId: any = sessionStorage.getItem(
            'selectedHaveHardwareId'
        );
        if (selectedHaveHardwareId != undefined) {
            this.selectedHaveHardwareId = parseInt(selectedHaveHardwareId);
        }

        let selectedDoProcessOnlinePaymentsId: any = sessionStorage.getItem(
            'selectedDoProcessOnlinePaymentsId'
        );
        if (selectedDoProcessOnlinePaymentsId != undefined) {
            this.selectedDoProcessOnlinePaymentsId = parseInt(
                selectedDoProcessOnlinePaymentsId
            );
        }
    }

    initStepEightSavedForm() {
        let selectedHardware: any = sessionStorage.getItem('selectedHardware');
        if (selectedHardware != undefined) {
            this.selectedHardware = JSON.parse(selectedHardware);
        }

        let selectedWebsiteAndOnlineStorePlan: any = sessionStorage.getItem(
            'selectedWebsiteAndOnlineStorePlan'
        );
        if (
            selectedWebsiteAndOnlineStorePlan != undefined &&
            selectedWebsiteAndOnlineStorePlan != null
        ) {
            this.selectedWebsiteAndOnlineStorePlan = JSON.parse(
                selectedWebsiteAndOnlineStorePlan
            );
            if (this.selectedWebsiteAndOnlineStorePlan != null) {
                this.selectedDomainPlan =
                    this.selectedWebsiteAndOnlineStorePlan.domainPlan.filter(
                        (f) => f.selected
                    )[0];
            }
        }
    }

    initFreeTrialAccount() {
        let freeTrialAccount: any =
            sessionStorage.getItem('free-trial-account');
        this.freeTrialAccount = freeTrialAccount == '1' ? true : false;
    }

    initAccountVerified() {
        let accountVerified: any = sessionStorage.getItem('accountVerified');
        this.accountVerified = accountVerified == '1' ? true : false;
    }

    returnSelectedPlanInf() {
        let accountVerified: any = sessionStorage.getItem('selectedPlan');
        if (accountVerified != undefined) {
            return JSON.parse(accountVerified);
        }
        return {};
    }

    returnOldLocation() {
        let accountVerified: any = sessionStorage.getItem('locationsQty');
        if (accountVerified != undefined) {
            return parseInt(accountVerified);
        }
        return 0;
    }

    returnOldRegister() {
        let accountVerified: any = sessionStorage.getItem('registersQty');
        if (accountVerified != undefined) {
            return parseInt(accountVerified);
        }
        return 0;
    }

    planDurationSet() {
        let selectedPlanTimeframe: any = sessionStorage.getItem(
            'selectedPlanTimeframe'
        );
        if (selectedPlanTimeframe != undefined) {
            this.selectedPlanTimeframe = selectedPlanTimeframe;
        }
    }

    returnPlanDuration() {
        let selectedPlanTimeframe: any = sessionStorage.getItem(
            'selectedPlanTimeframe'
        );
        if (selectedPlanTimeframe != undefined) {
            return selectedPlanTimeframe;
        }
        return null;
    }

    isLoginWithSubDashboard() {
        let selectedPlanTimeframe: any =
            sessionStorage.getItem('sub-from-dashboard');
        if (selectedPlanTimeframe != undefined) {
            return true;
        }
        return false;
    }

    saveUserSignupInfo(data: any) {
        const url =
            environment.AuthenticationURL + environment.FinishSignUpProcess;
        return this._bs.httpPost(url, data, true).pipe(
            map((res) => {
                return res;
            })
        );
    }
    saveUserSignupInfoForStripe(data: any) {
        const url =
            environment.AuthenticationURL +'CreateStripeSession' ;
        return this._bs.httpPost(url, data, true).pipe(
            map((res) => {
                return res;
            })
        );
    }

    setInitialSubscriptionDetail(data: any) {
        try {
            let unverifiedAccount: any = sessionStorage.getItem(
                'un-verified-account'
            );
            if (unverifiedAccount != undefined) {
                if (unverifiedAccount == '1') {
                    this.userAccountPendingForPayment = true;

                    this.setPersonalInfo(data);
                    this.setBusinessInfo(data.businessSetup[0]);
                    this.setSubScriptionInfo(data.businessSetup[0]);
                }
            }
        } catch (error) {}
    }

    setSubScriptionInfo(subscriptionInfo: any) {
        try {
        } catch (error) {}
    }

    setPersonalInfo(personalInfo: any) {
        try {
            this.personalInfo.firstName = personalInfo.firstName;
            this.personalInfo.lastName = personalInfo.lastName;
            this.personalInfo.email = personalInfo.email;
            this.personalInfo.confirmEmail = personalInfo.email;
            this.personalInfo.phone = personalInfo.phone;
            this.personalInfo.password = 'vAc@2v0p454';
            this.personalInfo.confirmPassword = 'vAc@2v0p454';
            this.personalInfo.userID = personalInfo.userID;
            this.personalInfo.doAgree = true;
        } catch (error) {}
    }

    setBusinessInfo(businessInfo: any) {
        try {
            this.businessInfo.companyName = businessInfo.businessDisplayName;
            this.businessInfo.phone = businessInfo.phone;
            this.businessInfo.email = businessInfo.contactEmail;
            this.businessInfo.address = businessInfo.address;
            this.businessInfo.zip = businessInfo.zip;
            this.businessInfo.city = businessInfo.city;
            this.businessInfo.businessId = businessInfo.businessId;
            this.businessInfo.userID = businessInfo.userID;
            this.businessInfo.industryTypeId = businessInfo.industry;
            let checkState = businessInfo.state;
            if (this.userAccountPendingForPayment) {
                this.accountVerified =
                    businessInfo.accountStatusString == 'AccountVerified'
                        ? true
                        : false;
                sessionStorage.setItem('accountVerified', '0');
                if (this.accountVerified) {
                    sessionStorage.setItem('accountVerified', '1');
                }
            }
            if (checkState != undefined) {
                this.businessInfo.state = this.usaStates.filter(
                    (f) => f.abbreviation == checkState
                )[0].abbreviation;
            }
        } catch (error) {}
    }
}
