export interface WHITE_LABEL {
  wlName: string;
  wlsName?:string;
  apiName?:string;
  logo: string;
  slogan: string;
  backgroundImage: string;
  adminBackgroundImage?:string;
  themeId: string;
  industryId: string;
  id: string;
  isProduction: boolean;
  website:string;
  domain: string;
  favIcon: string;
  siteURL?:string
  signupURL?: string;
  loginURL?: string;
  brandColor?: string;
  logoWhite?: any;
  themeData?: THEME_DATA;
  isStripe?:boolean
  isChangePlan?:boolean;
  isAddLocations?:boolean;
  isAddRegidters?:boolean;
  isChangeCard?:boolean
  isDownloadPOSAPP?:boolean
}
export interface THEME_DATA {
  brandColor: string;
  brandColorAlternate: string;
  brandColorDark: string;
  brandColorLight: string;
  brandColorGeneralText: string;
  brandColorPageHeadingText: string;
}
export let WhiteLabelList: WHITE_LABEL[] = [

  {

    wlName: "LincSell",
    wlsName: "lincsell",
    apiName: "lincSell",
    logo: "assets/images/LincSell-Main.svg",
    logoWhite: "assets/images/LincSell-Main.svg",
    slogan: "Welcome to LincSell!",
    backgroundImage:"assets/images/bggg.jpg",
    adminBackgroundImage:"assets/images/bggg.jpg",
    themeId: "",
    industryId: "",
      id: "94DE1528-DE42-498A-A07E-4A458E97240E",
    isProduction: true,
    website: 'https://www.lincsell.com/',
    siteURL: 'https://lincsell.com/',
    domain: "http://localhost:4200/",
    favIcon: "lsfavicon",
    signupURL: "https://su.connect-360.net/signup",
    // brandColor: "#1429EF",
    brandColor: "#FA4B1B",
    isStripe:true,
    isChangePlan: true,
    isAddLocations: true,
    isAddRegidters: true,
    isChangeCard:true,
    isDownloadPOSAPP:false,
    themeData: {
      // brandColor: "#1429EF",
      brandColor: "#FA4B1B",
      brandColorAlternate: "#1429EF",
      brandColorDark: "#030A21",
      brandColorLight: "#b8ddd73f",
      brandColorGeneralText: "#d",
      brandColorPageHeadingText: "#dd",
    }
  },

    // {
  //   wlName: "Connect-CBD",
  //   logo: "https://cloudstoragesvc.blob.core.windows.net/commonfiles/Connect-CBD_Logo.svg",
  //   logoWhite: "https://cloudstoragesvc.blob.core.windows.net/commonfiles/Connect-CBD_Menu_White_Text.svg",
  //   slogan: "Welcome to Connect-CBD!",
  //   backgroundImage: "https://preapp.connect-cbd.net/assets/img/crs-theme-v4.png",
  //   themeId: "",
  //   industryId: "",
  //   id: "E0D7F13C-7A78-40B7-8049-CF8A1F1C245F",
  //   isProduction: false,
  //   website: 'https://www.connect-CBD.net/',
  //       domain: "http://localhost:4200/",
  //   favIcon: "favicon-cbd",
  //   signupURL: "https://su.connect-cbd.net/signup",
  //   brandColor: "#358a35",
  //   themeData: {
  //     brandColor: "#358a35",
  //     brandColorAlternate: "#358a35",
  //     brandColorDark: "#384600",
  //     brandColorLight: "#b8d73f",
  //     brandColorGeneralText: "#526a76",
  //     brandColorPageHeadingText: "#757575",
  //   }
  // },
  
  // {
  //   wlName: "ConnectedSoftware360",
  //   logo: "https://cloudstoragesvc.blob.core.windows.net/commonfiles/connectedsoftware360BlackLogo.png",
  //   logoWhite: "https://cloudstoragesvc.blob.core.windows.net/commonfiles/connectedsoftware360WhiteLogo.png",
  //   slogan: "Welcome to Admin Dashboard!",
  //   backgroundImage: "https://testapp.connect-360.net/assets/img/connect-360-login-banner.png",
  //   themeId: "",
  //   industryId: "",
  //   id: "94DE1528-DE42-498A-A07E-4A458E97240E",
  //   isProduction: true,
  //   website: 'http://localhost:4200/',
  //   domain: "http://localhost:4200/",
  //   favIcon: "favicon-connect-360",
  //   signupURL: "",
  //   brandColor: "#00457D",
  //   themeData: {
  //     brandColor: "#00457D",
  //     brandColorAlternate: "#3ddd58a35",
  //     brandColorDark: "#030A21",
  //     brandColorLight: "#b8ddd73f",
  //     brandColorGeneralText: "#d",
  //     brandColorPageHeadingText: "#dd",
  //   }
  // },
  // {
  //   wlName: "Connect-360",
  //   logo: "https://cloudstoragesvc.blob.core.windows.net/commonfiles/Connect360_Logo.svg",
  //   logoWhite: "https://cloudstoragesvc.blob.core.windows.net/commonfiles/Connect360_Menu_White_Text.svg",
  //   slogan: "Welcome to Connect-360!",
  //   backgroundImage: "https://testapp.connect-360.net/assets/img/connect-360-login-banner.png",
  //   themeId: "",
  //   industryId: "",
  //   id: "94DE1528-DE42-498A-A07E-4A458E97240E",
  //   isProduction: true,
  //   website: 'https://www.connect-360.net/',
  //   domain: "https://preapp.connect-360.net/",
  //   favIcon: "favicon-connect-360",
  //   signupURL: "https://testapp.connect-360.net/signup",
  //   brandColor: "#00457D",
  //   themeData: {
  //     brandColor: "#00457D",
  //     brandColorAlternate: "#3ddd58a35",
  //     brandColorDark: "#030A21",
  //     brandColorLight: "#b8ddd73f",
  //     brandColorGeneralText: "#d",
  //     brandColorPageHeadingText: "#dd",
  //   }
  // },
  // {
  //   wlName: "Connect-CBD",
  //   logo: "https://cloudstoragesvc.blob.core.windows.net/commonfiles/Connect-CBD_Logo.svg",
  //   logoWhite: "https://cloudstoragesvc.blob.core.windows.net/commonfiles/Connect-CBD_Menu_White_Text.svg",
  //   slogan: "Welcome to Connect-CBD!",
  //   backgroundImage: "https://app.connect-cbd.net/assets/img/crs-theme-v4.png",
  //   themeId: "",
  //   industryId: "",
  //   id: "E0D7F13C-7A78-40B7-8049-CF8A1F1C245F",
  //   isProduction: false,
  //   website: 'https://www.connect-CBD.net/',
  //   domain: "https://preapp.connect-cbd.net/",
  //   favIcon: "favicon-cbd",
  //   signupURL: "https://testapp.connect-cbd.net/signup",
  //   brandColor: "#358a35",
  //   themeData: {
  //     brandColor: "#358a35",
  //     brandColorAlternate: "#358a35",
  //     brandColorDark: "#384600",
  //     brandColorLight: "#b8d73f",
  //     brandColorGeneralText: "#526a76",
  //     brandColorPageHeadingText: "#757575",
  //   }
  // }
  // ,
  // {
  //   wlName: "Connect-CBD",
  //   logo: "https://cloudstoragesvc.blob.core.windows.net/commonfiles/Connect-CBD_Logo.svg",
  //   logoWhite: "https://cloudstoragesvc.blob.core.windows.net/commonfiles/Connect-CBD_Menu_White_Text.svg",
  //   slogan: "Welcome to Connect-CBD!",
  //   backgroundImage: "https://app.connect-cbd.net/assets/img/crs-theme-v4.png",
  //   themeId: "",
  //   industryId: "",
  //   id: "E0D7F13C-7A78-40B7-8049-CF8A1F1C245F",
  //   isProduction: false,
  //   website: 'https://www.connect-CBD.net/',
  //   domain: "https://localhostt:4200/",
  //   favIcon: "favicon-cbd",
  //   signupURL: "https://testapp.connect-cbd.net/signup",
  //   // brandColor:"#358a35"
  //   brandColor: "#a8c730",
  //   themeData: {
  //     brandColor: "#a8c730",
  //     brandColorAlternate: "#358a35",
  //     brandColorDark: "#384600",
  //     brandColorLight: "#b8d73f",
  //     brandColorGeneralText: "#526a76",
  //     brandColorPageHeadingText: "#757575",
  //   }
  // },
  // {
  //   wlName: "Connect-CBD",
  //   logo: "https://cloudstoragesvc.blob.core.windows.net/commonfiles/Connect-CBD_Logo.svg",
  //   logoWhite: "https://cloudstoragesvc.blob.core.windows.net/commonfiles/Connect-CBD_Menu_White_Text.svg",
  //   slogan: "Welcome to Connect-CBD!",
  //   backgroundImage: "https://app.connect-cbd.net/assets/img/crs-theme-v4.png",
  //   themeId: "",
  //   industryId: "",
  //   id: "E0D7F13C-7A78-40B7-8049-CF8A1F1C245F",
  //   isProduction: false,
  //   website: 'https://www.connect-CBD.net/',
  //   domain: "https://connectedsoftware360-newtheme.azurewebsites.net/",
  //   favIcon: "favicon-cbd",
  //   signupURL: "https://testapp.connect-cbd.net/signup",
  //   brandColor: "#a8c730",
  //   themeData: {
  //     brandColor: "#a8c730",
  //     brandColorAlternate: "#358a35",
  //     brandColorDark: "#384600",
  //     brandColorLight: "#b8d73f",
  //     brandColorGeneralText: "#526a76",
  //     brandColorPageHeadingText: "#757575",
  //   }
  // },
  // {
  //   wlName: "Connect-360",
  //   logo: "https://cloudstoragesvc.blob.core.windows.net/commonfiles/Connect360_Logo.svg",
  //   logoWhite: "https://cloudstoragesvc.blob.core.windows.net/commonfiles/Connect360_Menu_White_Text.svg",
  //   slogan: "Welcome to Connect-360!",
  //   backgroundImage: "https://testapp.connect-360.net/assets/img/connect-360-login-banner.png",
  //   themeId: "",
  //   industryId: "",
  //   id: "94DE1528-DE42-498A-A07E-4A458E97240E",
  //   isProduction: true,
  //   website: 'https://www.connect-360.net/',
  //   domain: "https://connectedsoftware360-newtheme.azurewebsites.net/",
  //   favIcon: "favicon-connect-360",
  //   signupURL: "https://testapp.connect-360.net/signup",
  //   brandColor: "#00457D",
  //   themeData: {
  //     brandColor: "#00457D",
  //     brandColorAlternate: "#358a35",
  //     brandColorDark: "#384600",
  //     brandColorLight: "#b8d73f",
  //     brandColorGeneralText: "#526a76",
  //     brandColorPageHeadingText: "#757575",
  //   }
  // },
  //   {
  //       wlName: "Connect-CBD",
  //       logo: "https://cloudstoragesvc.blob.core.windows.net/commonfiles/Connect-CBD_Logo.svg",
  //       logoWhite: "https://cloudstoragesvc.blob.core.windows.net/commonfiles/Connect-CBD_Menu_White_Text.svg",
  //       slogan: "Welcome to Connect-CBD!",
  //       backgroundImage: "https://app.connect-cbd.net/assets/img/crs-theme-v4.png",
  //       themeId: "",
  //       industryId: "",
  //       id: "E0D7F13C-7A78-40B7-8049-CF8A1F1C245F",
  //       isProduction: false,
  //       website: 'https://www.connect-CBD.net/',
  //       domain: "https://testing.connect-cbd.com/",
  //       favIcon: "favicon-cbd",
  //       signupURL: "https://testapp.connect-cbd.net/signup",
  //       brandColor: "#a8c730",
  //       themeData: {
  //           brandColor: "#a8c730",
  //           brandColorAlternate: "#358a35",
  //           brandColorDark: "#384600",
  //           brandColorLight: "#b8d73f",
  //           brandColorGeneralText: "#526a76",
  //           brandColorPageHeadingText: "#757575",
  //       }
    // },
];
