import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CurrentPlan } from 'src/app/_data/current-plan';
import { WhiteLabelList } from 'src/app/_data/white-label-list';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { SignupService } from 'src/app/_services/signup.service';

@Component({
    selector: 'app-subscription-plans-lincsell',
    templateUrl: './subscription-plans-lincsell.component.html',
    styleUrls: ['./subscription-plans-lincsell.component.scss'],
})
export class SubscriptionPlansLincsellComponent implements OnInit {
 
    _globals = GlobalService
    currentPlan = CurrentPlan
    trialDaysLeft: number = 0;
    showTrialMessage: boolean = false;
    businessSetup:any;
    isMonthly:boolean = false;


    plans = [];
    
    constructor(
        public _signup: SignupService,
        private _router: Router,
        private _abd:AppBaseDataService,
    ) {}



    ngOnInit(): void {
      this._abd.showSpinner()
      try {
              this._signup.getSubscriptionPlans().subscribe((res)=>{
          this._abd.hideSpinner()
                if(res.success){
          this.plans =  res.data.filter((p)=>p.isTrial !== true) 
        }  
   
      })
      } catch (error) {
        this._abd.hideSpinner()
      }


      


    }
    getRemaintingTime(){
      let businessSetup = this._globals.userInfo?.businessSetup[0];
        let planInfo = this._globals.userInfo?.businessSetup[0]?.subscription;
       
        let IsUserOnTrial=businessSetup?.isFreeTrial
        if (IsUserOnTrial) {
            let startDate: Date = new Date();
            let endDate: Date = new Date(businessSetup?.freeTrialEndDate.toString());

            let diff = Math.abs(endDate.getTime() - startDate.getTime());
            return Math.ceil(diff / (1000 * 3600 * 24));

        }
        else {
            let startDate: Date = new Date(businessSetup?.createdDate.toString());
            let endDate: Date = new Date(planInfo?.nextChargeDate.toString());

            let diff = Math.abs(endDate.getTime() - startDate.getTime());
            return Math.ceil(diff / (1000 * 3600 * 24));
        }
    }
    goToBilling(value:any){

      this._signup.selectedPlanDetails = value
      localStorage.setItem('selectedPlanDetails',JSON.stringify(this._signup.selectedPlanDetails))
      this._router.navigate(["check-out"])
    }
    goToHome(){
      localStorage.setItem('is_Select_Plan','true')
      // if (this.showTrialMessage) {
      //   this._router.navigate([""])
      // } else {
        this._router.navigate([""])
      // }
    }
    goToPlanDetails(){
      window.open("https://lincsell.com/pricing/","_blank");
    }

}
