<div
    class="layout-wrapper"
    [ngClass]="{
        'layout-overlay': isOverlay(),
        'layout-static': isStatic(),
        'layout-theme-light': !config.dark,
        'layout-theme-dark': config.dark,
        'layout-overlay-sidebar-active': overlayMenuActive,
        'layout-static-sidebar-inactive': staticMenuInactive,
        'layout-mobile-sidebar-active': menuActiveMobile,
        'p-ripple-disabled': !config.ripple,
        'p-input-filled': config.inputStyle === 'filled'
    }"
>
    <app-topbar></app-topbar>
    <div class="layout-sidebar" [ngClass]="{'pointer-events-none': _globals.currentTour <= _globals.totalTour}">
        <!-- <app-menu></app-menu> -->
        <!-- <app-menu-left></app-menu-left> -->
             <app-sidebar></app-sidebar>
    </div>
    <div class="main-tour" *ngIf="_globals.currentTour <= _globals.totalTour && isDesktop()">
<app-welcome-tour></app-welcome-tour>
    </div>

    <div class="layout-top-container" *ngIf="IsUserOnTrial && !isOnlineStore">
        <div>
&nbsp;
    </div> 
<div class="dashboard-center-free-trial" *ngIf="IsUserOnTrial && !isOnlineStore" >You Have {{dayLeftInRenewal}} Days Left in Your Free Trial. <span class="plan-btn-upgrade cursor-pointer" (click)="navigateToSubscription()" *ngIf="!IsOnSubscriptionPage && showUpgradeBtn">Upgrade Plan</span> </div>
<!-- <div class="dashboard-center-free-trial" >You Have {{dayLeftInRenewal}} Days Left in Your Free Trial. <span class="plan-btn-upgrade"></span> </div> -->
<div>&nbsp;</div>
</div>   
    <div class="layout-main-container" >    
      
        <div class="pb-2 mb-1" *ngIf="_abd.isUserOnDashboard && _abd.isSetupGuide && _abd.isAdmin">
        <h6 class="font-semibold text-xl my-1">
            Hello! {{_globals.userInfo.firstName}} {{_globals.userInfo.lastName}}
        </h6>
        <p >
            Achieve a smooth store launch by following our step-by-step guide below.
        </p>
      </div>
        <!-- <div class="card py-1 border-radius-none" *ngIf="IsUserOnTrial && !isOnlineStore">
            <div class="flex flex-row flex-wrap  card-container  ">
                <div class="flex align-items-center justify-content-center m-2 flex-grow-1 " *ngIf="IsUserOnTrial">
                    <span *ngIf="IsUserOnTrial ">
                        <div class="flex font-bold text-lg align-items-center">
                            <span>You are currently on 30 Day free trial which is ending in {{dayLeftInRenewal}} days.</span>
                            <p-button *ngIf="!IsOnSubscriptionPage && showUpgradeBtn" label="View Details"
                                (click)="navigateToSubscription()" styleClass="mx-3">
                            </p-button>
                        </div>
                    </span>
                </div>
            </div>
        </div> -->
        <div class="layout-main mt-7">
            <router-outlet></router-outlet>
        </div>
        <!-- <app-footer></app-footer> -->
    </div>
    <app-config></app-config>
    <div *ngIf="menuActiveMobile" class="layout-mask p-component-overlay"></div>
</div>

<p-dialog [dismissableMask]="true" [(visible)]="_abd.isDownloadPosApp" [style]="{width: '50vw'}">
    <div class="flex justify-content-center font-bold py-2 pb-4 " style=" color:var(--brandColor);font-size: large;">
     Please scan the QR Code to Download the Point-of-Sale App
    </div>
    <div class="flex justify-content-between  ">
      <div class="flex flex-column align-items-center justify-content-between">
     <img width="200px" height="200px" src="assets/images/app-store.png" alt="QR Code">
     <span class="flex align-items-center">
         <img src="./../assets/images/app-store-icon.svg" alt="" width="15px" height="15px">&nbsp; App Store
     </span>
    </div>
    <div class="flex flex-column align-items-center justify-content-between">   <img width="200px" height="200px" src="assets/images/play-store.png" alt="QR Code">
 <span class="flex align-items-center">
    <img src="./../assets/images/play-store-icon.svg" alt="" width="15px" height="15px">&nbsp; Play Store
 </span> 
 </div>
    <div class="flex flex-column align-items-center justify-content-between">   <img width="200px" height="200px" src="assets/images/pax-store.png" alt="QR Code">
 <span class="flex align-items-center">
    <img src="./../assets/images/pax-store-icon.png" alt="" width="15px" height="15px"> &nbsp; Pax Store
 </span>
 </div>
    </div>     
</p-dialog> 
