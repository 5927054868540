import { UserService } from 'src/app/_services/user.service';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { usTimeZoneList } from 'src/app/_data/time-zones';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { LocationsService } from 'src/app/_services/locations.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-template-preview',
  templateUrl: './theme-preview.component.html',
  styleUrls: ['./theme-preview.component.scss']
})
export class ThemePreviewComponent implements OnInit {
 _global = GlobalService;
  notShow: any = false;
   current
  constructor(
    public _abd: AppBaseDataService,
    public _businessSetup: BusinessSetupService,
    public _userService: UserService,
    private _router: Router,
    private _connMessageService: ConnMessageService,
    public _locationService: LocationsService,
    private _location:Location, 
  ){}
 
  ngOnInit(): void {}
  setTheme(type:any, value?:any){
 
    if(type === 'back'){
       this._location.back()
   }else if(type === 'usetheme'){ 
    this.saveFn(value)

 
  }}
  saveFn(currentTemplate?:any){
    let obj ={
        id:currentTemplate?.id,
        templateID:currentTemplate?.templateID,
        webStoreHost:currentTemplate?.webStoreHost,
        demoUrl:currentTemplate?.demoUrl,
        isDefault:true,
        isAvailable:currentTemplate?.isAvailable,
        imageUrl:currentTemplate?.imageUrl,
        isSelectedLater:currentTemplate?.isSelectedLater,
        createdDate:currentTemplate?.createdDate,
    }   
    try{
      this._abd.showSpinner()
      this._businessSetup.saveThemeInformation(obj).subscribe(res=>{
        if(res.data === true){
          this._userService.getWebStoreConfiguration().subscribe((res)=>{
            if(res.success){
              this._global.webStoreConfiguration = res.data
              this._router.navigate([""])
              let x = window.location.origin + '/#'
              localStorage.setItem('goto',x)
              this._userService.autologin(false)
              window.open(this._global.webStoreConfiguration?.webStoreHost);

            }
          })
          this._connMessageService.showToastMessage(`Theme Configuration saved successfully!`, 'success')
        }
        else{
              this._connMessageService.showToastMessage(`This template is not available. Please choose another one.`, 'error')
            }
      })
    }
    catch{
      this._connMessageService.showToastMessage(`Error saving Theme settings!`, 'error')
      this._abd.hideSpinner()
    }
  
}
craeteDefaultLocation() {
  let obj = {
     LocationName: "Default Location",
     ManageInventory: true, //this._locationService.activeItem.manageInventory,
     Phone: this._global.userInfo?.phone, //this._locationService.activeItem.phone.replace(/\D/g,''),
     Address: null,
     City: null,
     State: '',
     zip: null, //this._locationService.activeItem.zip,
     Country: "US",
     AllowShipping: true, //this._locationService.activeItem.allowShipping,
     AcceptOnlineOrders: true, //this._locationService.activeItem.acceptOnlineOrders
     // AcceptOnlineOrders: this.editform.get('acceptOnlineOrders').value, //this._locationService.activeItem.acceptOnlineOrders
   }
  
  
   try {
     
     this._locationService.createUpdateItem(obj, true).subscribe(res => {
      this._abd.loadInit()
      //  this._connMessageService.showToastMessage(`Default Location saved successfully!`, 'success')
     })
   } catch (error) {
    //  this._connMessageService.showToastMessage(`Error saving Default Location!`, 'error')
   }
 }
}
